import { FC } from 'react'
import { Autocomplete, Box, TextField } from 'src/components/UI'
import { SelectInputProps } from './types'
import useStyles from './styles'
import { Chip, CircularProgress, Tooltip, Typography } from '@mui/material'

const SelectInput: FC<SelectInputProps> = ({
  label = '',
  labelVariant = 'h6',
  onChange = () => { },
  onInputChange = () => { },
  onBlur = () => { },
  onFocus = () => { },
  clearOnBlur = false,
  options = [],
  placeholder = 'Search...',
  value,
  customIcon,
  className = '',
  size = 'medium',
  disabled,
  multiple = false,
  sx,
  freeSolo = false,
  maxCharacters = null,
  disableClearable = false,
  renderOption = null,
  renderInput = null,
  deafultSelected = { key: '', label: '' },
  getOptionLabel,
  chipVariant = 'outlined',
  getOptionDisabled,
  renderChip,
  isOptionEqualToValue,
  paperWidth,
  tooltipCharacters,
  loading,
  loadingValue,
  paperMarginLeft,
  labelStyle,
  canRemove = true,
  error
}) => {
  const classes = useStyles()
  const renderChipDefault = (label: string) => {
    if (!maxCharacters) return label
    if (label.length > 10) {
      return label.substring(0, maxCharacters) + '...'
    } else {
      return label;
    }
  }

  return (
    <Box
      /* sx={{ ...sx }} */
      sx={[
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {label !== '' && (
        <Typography
          variant={labelVariant}
          sx={labelStyle}
        >
          {label}
        </Typography>
      )}
      <Autocomplete
        onInputChange={(event: any, newValue: any) => {
          return onInputChange(newValue)
        }}
        onChange={(event: any, newValue: any) => {
          if (newValue === null) return onChange(deafultSelected)
          return onChange(newValue)
        }}
        onBlur={onBlur}
        clearOnBlur={clearOnBlur}
        disableClearable={disableClearable}
        value={value}
        options={!loading ? options : []}
        onFocus={onFocus}
        freeSolo={freeSolo}
        renderOption={renderOption !== null ? renderOption : (props, item: any) => { return (<li {...props} key={item.id}>{item.label || item}</li>) }}
        loading={loading}
        getOptionLabel={getOptionLabel}
        renderInput={params => {
          const { InputProps, ...rest } = params
          return (
            <Tooltip
              title={typeof value === 'string' && tooltipCharacters && value.length > tooltipCharacters ? value : ''}>
              {loadingValue ?
                <CircularProgress size="1rem" /> :
                <TextField
                  {...rest}
                  autoComplete='search'
                  color='infoText'
                  placeholder={placeholder}
                  InputProps={{
                    classes: {
                      root: classes.input,
                      input: classes.input,
                      notchedOutline: classes.notchedOutline,
                      adornedEnd: canRemove ? undefined : classes.canRemove,
                    },
                    size: size,
                    ...InputProps
                  }}
                  className={`${error ? classes.error : ''} ${className}`}
                  variant='outlined'
                />}
            </Tooltip>
          )
        }}
        renderTags={(value, getTagProps) => {
          return value.map((option: any, index) => (
            <Chip variant={chipVariant} label={
              <Typography>{renderChip ? renderChip(option) : renderChipDefault(option)}</Typography>
            } {...getTagProps({ index })}
            />
          ))
        }}
        size='small'
        popupIcon={customIcon}
        classes={{
          root: classes.input,
          popupIndicator: customIcon ? classes.popupCustomIcon : classes.popupIcon
        }}
        componentsProps={{
          paper: {
            sx: {
              width: paperWidth ?? 'auto',
              marginTop: '8px',
              marginLeft: paperMarginLeft ? paperMarginLeft : 'auto',
            }
          }
        }}
        disabled={disabled}
        multiple={multiple}
        defaultValue={deafultSelected || undefined}
        getOptionDisabled={getOptionDisabled}
        isOptionEqualToValue={isOptionEqualToValue || undefined}
      />
    </Box>
  )
}

export default SelectInput
