import { TableBody as MaterialTableBody } from '@mui/material'

import TableRow from '../TableRow'
import { TableBodyProps } from './types'


const TableBody = <T, U>({
  headers,
  small,
  children,
  rowAction,
  actions,
  hasBorders,
  rowColor,
  onRightClickRow,
  selectedRow
}: TableBodyProps<T, U>) => {
  return (
    <MaterialTableBody>
      {children && children.map((row: T, rowIndex) => (
        <TableRow<T, U>
          key={rowIndex}
          headers={headers}
          small={small}
          actions={actions}
          onClickRow={rowAction}
          hasBorders={hasBorders}
          index={rowIndex}
          rowColor={rowColor}
          onRightClickRow={onRightClickRow}
          selected={selectedRow === rowIndex}
        >
          {row}
        </TableRow>
      ))}
    </MaterialTableBody>
  )
}

export default TableBody