import { useState, FC, useEffect, useMemo, useCallback } from 'react'
import {
  Box,
  LabeledText,
  BoxContainer,
  TitleFirst,
  Grid,
  Typography,
  Chip,
  Checkbox,
  IconButton,
  Button as ModalButton,
  TextFieldLabel,
  SelectInput,
} from 'src/components/UI'
import DatePicker from 'src/components/UI/CustomUI/atoms/DatePicker'
import { formatTimestampDate, formatDateTimestamp } from 'src/helpers'
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  StreetViewPanorama,
} from '@react-google-maps/api'
import env from '@beam-australia/react-env'
import {
  companiesActions,
  employeesActions,
  estimateActions,
  estimatesActions,
  territoriesActions,
} from 'src/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import EmailIcon from '@mui/icons-material/Email'
import SaveIcon from '@mui/icons-material/Save'
import {
  EMPLOYEE_ROLE_TYPE,
  ESTIMATE_APPROVAL_STATUS,
  isEmpty,
} from 'src/helpers'
import { CircularProgress, FormControlLabel } from '@mui/material'
import { getEstimate } from 'src/ducks/estimate/selectors'
import {
  getCompanies,
  getCompaniesForDropdownWithoutAll,
  getEmployeePrimaryEmail,
  getEmployeeRoles,
  getEmployeesByRole,
  getOnlyTerritoriesForDropdown,
  getTerritories,
  getTerritoriesSelector,
} from 'src/ducks/selectors'
import { ADJUSTMENT_USERS } from 'src/helpers/constants'
import { Address as EstimateAddress, EstimateItem, EstimateProperty } from 'src/ducks/types'
import { InformationTabsProps } from './types'
import { debounce } from 'lodash'
import EditIcon from '@mui/icons-material/Edit'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import SelectAddress from 'src/components/UI/CustomUI/organisms/SelectAddress'
import { Address } from 'src/components/UI/CustomUI/organisms/SelectAddress/types'
import { ModalActionType } from '../../ControlButtons/Modals/ModalMaterialInvoices/types'
import { icons } from 'src/assets'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'

const Information: FC<InformationTabsProps> = ({ setPageLoading }) => {
  const dispatch = useDispatch()
  //const job = useSelector(getJob())
  const estimate = useSelector(getEstimate())
  const estimators = useSelector(
    getEmployeesByRole(EMPLOYEE_ROLE_TYPE.TERRITORY_ESTIMATOR)
  )
  const successManagers = useSelector(
    getEmployeesByRole(EMPLOYEE_ROLE_TYPE.SUCCESS_MANAGER)
  )
  const territoryManagers = useSelector(
    getEmployeesByRole(EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER)
  )
  const busDevManagers = useSelector(
    getEmployeesByRole(EMPLOYEE_ROLE_TYPE.TERRITORY_BUSINESS_DEV_MANAGER)
  )
  const employeeRoles = useSelector(getEmployeeRoles)
  const employeeEmail = useSelector(getEmployeePrimaryEmail)
  const affiliates = useSelector(getCompaniesForDropdownWithoutAll)
  const affiliateStages = useSelector(getCompanies)

  const [estimateInfo, setEstimateInfo] = useState({
    key: '',
    notes: estimate?.properties.clientNotes,
    lat: estimate?.properties?.address?.latitude
      ? estimate?.properties.address.latitude
      : 32.78,
    lng: estimate?.properties?.address?.longitude
      ? estimate?.properties.address.longitude
      : -79.94,
    fullAddress: estimate?.properties?.address?.fullAddress,
    payAtClose: !!estimate?.properties.payAtClose,
    nar: !!estimate?.properties.nar,
    estimateFormType: estimate?.properties?.estimateFormType || '',
    //address: job?.properties?.address
  })
  const [updateData, setUpdateData] = useState(false)
  const [loading, setLoading] = useState(false)


  const [adjustment, setAdjustment] = useState(
    estimate?.properties.discount || '0'
  )
  const [minimum, setMinimum] = useState(
    `${estimate?.properties.minimum}` || '500'
  )
  const [payAtCloseChecked, setPayAtCloseChecked] = useState(
    estimateInfo.payAtClose || false
  )
  const [narChecked, setNarChecked] = useState(estimateInfo.nar || false)
  const [estimatorName, setEstimatorName] = useState(
    estimate?.estimatorName || ''
  )
  const [minimumSaving, setMinimumSaving] = useState(false)
  const [adjustmentSaving, setAdjustmentSaving] = useState(false)
  const [open, setOpen] = useState(false)

  const GOOGLE_MAPS_API_KEY = env('GOOGLE_MAPS_API_KEY') ?? ''
  const streetViewPanoramaOptions = {
    position: { lat: estimateInfo.lat, lng: estimateInfo.lng },
    zoom: 1,
    pov: { heading: 0, pitch: 0 },
    // visible: true,
  }

  const [address, setAddress] = useState<any>('')
  //const [open, setOpen] = useState(false)

  const territoriesOtions = useSelector(getOnlyTerritoriesForDropdown)
  const allTerritories = useSelector(getTerritories)
  const terrytorySelected = territoriesOtions.find(
    (ter) => ter.key === estimate?.properties?.territory?.id
  )
  const [territory, setTerritory] = useState(terrytorySelected)
  const roleTypes = employeeRoles.map((role) => role.roleType)

  const handleATerritoryChange = (newSelected: SelectInputOption) => {
    if (newSelected === null) {
      setTerritory(territoriesOtions[0])
    } else {
      const territorySelected = allTerritories.find(
        (ter) => ter.id === newSelected.key
      )
      handleUpdateEstimateProperty(
        { territory: territorySelected },
        false
      )
      setTerritory(newSelected)
    }
  }

  const controls = [
    {
      label: 'Pay at Close',
      propertyName: 'payAtClose',
      checked: payAtCloseChecked,
      toogle: (checked: boolean) => {
        handleUpdateEstimateProperty(
          { ['payAtClose' as keyof EstimateProperty]: checked },
          false
        )
        setPayAtCloseChecked(checked)
      },
      disabled: false,
    },
    {
      label: 'NAR',
      propertyName: 'nar',
      checked: narChecked,
      toogle: (checked: boolean) => {
        let request: Partial<EstimateProperty> = { nar: checked }

        if (checked === true) {
          setAdjustment(-5)
          request.discount = -5
        } else {
          setAdjustment(0)
          request.discount = 0
        }

        handleUpdateEstimateProperty(request, false)
        setNarChecked(checked)
      },
      disabled:
        estimate?.approvalStatus === ESTIMATE_APPROVAL_STATUS.APPROVED &&
        !employeeRoles
          .map((role) => role.roleType)
          .includes(EMPLOYEE_ROLE_TYPE.SUPER_ADMIN),
    },
  ]

  const isValid: boolean =
    !isEmpty(address?.line_1) &&
    address?.line_1 !== ' ' &&
    !!address?.zipCode &&
    address?.latitude &&
    address?.longitude

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  })

  const QA_EMAILS_FOR_DEV =
    env('NODE_ENV') !== 'production'
      ? [
        'rodrigo.antognazza@punchlistusa.com',
        'rodrigo.antognazza@bosscathome.com',
      ]
      : []
  const ADJUSTMENTS_USERS = [...ADJUSTMENT_USERS, ...QA_EMAILS_FOR_DEV]

  const containerStyle = {
    width: '100%',
    height: '100%',
    minHeight: '175px',
    padding: '12px 0',
    borderRadius: '8px',
    '.gm-style-cc': { display: 'none' },
  }

  /*   const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { name, value } = e.target
      setestimateInfo({
        ...estimateInfo,
        [name]: value
      })
    } */

  useEffect(() => {
    dispatch(companiesActions.fetchCompanies({}, (_succ: boolean) => { }))
    dispatch(
      employeesActions.fetchEmployees(
        { page: 0, size: 150, searchParams: { approved: true } },
        (_succ: boolean) => { }
      )
    )
  }, [])

  useEffect(() => {
    dispatch(territoriesActions.fetchTerritories(() => { }))
  }, [])

  /*   useEffect(() => {
  
      if (estimateInfo.notes != job?.properties.clientNotes
        || estimateInfo.payAtClose !== !!job?.properties.payAtClose
        || estimateInfo.inCollection !== !!job?.inCollection
        || estimateInfo.writtenOff !== !!job?.writtenOff) {
        setUpdateData(true)
      } else {
        setUpdateData(false)
      }
    }, [estimateInfo]) */

  /*   const fetchEstimate = useCallback(() => {
      if (estimate?.id) {
  
        dispatch(estimateActions.fetchEstimate(estimate?.id,
          (_succ: boolean) => {
            setOpen(false)
            setAddress('')
          }))
      }
    }, []) */

  /*   const onUpdateJob = (newValue: Partial<JobItem>, callback?: (() => void) | undefined): void => {
      setLoading(true)
      dispatch(jobActions.updateJob(newValue, (succ) => {
        if (callback && succ) callback()
        setLoading(false)
        setUpdateData(false)
      }))
    } */

  const onUpdateEstimateProps = (newValue: Partial<EstimateProperty>): void => {
    setLoading(true)
    dispatch(estimateActions.updateEstimateProperty(newValue, () => {
      setLoading(false)
      setUpdateData(false)
    }))
  }
  /* 
    const onSubmit = (): void => {
      let requestItem: Partial<JobItem> = {}
      let requestProperties: Partial<JobProperties> = {}
      if (estimateInfo.notes && job?.properties && estimateInfo.notes !== job?.properties.clientNotes) {
        requestProperties = { ...requestProperties, clientNotes: estimateInfo.notes }
      }
      if (estimateInfo.payAtClose !== job?.properties.payAtClose) {
        requestProperties = { ...requestProperties, payAtClose: estimateInfo.payAtClose }
      }
      if (estimateInfo.inCollection !== !!job?.inCollection) {
        requestItem = { ...requestItem, inCollection: estimateInfo.inCollection }
      }
      if (estimateInfo.writtenOff !== !!job?.writtenOff) {
        requestItem = { ...requestItem, writtenOff: estimateInfo.writtenOff }
      }
  
  
  
      if (Object.keys(requestProperties).length !== 0 && Object.keys(requestItem).length !== 0) {
        onUpdateJob(requestItem, () => onUpdateJobProps(requestProperties))
      } else if (Object.keys(requestProperties).length !== 0) {
        onUpdateJobProps(requestProperties)
      } else if (Object.keys(requestItem).length !== 0) {
        onUpdateJob(requestItem)
      }
    } */

  const handleChange = (newAddress: Address) => {
    setAddress(newAddress)
  }

  const handleClose = () => {
    setOpen(false)
    setAddress('')
  }

  const handleSaveEditAddress = () => {
    if (isValid) {
      var editAdress: EstimateAddress = {
        city: address.city,
        state: address.state,
        line_1: address.line_1,
        line_2: address.line_2,
        zipCode: address.zipCode,
        latitude: address.latitude,
        longitude: address.longitude,
        fullAddress: '',
      }

      let requestProperties: Partial<EstimateProperty> = {}
      requestProperties = { ...requestProperties, address: editAdress }
      onUpdateEstimateProps(requestProperties)

      var unitNumber = address.line_2 !== '' ? ' Unit ' + address.line_2 : ' '
      setEstimateInfo({
        ...estimateInfo,
        lat: address.latitude,
        lng: address.longitude,
        fullAddress:
          address.line_1 +
          unitNumber +
          ', ' +
          address.city +
          ', ' +
          address.state +
          ' ' +
          address.zipCode,
      })

      setUpdateData(true)
      setOpen(false)
      setAddress('')
    }
  }

  const modalAction: ModalActionType[] = [
    {
      textButton: 'Cancel',
      variant: 'outlined',
      onClick: () => {
        handleClose()
      },
    },
    {
      textButton: 'Update Address',
      variant: 'contained',
      onClick: () => {
        handleSaveEditAddress()
      },
      icon: 'Save',
      iconColor: 'white',
      disabled: !isValid,
    },
  ]

  const handleUpdateEstimateProperty = (
    newProperty: Partial<EstimateProperty>,
    applyLoading: boolean = true
  ): void => {
    if (applyLoading) setPageLoading(true)
    dispatch(
      estimateActions.updateEstimateProperty(newProperty, (succ) => {
        setPageLoading(false)
        setMinimumSaving(false)
        setAdjustmentSaving(false)
      })
    )
  }

  const handleUpdateEstimate = (
    newEstimate: Partial<EstimateItem>,
    applyLoading: boolean = true
  ): void => {
    if (applyLoading) setPageLoading(true)
    dispatch(
      estimatesActions.updateEstimate(newEstimate, (succ) => {
        setPageLoading(false)
      })
    )
  }

  const debouncedUpdateEstimateProperty = useCallback(
    debounce(handleUpdateEstimateProperty, 500),
    []
  )

  const googleMapContent: JSX.Element = useMemo(
    () => (
      <Grid
        container
        style={{ height: '100%' }}
        sx={{ paddingLeft: '0.25rem' }}
      >
        <Grid item xs={12} style={{ height: '100%' }}>
          {isLoaded && (
            <GoogleMap
              center={estimateInfo}
              zoom={15}
              mapContainerStyle={containerStyle}
              options={{
                clickableIcons: false,
                fullscreenControl: false,
                zoomControl: false,
                mapTypeControl: true,
                mapTypeControlOptions: {
                  style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                  position: google.maps.ControlPosition.TOP_RIGHT,
                  mapTypeIds: ['roadmap', 'satellite', 'streetview'],
                },
              }}
            >
              <StreetViewPanorama options={streetViewPanoramaOptions} />
              <Marker position={estimateInfo} />
            </GoogleMap>
          )}
        </Grid>
      </Grid>
    ),
    [estimateInfo, isLoaded]
  )

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        gap={2}
        justifyContent="space-between"
        marginTop={3}
      >
        <BoxContainer
          borderColor="#FFFFFF"
          sx={{ width: '100%', height: '310px', justifyContent: 'flex-start' }}
          title={
            <>
              <TitleFirst
                alignItems="center"
                black={estimateInfo.fullAddress}
                blackVariant="h5"
                lineHeight="24px"
                sx={{ 'margin-left': '-5px' }}
                rightIcon={
                  <IconButton
                    size="small"
                    onClick={() => {
                      setOpen(true)
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                }
              />
            </>
          }
        >
          {googleMapContent}
        </BoxContainer>
        <BoxContainer
          borderColor="#FFFFFF"
          sx={{
            width: '100%',
            height: '340px',
            justifyContent: 'flex-start',
            gap: '8px',
          }}
          title={
            <TitleFirst
              alignItems="center"
              black="Details & Adjustments"
              blackVariant="h5"
              lineHeight="24px"
              sx={{ 'margin-left': '-5px' }}
            />
          }
        >
          <Grid item container spacing={1} alignItems="flex-end">
            <Grid item xs={8}>
              <TextFieldLabel
                label="Adjustment %"
                textFieldStyle={{ height: '37px' }}
                value={`${adjustment}`}
                onChange={(e) => setAdjustment(e.target.value)}
                disabled={!(roleTypes?.includes(EMPLOYEE_ROLE_TYPE.ADMIN) || roleTypes?.includes(EMPLOYEE_ROLE_TYPE.SUPER_ADMIN))}
              />
            </Grid>
            <Grid item xs={4}>
              <ModalButton
                variant="contained"
                style={{ backgroundColor: '#1CCD83', border: '0px' }}
                endIcon={
                  adjustmentSaving ? (
                    <CircularProgress color="inherit" size={18} />
                  ) : (
                    <SaveIcon style={{ height: '16px', width: '16px' }} />
                  )
                }
                onClick={() => {
                  setAdjustmentSaving(true)
                  handleUpdateEstimateProperty(
                    { discount: Number(adjustment) },
                    false
                  )
                }}
                fullWidth
              >
                Save
              </ModalButton>
            </Grid>
          </Grid>

          <Grid item container spacing={1} alignItems="flex-end">
            <Grid item xs={8}>
              <TextFieldLabel
                label="Modify Minimun"
                value={minimum}
                onChange={(e) => setMinimum(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <ModalButton
                variant="contained"
                style={{ backgroundColor: '#1CCD83', border: '0px' }}
                endIcon={
                  minimumSaving ? (
                    <CircularProgress color="inherit" size={18} />
                  ) : (
                    <SaveIcon style={{ height: '16px', width: '16px' }} />
                  )
                }
                onClick={() => {
                  setMinimumSaving(true)
                  handleUpdateEstimateProperty(
                    { minimum: Number(minimum) },
                    false
                  )
                }}
                fullWidth
              >
                Save
              </ModalButton>
            </Grid>
          </Grid>

          <Grid item container spacing={1}>
            {controls.map(
              ({ label, checked, propertyName, toogle, disabled }) => (
                <Grid item xs={6}>
                  <FormControlLabel
                    key={label}
                    value={label}
                    sx={{
                      width: '100%',
                      padding: '8px',
                      border: '2px solid #EBECEF',
                      borderRadius: '8px',
                      boxShadow:
                        '0px 1px 1px rgba(26, 42, 85, 0.24), 0px 0px 1px rgba(26, 42, 85, 0.31)',
                    }}
                    control={
                      <Checkbox
                        color="primary"
                        style={{
                          padding: 0,
                          paddingRight: '4px',
                          width: '16px',
                          height: '16px',
                        }}
                        checked={checked}
                        onClick={() => {
                          toogle(!checked)
                        }}
                        disabled={disabled}
                      />
                    }
                    label={label}
                  />
                </Grid>
              )
            )}
          </Grid>

          <Grid item container direction="column">
            <Grid item container sx={{ paddingLeft: '0.25rem' }}>
              <Typography variant="h5Bold">Source And Notes</Typography>
              <Grid item xs={12}>
                <LabeledText
                  labelValue="Source:"
                  labelVariant="h6"
                  textValue={estimate?.formType || '-'}
                />
                <LabeledText
                  labelValue="Campaign:"
                  labelVariant="h6"
                  textValue="-"
                />
                <LabeledText labelValue="CC mails:" />
                {estimate?.properties?.contacts?.length === 0 && (
                  <Typography>No CC mails</Typography>
                )}
                {estimate?.properties?.contacts?.map((contact) => {
                  return (
                    <Chip
                      variant="outlined"
                      icon={<EmailIcon style={{ height: '16px' }} />}
                      label={contact.email}
                      style={{ margin: '3px' }}
                    />
                  )
                })}
              </Grid>
            </Grid>
          </Grid>
        </BoxContainer>
        <BoxContainer
          borderColor="#FFFFFF"
          sx={{ width: '100%', height: '340px', justifyContent: 'flex-start' }}
          title={
            <TitleFirst
              alignItems="center"
              black=""
              blackVariant="h6"
              lineHeight="24px"
            />
          }
        >
          <Grid
            container
            columnSpacing={2}
            rowSpacing={1}
            sx={{ paddingLeft: '0.25rem' }}
          >
            <Grid item xs={12}>
              <SelectInput
                label="Affiliate"
                labelStyle={{ fontSize: '12px' }}
                options={affiliates}
                value={
                  affiliateStages.find(
                    (aff) =>
                      aff.affiliateId === estimate?.properties.affiliate &&
                      estimate.properties.affiliate
                  )?.name || ''
                }
                onChange={(newAffiliate) =>
                  handleUpdateEstimateProperty(
                    { affiliate: newAffiliate.key },
                    false
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                label="Assigned Estimator"
                labelStyle={{ fontSize: '12px' }}
                options={estimators}
                value={estimatorName}
                onChange={(newEstimator) => {
                  handleUpdateEstimate({ estimatorId: newEstimator.key }, false)
                  setEstimatorName(newEstimator.label)
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                label="Assigned CSM"
                labelStyle={{ fontSize: '12px' }}
                options={successManagers}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                label="Assigned TM"
                labelStyle={{ fontSize: '12px' }}
                options={territoryManagers}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                label="Assigned BDM"
                labelStyle={{ fontSize: '12px' }}
                options={busDevManagers}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="Due On"
                value={
                  estimate?.dueOn
                    ? formatTimestampDate(
                      estimate?.dueOn
                    )
                    : null
                }
                onChange={(selectedDate) => {
                  handleUpdateEstimate(
                    { dueOn: formatDateTimestamp(selectedDate) }
                  )
                }}
                size="small"
                style={{ height: '40px', padding: '0px' }}
                // disabled={disableInputs}
                align="right"
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                label='Territory:'
                onChange={handleATerritoryChange}
                options={territoriesOtions}
                value={territory}
                canRemove={false}
                sx={{ maxWidth: '240px', minWidth: '156px', flex: '1' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldLabel
                labelVariant="h6"
                label="Note:"
                value={estimateInfo.notes || ''}
                multiline
                rows={3}
                placeholder="Write some notes here..."
                size="small"
                onChange={(e) => {
                  setEstimateInfo({ ...estimateInfo, notes: e.target.value })
                  debouncedUpdateEstimateProperty(
                    { clientNotes: e.target.value },
                    false
                  )
                }}
              // disabled
              />
            </Grid>
          </Grid>
        </BoxContainer>
      </Box>
      {/* {updateData && (
        <ActionsButton
          variant='contained'
          sx={{
            position: 'fixed',
            height: '40px !important',
            width: '100PX',
            bottom: '40px',
            right: '40px'
          }}
          loading={loading}
          text='Save changes'
          onClick={onSubmit}
        />
      )} */}

      <Box
        sx={{
          position: 'absolute',
          bottom: '12px',
          right: '12px',
        }}
      >
        {open && (
          <Modal
            setOpen={setOpen}
            open={open}
            title={'Edit estimate Address Information'}
            actions={modalAction}
            onClose={() => {
              handleClose()
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectAddress
                  placeholder="Property address"
                  showWarnings={false}
                  savedAddress={address}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid
                  item
                  container
                  direction="column"
                  sx={{ backgroundColor: '#E5F7FF' }}
                >
                  <Grid item container>
                    <icons.InfoRounded
                      style={{
                        marginTop: 3,
                        width: '12px',
                        height: '12px',
                        color: '#1A2A55',
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      sx={{ color: '#1A2A55', fontFamily: 'nouvelle' }}
                    >
                      Changing the Address is an action that can’t be undone.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Modal>
        )}
      </Box>
    </>
  )
}

export default Information
