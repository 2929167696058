import { useEffect, useState, FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, Typography, Checkbox, FormControlLabel } from '@mui/material'
import { SelectInput, ButtonSelector, TextFieldLabel, Box } from 'src/components/UI'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import DatePicker from 'src/components/UI/CustomUI/atoms/DatePicker'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { getEmployee, getTerritoriesSelector, getVendor } from 'src/ducks/selectors'
import { vendorActions } from 'src/ducks/actions'
import {
  formatDateTimestamp, formatTimestampDate, REVIEW_STATUS, getOptionsFromArray, enumToArrayObject,
  INSURANCE_TYPE, LICENSE_TYPE, AMOUNT_MASK, unMaskedInput, EMPLOYEE_ROLE_TYPE
} from 'src/helpers'

import Modal from 'src/components/UI/CustomUI/molecules/Modal'

import styles from './styles.module.scss'
import { createNumberMask } from 'text-mask-addons'
import { ModalsProps } from './types'

const insuranceOptions = [{ key: INSURANCE_TYPE.WORKERS_COMPENSATION, label: "WC" }, { key: INSURANCE_TYPE.CERTIFICATE_OF_INSURANCE, label: "Col" }, { key: INSURANCE_TYPE.OTHER, label: "Other" }]
const licenseOptions = [{ key: LICENSE_TYPE.FORM_W9, label: "W9" }, { key: LICENSE_TYPE.IDENTIFICATION, label: "ID" }, { key: LICENSE_TYPE.TRADE_LICENSE, label: "Trade License" }, { key: LICENSE_TYPE.TRADE, label: "Trade" }, { key: LICENSE_TYPE.OTHER, label: "Other" }]

const Modals: FC<ModalsProps> = ({ item, contact, onClose, onSave, licenseContactList = [], vendorId }) => {
  const dispatch = useDispatch()
  const vendor = useSelector(getVendor())
  const url = item?.fileLocation === 'CLOUDINARY' ? 'https://res.cloudinary.com/punchlist/raw/upload/' : 'https://files.prod.punchlistapi.com/'
  const employee = useSelector(getEmployee)
  const [disabled] = useState(!employee.roles.some(role => [EMPLOYEE_ROLE_TYPE.TERRITORY_VENDOR_MANAGER, EMPLOYEE_ROLE_TYPE.SUPER_ADMIN, EMPLOYEE_ROLE_TYPE.MANAGER, EMPLOYEE_ROLE_TYPE.ADMIN ].includes(role.roleType)))

  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [isInsurance, setIsInsurance] = useState(item.licenseType === undefined)

  const [selTerritories, setSelTerritories] = useState<string[]>([])
  const [selTerrValues, setSelTerrValues] = useState<boolean[]>([])
  const territoriesOtions = useSelector(getTerritoriesSelector)
  const [dates, setDates] = useState<{ validFromDate: number | null, validToDate: number | null }>({
    validFromDate: item.validFromDate,
    validToDate: item.validToDate
  })
  const enumArray = enumToArrayObject(REVIEW_STATUS)
  const statusOtions = getOptionsFromArray(enumArray, { key: 'key', label: 'label' })
  const [status, setStatus] = useState({
    key: item?.reviewStatus,
    label: item?.reviewStatus?.slice(0, 1) + item?.reviewStatus?.toLowerCase()?.slice(1)?.replaceAll('_', ' ')
  })
  const [insurance, setInsurance] = useState({ key: '', label: '' })
  const [insuranceCompanyName, setInsuranceCompanyName] = useState(item?.insuranceCompanyName || '')
  const [amountOfCoverage, setAmountOfCoverage] = useState(`${item?.amountOfCoverage || ''}`)
  const [additionalInsured, setAdditionalInsured] = useState(item?.additionalInsured || false)
  const [updateDisabled, setUpdateDisabled] = useState<boolean>(false)

  const currencyMask = createNumberMask(AMOUNT_MASK(1))

  useEffect(() => {
    switch (isInsurance) {
      case false:
        setUpdateDisabled(false)
        break
      case true:
        console.log(status)
        setUpdateDisabled(!((insuranceCompanyName !== '' && !!amountOfCoverage && !!dates.validFromDate && !!dates.validToDate) || status.key === 'EXPIRED'))
        break
    }
  }, [isInsurance, insuranceCompanyName, amountOfCoverage, dates, status])

  useEffect(() => {
    setUpdateDisabled(true)

    if (vendorId?.length) {
      dispatch(
        vendorActions.fetchVendor(vendorId, (succ) => {
          setUpdateDisabled(false)
        })
      )
    }
  }, [vendorId])

  useEffect(() => {
    if (contact) handleOpen()
  }, [contact])

  const updatePro = () => {
    setLoading(true)
    const originInsurance = item.licenseType === undefined
    const id = originInsurance ? vendor?.insuranceList?.findIndex((c) => c.id === item.id) : vendor?.licenseList?.findIndex((c) => c.id === item.id)

    if ((originInsurance && isInsurance) || (!originInsurance && !isInsurance)) {
      const newItem = item
      if (dates.validFromDate) {
        newItem.validFromDate = dates.validFromDate
      }

      if (dates.validToDate) {
        newItem.validToDate = dates.validToDate
      }

      if (status.key !== item.reviewStatus) {
        newItem.reviewStatus = status.key
      }

      if (isInsurance) {
        if (insuranceCompanyName) {
          newItem.insuranceCompanyName = insuranceCompanyName
        }
        if (amountOfCoverage) {
          newItem.amountOfCoverage = unMaskedInput(amountOfCoverage)
        }
        // if (additionalInsured) {
        newItem.additionalInsured = additionalInsured || false
        // }
      }


      if (originInsurance) {
        if (insurance.key !== item.insuranceType) {
          newItem.insuranceType = insurance.key
        }
      } else {
        if (insurance.key !== item.licenseType) {
          newItem.licenseType = insurance.key
        }
      }

      const newValues = [] as string[]
      const options = territoriesOtions.slice(1)
      selTerrValues.forEach((item: boolean, index: number) => {
        if (item) {
          newValues.push(options[index].key)
        }
      })
      newItem.territories = newValues

      const list = originInsurance ? 'insuranceList' : 'licenseList'
      dispatch(
        vendorActions.updateVendorList(newItem, id ?? -1, list, () => {
          setOpen(false)
          setLoading(false)
          if (onSave) onSave()
          if (onClose) onClose()
        })
      )
    } else {
      // delete object insurance/license and create new object license/insurance
      const newItem = originInsurance
        ? { fileLocation: item?.fileLocation, fileName: item?.fileName, licenseType: insurance.key, reviewStatus: status.key } as Partial<any>
        : { fileLocation: item?.fileLocation, fileName: item?.fileName, insuranceType: insurance.key, reviewStatus: status.key } as Partial<any>

      if (dates.validFromDate) {
        newItem.validFromDate = dates.validFromDate
      }

      if (dates.validToDate) {
        newItem.validToDate = dates.validToDate
      }

      if (isInsurance) {
        if (insuranceCompanyName) {
          newItem.insuranceCompanyName = insuranceCompanyName
        }
        if (amountOfCoverage) {
          newItem.amountOfCoverage = unMaskedInput(amountOfCoverage)
        }
        if (additionalInsured) {
          newItem.additionalInsured = additionalInsured
        }
      }

      const newValues = [] as string[]
      const options = territoriesOtions.slice(1)
      selTerrValues.forEach((item: boolean, index: number) => {
        if (item) {
          newValues.push(options[index].key)
        }
      })
      newItem.territories = newValues
      dispatch(
        vendorActions.deleteVendorInsurance(newItem, id ?? -1, originInsurance, () => {
          setOpen(false)
          setLoading(false)
          if (onSave) onSave()
          if (onClose) onClose()
        })
      )
    }
  }

  const handleOpen = () => {
    if (disabled) {
      return
    }
    const options = territoriesOtions.slice(1)
    const terr = options.map((t: { label: any }) => { return t.label })
    const terrValues = options.map(t => { return item.territories?.find((et: any) => et === t.key) !== undefined || false })
    setSelTerritories(terr)
    setSelTerrValues(terrValues)
    setIsInsurance(item.licenseType === undefined)
    setDates({
      validFromDate: item.validFromDate,
      validToDate: item.validToDate
    })
    if (item.licenseType === undefined) {
      setInsurance({
        key: item?.insuranceType,
        label: item?.insuranceType.slice(0, 1) + item?.insuranceType.toLowerCase().slice(1).replaceAll('_', ' ')
      })
    } else {
      setInsurance({
        key: item?.licenseType,
        label: item?.licenseType.slice(0, 1) + item?.licenseType.toLowerCase().slice(1).replaceAll('_', ' ')
      })
    }

    setStatus({
      key: item?.reviewStatus,
      label: item?.reviewStatus.slice(0, 1) + item?.reviewStatus.toLowerCase().slice(1).replaceAll('_', ' ')
    })
    setOpen(true)
    setLoading(false)
  }

  const handleClose = () => {
    setOpen(false)
    if (onClose) onClose()
  }

  const handlStatusChange = (newSelected: SelectInputOption) => {
    if (newSelected === null) {
      setStatus(statusOtions[0])
    } else {
      setStatus(newSelected)
    }
  }

  const handlInsuranceChange = (newSelected: SelectInputOption) => {
    if (newSelected === null) {
      setInsurance(insuranceOptions[0])
    } else {
      setInsurance(newSelected)
    }
  }

  const handleChangeInsurance = () => {
    setIsInsurance(!isInsurance)
    if (isInsurance) {
      setInsurance(licenseOptions[0])
    } else {
      setInsurance(insuranceOptions[0])
    }
  }

  const getContent = () => {
    return (
      <Grid container padding='0 0 1.5rem 0' display='flex' justifyContent='flex-start'>
        <Grid container item xs={6} justifySelf='start' alignSelf='start' spacing={2}>
          <Grid item xs={12} padding='0 12px 0 0'>
            <Typography variant='h5'> File: </Typography>
            <Grid item container display='flex' direction='row' sx={{ background: 'var(--gray100)', borderRadius: '4px', marginRight: '6px' }}>
              <Button
                href={item?.fileName ? url + item?.fileName : ''}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Icon name='Description' color='var(--navy-700)' sx={{ margin: '4px' }} />
                <Typography variant='body1' color='var(--blue700)' style={{ textDecoration: 'underline', margin: '4px' }}>
                  {item?.fileName ? item?.fileName : '_'}
                </Typography>
              </Button>
            </Grid>
          </Grid>
          {contact && !isInsurance && (
            <Grid bgcolor='#FAFDFF' container item xs={12} spacing={1} borderRadius={2} justifyContent='center' alignItems='center' marginLeft={2} marginTop={1} style={{ padding: '0 8px 8px 0' }}>
              {licenseContactList.map((contactItem, index) => (
                <Grid key={index} item xs={6} className={styles.Modal__contactStyle}>
                  <Typography fontWeight={400}>{contactItem.key}</Typography>
                  <Typography fontWeight={600}>{contactItem.value || '-'}</Typography>
                </Grid>
              ))}
            </Grid>
          )}
          {!contact && (
            <Grid item xs={12} gap={1}>
              <Typography variant='h6'> File Type </Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <ButtonSelector onClick={() => handleChangeInsurance()} value={!isInsurance}> License </ButtonSelector>
                </Grid>
                <Grid item xs={6}>
                  <ButtonSelector onClick={() => handleChangeInsurance()} value={isInsurance}> Insurance </ButtonSelector>
                </Grid>
              </Grid>
            </Grid>
          )}
          {isInsurance && <Grid container item xs={12} spacing={1}>
            <Grid item xs={6}>
              <TextFieldLabel
                label="Insurance Company:"
                labelVariant="h6"
                value={insuranceCompanyName}
                onChange={e => setInsuranceCompanyName(e.target.value)}
                placeholder="Insurance Company Name"
                name="insurance-company"
                type='text'
                id='insurance-company'
                error={status.key !== 'EXPIRED' && !insuranceCompanyName}
                required
                helperText={status.key !== 'EXPIRED' && !insuranceCompanyName && "This field is required"}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldLabel
                label="Amount of Coverage:"
                labelVariant="h6"
                value={amountOfCoverage}
                onChange={e => setAmountOfCoverage(e.target.value)}
                placeholder="20,000"
                mask={currencyMask}
                name="amount-of-coverage"
                type='text'
                id="amount-of-coverage"
                error={status.key !== 'EXPIRED' && !amountOfCoverage}
                required
                helperText={status.key !== 'EXPIRED' && !amountOfCoverage && "This field is required"}
              />
            </Grid>
            <Grid item xs={12} marginTop={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={additionalInsured}
                    onChange={() => setAdditionalInsured(!additionalInsured)}
                    name='remember'
                    color='infoText'
                    sx={{
                      padding: '0 !important',
                      marginRight: '.5rem'
                    }}
                  />
                }
                label={<Typography variant='body2'>Additional Insured</Typography>}
              />
            </Grid>
          </Grid>}
          <Grid container item xs={12} spacing={1} >
            <Grid item xs={6}>
              <DatePicker
                placeholder='MM/DD/YYYY'
                onChange={(dateSelected: Date) => setDates({ ...dates, validFromDate: formatDateTimestamp(dateSelected) })}
                value={dates?.validFromDate ? formatTimestampDate(dates?.validFromDate) : null}
                style={{ height: '38px', padding: '0px', width: '100%' }}
                size='small'
                label='Valid from Date:'                
                error={status.key !== 'EXPIRED' && !dates.validFromDate}
                required
                helperText={status.key !== 'EXPIRED' && !dates.validFromDate && "This field is required"}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                placeholder='MM/DD/YYYY'
                onChange={(dateSelected: Date) => setDates({ ...dates, validToDate: formatDateTimestamp(dateSelected) })}
                value={dates?.validToDate ? formatTimestampDate(dates?.validToDate) : null}
                style={{ height: '38px', padding: '0px', width: '100%' }}
                size='small'
                label='Valid to Date:'
                error={status.key !== 'EXPIRED' && !dates.validToDate}
                required
                helperText={status.key !== 'EXPIRED' && !dates.validFromDate && "This field is required"}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={1} >
            <Grid item xs={6}>
              <SelectInput
                label={isInsurance ? 'Insurance Type:' : 'License Type:'}
                onChange={handlInsuranceChange}
                options={isInsurance ? insuranceOptions : licenseOptions}
                value={insurance}
                sx={{ flex: '1' }}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                label='Review Status:'
                onChange={handlStatusChange}
                options={statusOtions}
                value={status}
                sx={{ flex: '1' }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Typography variant='h5'> Territories </Typography>
          <Grid container item>
            {selTerritories.map((terr: string, index: number) => {
              return (
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selTerrValues[index]}
                        onChange={() => setSelTerrValues(selTerrValues.map((val, i) => i === index ? !val : val))}
                        name='remember'
                        color='infoText'
                      />
                    }
                    label={<Typography variant='body2'>{'' + terr}</Typography>}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {!contact && (
        <Button
          sx={{ margin: '0px', width: '100%', height: '30px !important' }}
          onClick={() => handleOpen()}
          variant='contained'
        >
          Review
        </Button>
      )}
      <Modal
        setOpen={setOpen}
        open={open}
        className={styles.Modal}
        loading={loading}
        onClose={onClose}
        title={
          <Grid container justifyContent='left' padding='1rem'>
            <Typography variant='h5Bold'>{isInsurance ? 'Insurance Details' : 'License Details'}  </Typography>
          </Grid>
        }
      >
        {getContent()}
        <Grid container justifyContent='space-between'>
          {contact && (
            <Grid item xs={3}>
              <Button
                variant='outlined'
                onClick={() => handleChangeInsurance()}
                disabled={isInsurance}
              >
                Make Insurance Document
              </Button>
            </Grid>
          )}
          <Grid container item xs={contact ? 9 : 12} gap={1} display='flex' alignItems='flex-end' justifyContent='flex-end'>
            <Button
              sx={{ width: '200px' }}
              variant='outlined'
              onClick={() => handleClose()}
            >
              Close
            </Button>
            <Button
              sx={{ width: '200px' }}
              variant='contained'
              onClick={updatePro}
              disabled={updateDisabled}
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}

export default Modals
