/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { Box, Typography } from '../../../../../UI'
import { getVendor, getTerritories } from 'src/ducks/selectors'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import { useDispatch, useSelector } from 'react-redux'
import { VendorContext } from '../../context'
import headers, { headersInsurance, headersLicenses } from './headers'
import { territoriesActions } from 'src/ducks/actions'

export const LicensesView: FC = () => {
  const vendor = useSelector(getVendor())
  return (
    <>
      <Table headers={headersLicenses}>
        {vendor?.licenseList}
      </Table>
    </>
  )
}

export const InsurancesView: FC = () => {
  const vendor = useSelector(getVendor())
  return (
    <>
      <Table headers={headersInsurance}>
        {vendor?.insuranceList}
      </Table>
    </>
  )
}

const TABS = [
  {
    label: 'Licenses',
    content: <LicensesView />
  },
  {
    label: 'Insurances',
    content: <InsurancesView />
  }
]

export type DocumentValue = { territory: string, gl: boolean, wc: boolean, glExpiration: string, wcExpiration: string, wcExempt: boolean, toogleWcExempt?: () => void }

const Documents: FC = () => {
  const dispatch = useDispatch()

  // const userData = useSelector(getVendorData())
  // const userStatsPros = useSelector(getVendorStatics())
  const { editedVendor, setEditedVendor } = useContext(VendorContext)

  const [dataValues, setDataValues] = useState<DocumentValue[]>([])
  const [tableLoading, setTableLoading] = useState<boolean>(false)

  useEffect(() => {
    const insuranceList = editedVendor?.insuranceList
    const territories = editedVendor?.territoryList

    setTableLoading(true)

    // insuranceList?.forEach((insurance) => {
    //   insurance.territories?.forEach((territory: string) => {
    //     if (!territories.includes(territory)) {
    //       territories.push(territory)
    //     }
    //   })
    // })

    const data: DocumentValue[] = []

    territories?.forEach((territory) => {
      let gl = false
      let wc = false
      let glExpiration = '-'
      let wcExpiration = '-'
      const wcExempt = (editedVendor && editedVendor.wcExemptTerritories && editedVendor.wcExemptTerritories.includes(territory.id)) ?? false

      insuranceList?.forEach((insurance) => {
        if (insurance?.territories?.includes(territory.id)) {
          if (insurance?.insuranceType === 'CERTIFICATE_OF_INSURANCE') {
            gl = true
            glExpiration = moment(insurance?.validToDate * 1000).format('MM/DD/YYYY')
          }

          if (insurance.insuranceType === 'WORKERS_COMPENSATION') {
            wc = true
            wcExpiration = moment(insurance?.validToDate * 1000).format('MM/DD/YYYY')
          }
        }
      })

      data.push({
        territory: territory.title,
        gl: gl,
        glExpiration: glExpiration,
        wcExpiration: wcExpiration,
        wc: wc,
        wcExempt: wcExempt,
        toogleWcExempt: () => {
          if (!wcExempt) {
            setEditedVendor({
              ...editedVendor,
              wcExemptTerritories: [...new Set([...editedVendor?.wcExemptTerritories || [], territory.id ])] 
            })
          }
          else {
            setEditedVendor({
              ...editedVendor,
              wcExemptTerritories: editedVendor?.wcExemptTerritories?.filter((curTerr) => curTerr !== territory.id) || [] 
            })
          }
        }
      })
    })

    setDataValues(data)
    setTableLoading(false)
  }, [editedVendor])

  useEffect(() => {
    dispatch(
      territoriesActions.fetchTerritories(() => { })
    )
  }, [])

  return (
    <Box display='flex' gap={2}>
      <Box display='flex' flexDirection='column' gap={1} flex='1'>
        <Typography variant='h4' fontFamily='Nouvelle' style={{ marginTop: '16px' }}>Insurance</Typography>

        <Table headers={headers} hasPagination={false} loading={tableLoading}>
          {dataValues ?? []}
        </Table>

        <TabNavigation tabs={TABS} />
      </Box>
    </Box>
  )
}

export default Documents
