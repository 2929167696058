/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Checkbox, Grid, TextField, Typography } from '@mui/material'
import { FC, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import Tag from 'src/components/UI/CustomUI/atoms/Tag'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { AlignType } from 'src/components/UI/CustomUI/organisms/Table/types'
import { getItemsSortedByCategory, getJob, getOrderById, getOrders } from 'src/ducks/selectors'
import { dateFormatString, formatTimestampDate, getColorBySeverity, ITEM_STATUS, ORDER_STATUS, round } from 'src/helpers'
import DropDownSelect from './DropDownSelect'
import styles from './styles.module.scss'
import ItemActions from './ItemActions'
import { WorkOrdersContext } from '../context'
import Header from './Header'
import { PrivateLoader } from 'src/components/templates'
import { GroupedItem } from 'src/ducks/types'
import { computer } from 'src/assets'
import Status from './Status'
import { itemsActions, territoryActions } from 'src/ducks/actions'
import { useParams, useSearchParams } from 'react-router-dom'

const OrderItems: FC<{ orderId?: string | null, fullHeader?: boolean, tabName?: string }> = ({ orderId = null, fullHeader = false, tabName }) => {
  const {
    dispatch: dispatchContext,
    state: { checkAll, expandAll, itemsLoading, refreshSite, selectedOrderId }
  } = useContext(WorkOrdersContext)
  const dispatch = useDispatch()
  const { id } = useParams()
  const itemsByCategory = useSelector(getItemsSortedByCategory())
  const [tableLoading, setTableLoading] = useState(true)
  const job = useSelector(getJob())
  const [searchParams] = useSearchParams()

  const orderSelected = useSelector(getOrderById(selectedOrderId as string))
  const {
    completedTimestamp, vendorPaymentTimestamp,
    statusType, csmAcceptanceTimestamp,

  } = orderSelected

  const orders = useSelector(getOrders())
  const order = searchParams?.get('order') ?? ''

  const showPaid = statusType && (ORDER_STATUS.PAID === statusType || vendorPaymentTimestamp != null)
  const showAccepted = statusType && (ORDER_STATUS.COMPLETE === statusType || showPaid || csmAcceptanceTimestamp != null)
  const showComplete = statusType && (ORDER_STATUS.REVIEW === statusType || showAccepted || completedTimestamp != null)

  const getEvaluatePriceRangeString = (min: number, max: number) => {
    const dash = min && max
    return (
      (min ? `min: $${round(min || 0, 2)}` : '') +
      (dash ? ` - ` : '') +
      (max ? `max: $${round(max || 0, 2)}` : '')
    )
  }

  const headers = [
    {
      id: 'checkbox',
      custom: true,
      Element: (row: any, index: number): JSX.Element => {
        return (
          <Checkbox
            key={index}
            checked={row.checked || false}
            onClick={(event) => {
              event.stopPropagation()
              const copyWorkItems: GroupedItem[] = [...itemsByCategory]
              const categoryIndex = copyWorkItems.findIndex((category) => category.category === row.category)
              copyWorkItems[categoryIndex].items[index].checked = !copyWorkItems[categoryIndex].items[index].checked
              dispatch(itemsActions.setItemsByCategory(copyWorkItems))
            }}
            color='infoText'
          />
        )
      },
      width: 20
    },
    {
      id: 'inspectionReportReference',
      label: 'Ref #'
    },
    {
      id: 'item',
      label: 'Item',
      custom: true,
      width: 900,
      Element: (row: any, index: number): JSX.Element => {
        const { background } = getColorBySeverity(row.severity)
        const vendorIsPaid = orders.find(order => order.orderId === row.orderId)?.vendorPaymentTimestamp !== null || (showAccepted && showComplete)
        return (
          <Box marginRight={3}>
            <Box className={styles.Actions}>
              <Button
                variant='outlined'
                startIcon={<Icon name='Image' />}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  event.stopPropagation()
                  dispatchContext({ type: 'SET_SELECTED_ITEM_ID', payload: row.itemId })
                  dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
                  dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'IMAGES' })
                }}
              >
                View Images
              </Button>
              {!vendorIsPaid && <ItemActions itemId={row.itemId} orderId={row.orderId} status={row.status} indexCategory={index} itemCategory={row.category} />}
            </Box>
            <Box display='flex' flexDirection='column' gap={0.5}>
              <Box display='flex' gap={1}>
                <Typography variant='h6' color='var(--blue700)' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  {row.title}
                </Typography>
                <Tag
                  sx={{ paddingX: 2, textTransform: 'uppercase', height: '25px' }}
                  variant='caption'
                  width='fit-content'
                  background={background}
                  color='var(--black-color)'
                >
                  {row.severity}
                </Tag>
                {row.siteVisitId && (
                  <Tag
                    sx={{ paddingX: 2, textTransform: 'uppercase', cursor: 'pointer' }}
                    variant='caption'
                    width='fit-content'
                    background='#FAFDFF'
                    color='#0071A3'
                    onClick={(event) => {
                      event.stopPropagation()
                      dispatchContext({ type: 'SET_SELECTED_SITE_VISIT_ID', payload: row.siteVisitId })
                      dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
                      dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'SITE_VISIT' })
                    }}
                  >
                    <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                      <Icon name='CalendarToday' />
                      <Typography>
                        SITE VISIT SCHEDULED
                      </Typography>
                    </Box>
                  </Tag>
                )}
                {row.status === ITEM_STATUS.REJECTED && (
                  <Typography color='var(--red600)' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    {`(Client Rejected ${dateFormatString(formatTimestampDate(row.rejectedOn), 'MM-DD-YYYY hh:mm:ss')})`}
                  </Typography>
                )}
              </Box>

              {row.category === 'EVALUATE' && (
                <Grid>
                  {((row.minPriceRange || row.maxPriceRange) && (
                    <Typography>
                      <Typography variant='body1Bold' color='var(--green900)'>
                        Price Range: {getEvaluatePriceRangeString(row.minPriceRange, row.maxPriceRange)}
                      </Typography>
                      { }
                    </Typography>
                  )) || null}
                  <Typography>
                    <Typography variant='body1Bold'>Evaluate Reason: </Typography>{row.evaluateReason}
                  </Typography>
                </Grid>
              )}

              <Typography variant='body1Bold'>{row.disclaimer}</Typography>
              {row.inspectionReportNote && <Typography><Typography variant='body1Bold'>Inspection Notes: </Typography>{row.inspectionReportNote}</Typography>}
              {row.requestedRepairNote && <Typography><Typography variant='body1Bold'>Requested Repair: </Typography>{row.requestedRepairNote}</Typography>}
              {row.followupNotes && <Typography><Typography variant='body1Bold' color='error'>Rejected Notes: </Typography>{row.followupNotes}</Typography>}
              {row.fieldNote && <Typography><Typography variant='body1Bold' color='var(--orange500)'>Field Tech Notes: </Typography>{row.fieldNote}</Typography>}
            </Box>
          </Box>
        )
      }
    },
    {
      id: 'quantity', label: 'Qty', align: 'center' as AlignType
    },
    {
      id: 'totalCost',
      label: 'Vendor Price',
      custom: true,
      align: 'center' as AlignType,
      minWidth: 100,
      Element: (row: any): JSX.Element => {
        return (
          <Grid container item direction='column' alignItems='center' textAlign='end'>
            <Typography align='right' variant='body1Bold'>${round(row.totalCost, 2)}</Typography>
            <Typography align='right' fontSize='8px'>${round(row.unitCost, 2)}</Typography>
          </Grid>
        )
      }
    },
    {
      id: 'customerPrice',
      label: 'Customer Price',
      custom: true,
      align: 'center' as AlignType,
      minWidth: 100,
      Element: (row: any): JSX.Element => {
        return (
          <Grid container item direction='column' alignItems='center' textAlign='end'>
            <Typography align='right' variant='body1Bold'>${round(row.totalPrice, 2)}</Typography>
            <Typography align='right' fontSize='8px'>${round(row.unitPrice, 2)}</Typography>
          </Grid>
        )
      }
    },
    {
      id: 'staffReviewed',
      label: 'Reviewed',
      custom: true,
      align: 'center' as AlignType,
      Element: (row: any): JSX.Element => {
        return <Icon name={row.reviewed ? 'CheckBox' : 'CheckBoxOutlineBlank'} color='var(--blue700)' />
      }
    }
  ]

  useEffect(() => {
    fetchItems()
    dispatchContext({ type: 'SET_SELECTED_ORDER_ID', payload: orderId })
  }, [order, id])

  useEffect(() => {
    if (refreshSite) {
      dispatchContext({ type: 'REFRESH_SITE', payload: false })
      fetchItems()
    }
  }, [refreshSite])

  useEffect(() => {
    fetchRepairItems()
  }, [])

  const handleCheckAllWorkItems = (index?: number, checked?: boolean) => {
    const copyWorkItems: GroupedItem[] = [...itemsByCategory]
    const categoryIndex = copyWorkItems.findIndex((item: any) => item.category === copyWorkItems[index as number].category)
    copyWorkItems[categoryIndex].items.forEach((subitem: any) => {
      subitem.checked = !checked
    })
    dispatch(itemsActions.setItemsByCategory(copyWorkItems))
  }

  const fetchItems = () => {

    setTableLoading(true)
    dispatch(itemsActions.fetchItemsByCategory({
      params: { jobId: id, orderId: order }
    }, (_succ) => {
      setTableLoading(false)
    }))
  }

  const fetchRepairItems = () => {
    dispatch(
      territoryActions.fetchTerritoryRepairItems({ territoryId: job?.properties.territory.id, params: { limit: 9999 } })
    )
  }

  const handleClickRow = (row: any) => {
    dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
    dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'EDIT_ITEM' })
    dispatchContext({ type: 'SET_SELECTED_ITEM_ID', payload: row.itemId })
  }

  /* const handleClickOnHold = () => {
    dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
    dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'ON_HOLD' })
  } */

  return (
    <Box height='100%'>
      <Header hasItems={itemsByCategory.length > 0} fullHeader={fullHeader} tabName={tabName} />
      <Status />
      <PrivateLoader loading={tableLoading || itemsLoading} sx={{ alignContent: 'flex-start' }}>
        <Box height='100%'>
          {itemsByCategory.length
            ? <>
              {itemsByCategory.map((workItem: GroupedItem, index: number) => {
                return (
                  <DropDownSelect
                    workItem={workItem}
                    key={index}
                    index={index}
                    initiallyExpanded={expandAll}
                    initiallyChecked={checkAll}
                    checkAll={handleCheckAllWorkItems}
                  >
                    <Table
                      hasPagination={false}
                      headers={headers}
                      className={styles.Table}
                      small
                      hasBorders={false}
                      rowColor={(row: any) => row.status === ITEM_STATUS.NEW ? '#F1F0FF' : ''}
                      rowAction={handleClickRow}
                      loading={tableLoading}
                    >
                      {workItem.items}
                    </Table>
                  </DropDownSelect>
                )
              })}
              <Box sx={{ padding: 1, background: 'var(--blue800)' }}>
                <Typography color='white'>Excluded Items</Typography>
              </Box>
              <Box sx={{ padding: 2 }}>
                <TextField multiline rows={5} fullWidth placeholder='Write your excluded items' />
              </Box>
            </>
            : <Grid container direction='column' justifyContent='center' alignItems='center' padding={12} height='100%'>
              <img src={computer} alt='computer' />
              <Typography marginTop={2} variant='h6Bold' align='center'>No items added to <br /> this order yet</Typography>
            </Grid>}
        </Box>
      </PrivateLoader>
    </Box>
  )
}

export default OrderItems
