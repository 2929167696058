import { Button, Link, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'

import { TableActionProps } from './types'

const TableAction = <T,>({
  children,
  row,
}: TableActionProps<T>) => {
  const dispatch = useDispatch()

  const handleClick = (event: React.SyntheticEvent) => {
    if (children.preventDefault) {
      event.preventDefault()
    }
    children.onClick(row, dispatch)
  }

  if (!children.hide || !children.hide(row)) {
    return children?.textButton
      ? (
        <>
          {children?.tooltip ?
            <Tooltip
              title={
                <Typography color='inherit'>
                  {children.tooltip}
                </Typography>
              }
              placement='top'
              enterDelay={500}
              leaveDelay={200}
              arrow
            >
              <Button variant='contained' onClick={handleClick}>{children?.textButton}</Button>
            </Tooltip>
            :
            <Button
              variant='contained'
              onClick={handleClick}
            >
              {children?.textButton}
            </Button>
          }
        </>
      )
      : (
        <Link
          href=''
          onClick={handleClick}
        >
          {
            children?.linkText
              ? children.linkText(row)
              : ''
          }
        </Link>)
  }
  return <></>
}

export default TableAction