import bills from './bills'
import clients from './clients'
import files from './files'
import orders from './orders'
import employees from './employees'
import vendors from './vendors'
import searches from './searches'
import siteVisits from './siteVisits'
import users from './users'
import territories from './territories'
import jobs from './jobs'
import licenses from './licenses'
import companies from './companies'
import history from './history'
import items from './items'
import workflows from './workflows'
import notifications from './notifications'
import comments from './comments'
import notes from './notes'
import feedback from './feedback'
import invoices from './invoices'
import payments from './payments'
import tradeTypes from './trades'
import communications from './communications'
import materialInvoices from './materialInvoices'
import estimates from './estimates'
import invitations from './invitations'
import subscriptions from './subscriptions'
import insurances from './insurances'
import widgets from './widgets'
import matches from './matches'
import repairList from './repairlist'
import materials from './materials'
import reports from './reports'
import homemart from './homemart'
import discounts from './discounts'
import campaigns from './campaigns'

const punchlist = {
  bills,
  clients,
  files,
  orders,
  employees,
  vendors,
  searches,
  siteVisits,
  users,
  territories,
  jobs,
  licenses,
  companies,
  history,
  items,
  workflows,
  notifications,
  comments,
  feedback,
  notes,
  invitations,
  invoices,
  payments,
  tradeTypes,
  communications,
  materialInvoices,
  estimates,
  subscriptions,
  insurances,
  widgets,
  matches,
  repairList,
  materials,
  reports,
  homemart,
  discounts,
  campaigns,
}

export default punchlist
