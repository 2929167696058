import { UpdateRequest } from '~api/types'
import { GroupedItem, Item, ItemByCategory } from '../types'

/** TYPES **/
export const itemsTypes = {
  FETCH_ITEMS: 'FETCH_ITEMS',
  FETCH_INSPECTION_ITEMS: 'FETCH_INSPECTION_ITEMS',
  FETCH_ITEMS_BY_CATEGORY: 'FETCH_ITEMS_BY_CATEGORY',
  SET_ITEMS: 'SET_ITEMS',
  SET_INSPECTION_ITEMS: 'SET_INSPECTION_ITEMS',
  SET_ITEMS_BY_CATEGORY: 'SET_ITEMS_BY_CATEGORY',
  UPDATE_ITEM: 'UPDATE_ITEM',
  UPDATE_ITEM_TOTALS_SYNC: 'UPDATE_ITEM_TOTALS_SYNC',
  DELETE_ITEM: 'DELETE_ITEM',
  UPDATE_BATCH: 'UPDATE_BATCH',
  DELETE_BATCH: 'DELETE_BATCH',
  CREATE_ITEM: 'CREATE_ITEM',
  REJECT_ITEM: 'REJECT_ITEM',
  RECALL_ITEM: 'RECALL_ITEM',
  MOVE_ITEMS: 'MOVE_ITEMS',
  MOVE_ITEMS_TO_JOB: 'MOVE_ITEMS_TO_JOB',
  MOVE_ITEMS_TO_ESTIMATE: 'MOVE_ITEMS_TO_ESTIMATE',
  RECOVER_ITEM: 'RECOVER_ITEM',
  CREATE_ITEM_FROM_CSV: 'CREATE_ITEM_FROM_CSV',
  UPDATE_ITEM_TOTALS: 'UPDATE_ITEM_TOTALS',
  DELETE_ESTIMATE_ITEMS: 'DELETE_ESTIMATE_ITEMS',
  UPDATE_ITEM_BY_PATH: 'UPDATE_ITEM_BY_PATH',
  COMBINE_ITEMS: 'COMBINE_ITEMS',
  FETCH_RECOMMENDED_ITEMS: 'FETCH_RECOMMENDED_ITEMS',
  SET_RECOMMENDED_ITEMS: 'SET_RECOMMENDED_ITEMS',

}

/** ACTIONS **/
export interface FetchItemsAction {
  type: typeof itemsTypes.FETCH_ITEMS
  payload: {
    params?: Partial<ItemsParams>
  }
  callback?: (succ: boolean, count?: number, items?: Item[]) => void
}
export interface FetchInspectionItemsAction {
  type: typeof itemsTypes.FETCH_INSPECTION_ITEMS
  payload: {
    params?: Partial<ItemsParams>
  }
  callback?: (succ: boolean, count?: number, items?: Item[]) => void
}

export interface FetchItemsCountAction {
  type: typeof itemsTypes.FETCH_ITEMS
  payload: {
    params?: Partial<ItemsParams>
  }
  callback?: (succ: boolean, count: number) => void
}

export interface SetItemsAction {
  type: typeof itemsTypes.SET_ITEMS
  payload: Item[]
}

export interface SetItemsByCategoryAction {
  type: typeof itemsTypes.SET_ITEMS
  payload: GroupedItem[]
}
export interface UpdateItemPayload {
  itemId: string
  partialItem: Partial<Item>
  selectedOrderId?: string
  isEstimate?: boolean
}
export interface DeleteItemPayload {
  itemId: string
  selectedOrderId?: string
  jobId: string
}

export interface UpdateItemAction {
  type: typeof itemsTypes.UPDATE_ITEM
  payload: any
  callback?: (succ: boolean, newItem?: Item) => void
}

export interface DeleteItemAction {
  type: typeof itemsTypes.DELETE_ITEM
  payload: DeleteItemPayload
  callback?: (succ: boolean) => void
}

export interface UpdateBatchPayload {
  itemsIds: string[]
  id?: string
  partialItem: Partial<Item>
  selectedOrderId?: string
}

export interface DeleteBatchPayload {
  itemsIds: string[]
  jobId: string
  selectedOrderId?: string
}

export interface RejectBatchPayload {
  id: string
  itemId: string
  partialItem: Partial<Item>
}

export interface DeleteEstimateItemsAction {
  type: typeof itemsTypes.DELETE_ESTIMATE_ITEMS
  payload: string
  callback?: (succ: boolean) => void
}

export interface UpdateBatchAction {
  type: typeof itemsTypes.UPDATE_BATCH
  payload: UpdateBatchPayload
  callback?: (succ: boolean) => void
}

export interface DeleteBatchAction {
  type: typeof itemsTypes.DELETE_BATCH
  payload: DeleteBatchPayload
  callback?: (succ: boolean) => void
}

export interface CreateItemAction {
  type: typeof itemsTypes.CREATE_ITEM
  //replace with CreateItemRequest
  payload: any
  callback: (succ: boolean, item?: Item) => void
}

export interface RecallItemsAction {
  type: typeof itemsTypes.RECALL_ITEM
  payload: {
    items: Item[]
    id: string
  }
  callback: (succ: boolean, item: Item | null) => void
}

export interface RecoverItemAction {
  type: typeof itemsTypes.RECOVER_ITEM
  payload: {
    item: Item
    id?: string
  }
  callback: (succ: boolean, item: Item | null) => void
}


export interface RejectItemAction {
  type: typeof itemsTypes.REJECT_ITEM
  payload: RejectBatchPayload
  callback?: (succ: boolean) => void
}

export interface MoveItemsBatchAction {
  type: typeof itemsTypes.MOVE_ITEMS
  payload: UpdateBatchPayload
  callback?: (succ: boolean) => void
}

export interface UpdateItemTotalsAction {
  type: typeof itemsTypes.UPDATE_ITEM_TOTALS
  payload: any
  callback?: (succ: boolean, item: Item | null) => void
}

export interface UpdateItemTotalsSyncAction {
  type: typeof itemsTypes.UPDATE_ITEM_TOTALS_SYNC
  payload: any
  callback?: (succ: boolean, item: Item | null) => void
}
export interface UpdateItemByPathAction {
  type: typeof itemsTypes.UPDATE_ITEM_BY_PATH
  payload: any
  callback?: (succ: boolean) => void
}

export interface CombineItemsAction {
  type: typeof itemsTypes.COMBINE_ITEMS
  payload: {
    id: string,
    request: string[],
    isEstimate?: boolean,
  }
  callback?: (succ: boolean) => void
}

export type ItemsActions =
  | FetchItemsAction
  | FetchInspectionItemsAction
  | SetItemsAction
  | SetItemsByCategoryAction
  | UpdateItemAction
  | DeleteItemAction
  | UpdateBatchAction
  | DeleteBatchAction
  | CreateItemAction
  | RejectItemAction
  | RecallItemsAction
  | MoveItemsBatchAction
  | UpdateItemTotalsAction
  | UpdateItemTotalsSyncAction
  | DeleteEstimateItemsAction
  | UpdateItemByPathAction
  | CombineItemsAction

/** REDUCER **/
export interface ItemsParams {
  jobId: string,
  status: string,
  orderId?: string,
}

export interface ItemsType {
  items: Item[]
  inspectionItems: Item[]
  itemsByCategory: ItemByCategory[]
}
