import { Theme } from '../../../UI'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) => ({
  header: {
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '18px !important',
    fontWeight: 500,
    lineHeight: '28px !important',
  },
  subtitle: {
    fontSize: '12px !important',
    fontWeight: 400,
    lineHeight: '18px !important',
  },
  submitBtn: {
    backgroundColor: 'var(--green700) !important',
    color: 'white !important',
    borderRadius: '32px !important',
    boxShadow:
      '0px 0px 1px 0px rgba(26, 42, 85, 0.31), 0px 3px 5px 0px rgba(26, 42, 85, 0.20)',
    width: '127px',
    height: '40px !important',
    fontSize: '14px !important',
    lineHeight: '22px !important',
  },
  addBtn: {
    color: 'var(--blue700) !important',
    borderRadius: '32px !important',
    border: '2px solid var(--blue700) !important',
    boxShadow:
      '0px 0px 1px 0px rgba(26, 42, 85, 0.31), 0px 3px 5px 0px rgba(26, 42, 85, 0.20)',
    height: '40px !important',
    fontSize: '14px !important',
    lineHeight: '22px !important',
  },
  alertContainer: {
    padding: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderRadius: '8px',
    border: '2px solid var(--blue300)',
    backgroundColor: 'var(--grey100)',
    gap: '8px',
    color: 'var(--blue700)',
  },
  alertText: {
    fontSize: '16px !important',
    lineHeight: '24px !important',
    fontWeight: 400,
    color: 'var(--navy-700)'
  },
  tabContainer: {
    overflow: 'overlay',
    height: 'calc(100vh - 250px)',
  },
  contentTitle: {
    fontSize: '16px !important',
    lineHeight: '150% !important',
    fontWeight: 900
  },
  warn: {
    padding: '16px',
    display: 'flex',
    gap: '8px',
    backgroundColor: 'var(--red100)',
    border: "2px solid var(--red200, #FCC)",
    borderRadius: '8px',
    color: 'var(--red700)'
  }
}))
