/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  LabeledText,
  BoxContainer,
  TitleFirst,
  Grid,
  ProgressBarWithLabel,
  ActionsButton,
  Typography,
  Chip,
  Theme,
  TextField,
  IconButton,
  TextFieldLabel,
  Button as ModalButton
} from 'src/components/UI'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import env from '@beam-australia/react-env'
import { getEmployeePrimaryEmail, getEmployeeRoles, getJob } from 'src/ducks/selectors'
import { useState, FC, useEffect } from 'react'
import { InformationTabsProps } from '../../types'
import { companiesActions, jobActions } from 'src/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { Divider } from '../../../../JobsTopInfo/Templates'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import EmailIcon from '@mui/icons-material/Email'
import SaveIcon from '@mui/icons-material/Save'
import { ADJUSTMENT_USERS, EMPLOYEE_ROLE_TYPE, STATUS, isEmpty } from 'src/helpers'
import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material'
import { JobItem, JobProperties } from 'src/ducks/types'
import SelectAddress from 'src/components/UI/CustomUI/organisms/SelectAddress'
import { Address } from 'src/components/UI/CustomUI/organisms/SelectAddress/types'
import { Address as JobAdress } from 'src/ducks/types'
import EditIcon from '@mui/icons-material/Edit';
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { ModalActionType } from 'src/components/UI/CustomUI/molecules/Modal/types'
import { icons } from '../../../../../../../../assets'

const Details: FC<InformationTabsProps> = ({ isChanged }) => {

  const dispatch = useDispatch()
  const job = useSelector(getJob())
  const employeeEmail = useSelector(getEmployeePrimaryEmail)

  const [jobInfo, setJobInfo] = useState({
    key: '',
    notes: job?.properties.clientNotes,
    lat: job?.properties.address.latitude ? job?.properties.address.latitude : 32.78,
    lng: job?.properties.address.longitude ? job?.properties.address.longitude : -79.94,
    fullAddress: job?.properties.address.fullAddress,
    payAtClose: !!job?.properties.payAtClose,
    inCollection: !!job?.inCollection,
    writtenOff: !!job?.writtenOff,
    estimateFormType: job?.properties?.estimateFormType || '',
    //address: job?.properties?.address
  })
  const [updateData, setUpdateData] = useState(false)
  const [loading, setLoading] = useState(false)


  const GOOGLE_MAPS_API_KEY = env('GOOGLE_MAPS_API_KEY') ?? ''
  const disableInputs = job?.status === STATUS.COMPLETE || job?.status === STATUS.CLOSED
  const [address, setAddress] = useState<any>('')
  const [open, setOpen] = useState(false)
  const [adjustment, setAdjustment] = useState(job?.properties?.discount || '0')
  const [adjustmentSaving, setAdjustmentSaving] = useState(false)

  const roles = useSelector(getEmployeeRoles)
  const roleTypes = roles.map((role) => role.roleType)

  const isValid: boolean =
    !isEmpty(address?.line_1) &&
    address?.line_1 !== ' ' &&
    !!address?.zipCode &&
    address?.latitude &&
    address?.longitude

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY
  })

  const containerStyle = {
    width: '100%',
    height: '100%',
    minHeight: '175px',
    padding: '12px 0',
    borderRadius: '8px',
    '.gm-style-cc': { display: 'none' }
  }

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target
    setJobInfo({
      ...jobInfo,
      [name]: value
    })
  }

  useEffect(() => {
    dispatch(companiesActions.fetchCompanies({}, (_succ: boolean) => {
    }))
  }, [dispatch])

  useEffect(() => {

    if (jobInfo.notes !== job?.properties.clientNotes
      || jobInfo.payAtClose !== !!job?.properties.payAtClose
      || jobInfo.inCollection !== !!job?.inCollection
      || jobInfo.writtenOff !== !!job?.writtenOff) {
      setUpdateData(true)
    } else {
      setUpdateData(false)
    }
  }, [jobInfo])


  const onUpdateJob = (newValue: Partial<JobItem>, callback?: (() => void) | undefined): void => {
    setLoading(true)
    dispatch(jobActions.updateJob(newValue, (succ) => {
      if (callback && succ) callback()
      setLoading(false)
      setUpdateData(false)
    }))
  }

  const onUpdateJobProps = (newValue: Partial<JobProperties>): void => {
    setLoading(true)
    dispatch(jobActions.updateJobProperty(newValue, () => {
      setLoading(false)
      setUpdateData(false)
    }))
  }

  const onSubmit = (): void => {
    let requestItem: Partial<JobItem> = {}
    let requestProperties: Partial<JobProperties> = {}
    if (jobInfo.notes && job?.properties && jobInfo.notes !== job?.properties.clientNotes) {
      requestProperties = { ...requestProperties, clientNotes: jobInfo.notes }
    }
    if (jobInfo.payAtClose !== job?.properties.payAtClose) {
      requestProperties = { ...requestProperties, payAtClose: jobInfo.payAtClose }
    }
    if (jobInfo.inCollection !== !!job?.inCollection) {
      requestItem = { ...requestItem, inCollection: jobInfo.inCollection }
    }
    if (jobInfo.writtenOff !== !!job?.writtenOff) {
      requestItem = { ...requestItem, writtenOff: jobInfo.writtenOff }
    }

    /*
    if (jobInfo.address !== job?.properties.address) {
      requestProperties = { ...requestProperties, address: jobInfo.address }
    }
    */



    if (Object.keys(requestProperties).length !== 0 && Object.keys(requestItem).length !== 0) {
      onUpdateJob(requestItem, () => onUpdateJobProps(requestProperties))
    } else if (Object.keys(requestProperties).length !== 0) {
      onUpdateJobProps(requestProperties)
    } else if (Object.keys(requestItem).length !== 0) {
      onUpdateJob(requestItem)
    }
  }

  const handleChange = (newAddress: Address) => {

    setAddress(newAddress)

    /*
    var x: JobAdress = {
      city: newAddress.city,
      state: newAddress.state,
      'line_1': newAddress.line_1,
      'line_2': newAddress.line_2,
      zipCode: newAddress.zipCode,
      latitude: newAddress.latitude,
      longitude: newAddress.longitude,
      fullAddress: ''
    }
    setJobInfo({ ...jobInfo, address: x })
    */
  }

  const handleClose = () => {
    setOpen(false)
    setAddress('')
  }

  const handleSaveEditAddress = () => {

    if (isValid) {

      var editAdress: JobAdress = {
        city: address.city,
        state: address.state,
        'line_1': address.line_1,
        'line_2': address.line_2,
        zipCode: address.zipCode,
        latitude: address.latitude,
        longitude: address.longitude,
        fullAddress: ''
      }

      let requestProperties: Partial<JobProperties> = {}
      requestProperties = { ...requestProperties, address: editAdress }
      onUpdateJobProps(requestProperties)

      var unitNumber = address.line_2 !== '' ? " Unit " + address.line_2 : ' '
      setJobInfo({
        ...jobInfo,
        lat: address.latitude,
        lng: address.longitude,
        fullAddress: address.line_1 + unitNumber + ", " + address.city + ", " + address.state + " " + address.zipCode
      })

      setUpdateData(true)
      setOpen(false)
      setAddress('')

    }
  }

  const handleUpdateJobProperty = (newProperty: Partial<JobProperties>, applyLoading: boolean = true): void => {
    dispatch(
      jobActions.updateJobProperty(newProperty, (succ) => {
        setAdjustmentSaving(false)
      })
    )
  }

  const modalAction: ModalActionType[] = [
    {
      textButton: 'Cancel',
      variant: 'outlined',
      onClick: () => { handleClose() }
    },
    {
      textButton: 'Update Address',
      variant: 'contained',
      onClick: () => {
        handleSaveEditAddress()
      },
      icon: 'Save',
      iconColor: 'white',
      disabled: !isValid
    }
  ]


  const QA_EMAILS_FOR_DEV =
    env('NODE_ENV') !== 'production'
      ? [
        'rodrigo.antognazza@punchlistusa.com',
        'rodrigo.antognazza@bosscathome.com',
      ]
      : []
  const ADJUSTMENTS_USERS = [...ADJUSTMENT_USERS, ...QA_EMAILS_FOR_DEV]


  return (
    <>
      <Box display='flex' flexDirection='row' gap={2} justifyContent='space-between'>
        <BoxContainer
          borderColor='#FFFFFF'
          sx={{ width: '100%', height: '310px', justifyContent: 'flex-start' }}
          title={
            <>
              <TitleFirst alignItems='center' black={jobInfo.fullAddress} blackVariant='h5' lineHeight='24px' sx={{ 'margin-left': '-5px' }} rightIcon={<IconButton size='small'
                onClick={() => {
                  setOpen(true)
                }}
              >
                <EditIcon />
              </IconButton>} />

            </>
          }
        >
          <Grid container style={{ height: '100%' }} sx={{ paddingLeft: '0.25rem' }}>
            <Grid item xs={12} style={{ height: '100%' }}>
              {isLoaded && (
                <GoogleMap
                  center={jobInfo}
                  zoom={15}
                  mapContainerStyle={containerStyle}
                  options={{
                    clickableIcons: false,
                    fullscreenControl: false,
                    zoomControl: false
                  }}
                >
                  <Marker position={jobInfo} />
                </GoogleMap>
              )}
            </Grid>
          </Grid>
        </BoxContainer>
        <BoxContainer
          borderColor='#FFFFFF'
          sx={{ width: '100%', height: '340px', justifyContent: 'flex-start' }}
          title={<TitleFirst
            alignItems='center'
            black='Details'
            blackVariant='h5'
            lineHeight='24px'
            sx={{ 'margin-left': '-5px' }}
          />}
        >
          <Grid container sx={{ paddingLeft: '0.25rem' }} spacing={1}>
            <Grid item container direction='column' justifyContent='space-between' spacing={2} sx={{ paddingLeft: '0.25rem' }}>
              <Grid item xs={6}>
                <Grid container justifyContent='flex-start' spacing={2}>
                  <Grid item xs={12}>
                    <Grid item container spacing={1} alignItems='flex-end'>
                      <Grid item xs={8}>
                        <TextFieldLabel
                          label='Adjustment %'
                          textFieldStyle={{ height: '37px' }}
                          value={`${adjustment}`}
                          onChange={(e) => setAdjustment(e.target.value)}
                          disabled={!(roleTypes?.includes(EMPLOYEE_ROLE_TYPE.ADMIN) || roleTypes?.includes(EMPLOYEE_ROLE_TYPE.SUPER_ADMIN))}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <ModalButton
                          variant='contained'
                          style={{ backgroundColor: '#1CCD83', border: '0px' }}
                          endIcon={adjustmentSaving ? <CircularProgress color='inherit' size={18} /> : <SaveIcon style={{ height: '16px', width: '16px' }} />}
                          onClick={() => {
                            setAdjustmentSaving(true)
                            handleUpdateJobProperty({ discount: Number(adjustment) }, false)
                          }}
                          fullWidth
                        >
                          Save
                        </ModalButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container style={{ height: '20px' }}>
                      <Grid item xs={3}>
                        <LabeledText
                          labelValue='Job Progress:'
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <ProgressBarWithLabel value={job?.percentItemsComplete ? job?.percentItemsComplete : 0} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} container>
              <Grid item xs={4} container alignContent='center' alignItems='center'>
                <Grid item>
                  <LabeledText
                    display='row'
                    labelValue='Property to be Sold:'
                  />
                </Grid>
                <Grid item>
                  {job?.properties.propertyToBeSold ?
                    <CheckCircleIcon
                      color="success"
                      sx={{
                        marginLeft: '10px',
                        marginTop: '5px',
                        height: '20px'
                      }} /> :
                    <CancelIcon
                      color="error"
                      sx={{
                        marginLeft: '10px',
                        marginTop: '5px',
                        height: '20px'
                      }} />
                  }
                </Grid>
              </Grid>
              <Grid item xs={3} container alignContent='center' alignItems='center'>
                <Grid item>
                  <LabeledText
                    display='row'
                    labelValue='Archived:'
                  />
                </Grid>
                <Grid item>
                  {job?.properties.archived ?
                    <CheckCircleIcon
                      color="success"
                      sx={{
                        marginLeft: '10px',
                        marginTop: '5px',
                        height: '20px'
                      }} /> :
                    <CancelIcon
                      color="error"
                      sx={{
                        marginLeft: '10px',
                        marginTop: '5px',
                        height: '20px'
                      }} />
                  }
                </Grid>
              </Grid>
              <Grid item xs={5} container alignContent='center' alignItems='center'>
                <Grid item>
                  <LabeledText
                    labelValue='Excluded From Reports:'
                  />
                </Grid>
                <Grid item>
                  {job?.properties.excludedFromReports ?
                    <CheckCircleIcon
                      color="success"
                      sx={{
                        marginLeft: '10px',
                        marginTop: '5px',
                        height: '20px'
                      }} /> :
                    <CancelIcon
                      color="error"
                      sx={{
                        marginLeft: '10px',
                        marginTop: '5px',
                        height: '20px'
                      }} />
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item container>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={jobInfo.payAtClose}
                      onChange={() => setJobInfo({ ...jobInfo, payAtClose: !jobInfo.payAtClose })}
                      name='payAtClose'
                      color='infoText'
                    />
                  }
                  label={<Typography variant='body2'>Pay at Close</Typography>}
                />
              </Grid>

              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={jobInfo.inCollection}
                      onChange={() => setJobInfo({ ...jobInfo, inCollection: !jobInfo.inCollection })}
                      name='inCollection'
                      color='infoText'
                    />
                  }
                  label={<Typography variant='body2'>In Collection</Typography>}
                />
              </Grid>

              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={jobInfo.writtenOff}
                      onChange={() => setJobInfo({ ...jobInfo, writtenOff: !jobInfo.writtenOff })}
                      name='writtenOff'
                      color='infoText'
                    />
                  }
                  label={<Typography variant='body2'>Written Off</Typography>}
                />
              </Grid>

            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item container direction='column'>
              <Grid item container sx={{ paddingLeft: '0.25rem' }}>
                <Typography variant='h5Bold'>Source And Notes</Typography>
                <Grid item xs={12}>
                  <LabeledText
                    labelValue='CC mails:'
                  />
                  {job?.properties?.contacts.length === 0 && <Typography>No CC mails</Typography>}
                  {job?.properties.contacts.map((contact) => {
                    return (
                      <Chip
                        variant="outlined"
                        icon={<EmailIcon style={{ height: "16px" }} />}
                        label={contact.email}
                        style={{ margin: '3px' }}
                      />
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </BoxContainer>
        <BoxContainer
          borderColor='#FFFFFF'
          sx={{ width: '100%', height: '340px', justifyContent: 'flex-start' }}
          title={<TitleFirst alignItems='center' black='' blackVariant='h6' lineHeight='24px' />}
        >
          <Grid container spacing={1} sx={{ paddingLeft: '0.25rem' }}>
            <Grid item xs={12} container>
              <Grid item xs={6}>
                <LabeledText
                  labelValue='Created:'
                  textValue={job?.createdBy}
                />
              </Grid>
              <Grid item xs={6}>
                <LabeledText
                  labelValue='Source:'
                  textValue={jobInfo?.estimateFormType}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <LabeledText
                labelValue='Status:'
                textValue={job?.status}
                textColor={(theme: Theme) => theme.palette.blue[700]}
              />
            </Grid>
            <Grid item xs={12}>
              <LabeledText
                labelValue='Notes:'
              />
              <TextField disabled={disableInputs} name="notes" multiline rows={8} fullWidth value={jobInfo?.notes} onChange={handleChangeInput} />

              {updateData && (
                <ActionsButton
                  variant='contained'
                  sx={{
                    position: 'fixed',
                    height: '40px !important',
                    width: '100PX',
                    bottom: '40px',
                    //right: 'calc(33% - 100px)'
                  }}
                  fullHeight={false}
                  loading={loading}
                  text='Save changes'
                  onClick={onSubmit}
                />
              )}
            </Grid>
          </Grid>
        </BoxContainer>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: '12px',
          right: '12px'
        }}
      >
        {open &&
          <Modal
            setOpen={setOpen}
            open={open}
            title={'Edit Job Address Information'}
            actions={modalAction}
            onClose={() => { handleClose() }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectAddress
                  placeholder='Property address'
                  showWarnings={false}
                  savedAddress={address}
                  onChange={handleChange} />
              </Grid>

              <Grid item xs={12}>
                <Grid item container direction='column' sx={{ backgroundColor: "#E5F7FF" }}>
                  <Grid item container >
                    <icons.InfoRounded style={{ marginTop: 3, width: '12px', height: '12px', color: "#1A2A55" }} />
                    <Typography variant='subtitle2' sx={{ color: "#1A2A55", fontFamily: "nouvelle" }}>Changing the Address is an action that can’t be undone.</Typography>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Modal>}
      </Box>

    </>
  )
}

export default Details
