/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  memo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SelectInput, TextFieldLabel } from 'src/components/UI'
import DropDown from 'src/components/UI/CustomUI/atoms/DropDown'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import ImagesSelector from 'src/components/UI/CustomUI/molecules/ImagesSelector'
import {
  getOrderById,
  getTerritoryRepairItemsForSelect,
  getJob,
} from 'src/ducks/selectors'
import { WorkOrdersContext } from '../../../../../context'
import { debounce } from 'lodash'

import styles from './styles.module.scss'
import { CURRENCY_MASK, isEmpty, round, ITEM_CATEGORY, STATUS, CURRENCY_MASK_NEGATIVE, ORDER_STATUS, unMaskedInput } from 'src/helpers'
import { createNumberMask } from 'text-mask-addons'
import { NewItem, workOdersTypes } from '../../../../../context/types'
import SwiperCore, { Mousewheel } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { ImageProps } from './types'
import CurrencyTextField from 'src/components/UI/CustomUI/molecules/CurrencyTextField'
import { itemsActions } from 'src/ducks/actions'
import { Item } from 'src/ducks/types'

SwiperCore.use([Mousewheel])

export const DISCLAIMER_OPTIONS = [
  {
    key: 'Price to be adjusted per quantity required.',
    value: 'Price to be adjusted per quantity required',
  },
  {
    key: 'Price subject to change per onsite conditions.',
    value: 'Price subject to change per onsite conditions',
  },
  {
    key: 'Price is for area pictured and is subject to change per additional areas required.',
    value:
      'Price is for area pictured and is subject to change per additional areas required',
  },
  {
    key: 'Price subject to change if replacement is required.',
    value: 'Price subject to change if replacement is required',
  },
  {
    key: 'Price to be adjusted per linear footage required.',
    value: 'Price to be adjusted per linear footage required',
  },
  {
    key: 'Price subject to change if additional repairs are required.',
    value: 'Price subject to change if additional repairs are required',
  },
  {
    key: 'No photo(s) provided: price subject to change per onsite conditions.',
    value:
      'No photo(s) provided: price subject to change per onsite conditions',
  },
  {
    key: 'Price subject to change per style desired/availability of item.',
    value: 'Price subject to change per style desired/availability of item',
  },
]

export const EVALUATE_REASONS = [
  { key: 'Need more information', value: 'Need more information' },
  { key: 'Require diagnostic', value: 'Require diagnostic' },
  { key: 'Out of Scope/Expert', value: 'Out of Scope/Expert' },
  {
    key: 'May require Additional Inspection',
    value: 'May require Additional Inspection',
  },
]

const propsAreEqual = (prevProps: ImageProps, nextProps: ImageProps) => {
  return (
    JSON.stringify(prevProps.images) === JSON.stringify(nextProps.images) &&
    JSON.stringify(prevProps.imageFiles) ===
    JSON.stringify(nextProps.imageFiles) &&
    prevProps.isSlider === nextProps.isSlider &&
    prevProps.hasImageLoader === nextProps.hasImageLoader &&
    prevProps.submitAction === nextProps.submitAction
  )
}

const MemoImagesSelector = memo((props: ImageProps) => {
  const { submitAction, images, imageFiles, isSlider, hasImageLoader, allowDelete, deleteAction } = props

  return (
    <ImagesSelector
      multiple
      submitAction={submitAction}
      images={images}
      imageFiles={imageFiles}
      height="auto"
      columns={4}
      isSlider={isSlider}
      hasImageLoader={hasImageLoader}
      acceptedFiles={['image/*', 'application/pdf']}
      allowDelete={allowDelete}
      deleteAction={deleteAction}
    />
  )
}, propsAreEqual)

const EstimateInfo = () => {
  const territoryRepairItems = useSelector(getTerritoryRepairItemsForSelect)
  const dispatch = useDispatch()
  const job = useSelector(getJob())
  const {
    state: { item, editItem, selectedOrderId },
    dispatch: dispatchContext,
  } = useContext(WorkOrdersContext)
  const [newItem, setNewItem] = useState(item)
  // Temp item used for price fields
  const [tempItem, setTempItem] = useState(item)
  const [showOverride, setShowOverride] = useState(false)
  const isEditModal = !isEmpty(item.itemId)
  const currencyMaskNegative = createNumberMask(CURRENCY_MASK_NEGATIVE)
  const currencyMask = createNumberMask(CURRENCY_MASK)
  const [canEditPrice, setCanEditPrice] = useState(false)
  const order = useSelector(getOrderById(selectedOrderId as string))
  const [itemTotalLoading, setItemTotalLoading] = useState(false)

  const {
    completedTimestamp,
    vendorPaymentTimestamp,
    statusType,
    csmAcceptanceTimestamp,
  } = order

  const showPaid =
    statusType &&
    (ORDER_STATUS.PAID === statusType || vendorPaymentTimestamp != null)
  const showAccepted =
    statusType &&
    (ORDER_STATUS.COMPLETE === statusType ||
      showPaid ||
      csmAcceptanceTimestamp != null)
  const showComplete =
    statusType &&
    (ORDER_STATUS.REVIEW === statusType ||
      showAccepted ||
      completedTimestamp != null)

  const vendorIsPaid = order.vendorPaymentTimestamp !== null ? true : (showAccepted && showComplete) ? true : false//order.vendorPaymentTimestamp !== null //? true : completeAndAccepted 
  const isJobClosed = job?.status === STATUS.CLOSED

  const disclaimerArray =
    (item.disclaimer && typeof item.disclaimer === 'string'
      ? item.disclaimer
        .split('.')
        .filter((element) => element)
        .map((item) => item.trim())
      : item.disclaimer) ?? []

  const changeItemValue = useCallback((newValue, attr) => {
    dispatchContext({
      type: workOdersTypes.SET_ITEM_VALUE,
      payload: { attr: attr, value: newValue },
    })
    // if (isEditModal) {
    dispatchContext({
      type: workOdersTypes.SET_EDIT_ITEM_VALUE,
      payload: { attr: attr, value: newValue },
    })
    // }
  }, [])

  const dispatchDisclaimer = (newSelected: any) => {
    dispatchContext({
      type: 'SET_ITEM_VALUE',
      payload: { attr: 'disclaimer', value: newSelected },
    })
    dispatchContext({
      type: 'SET_EDIT_ITEM_VALUE',
      payload: { attr: 'disclaimer', value: newSelected },
    })
  }

  const _handleBlur = (event: any): void => {
    const disclaimer = event?.target?.value
    if (disclaimer?.length > 0) {
      const newValue: any = [...(disclaimerArray as string[]), disclaimer]
      dispatchDisclaimer(newValue)
    }
  }

  const submitAction = useCallback(
    (e: any) => {
      if (isEditModal) {
        dispatchContext({
          type: 'SET_EDIT_ITEM_VALUE',
          payload: { attr: 'images', value: e },
        })
      } else {
        dispatchContext({
          type: 'SET_ITEM_VALUE',
          payload: { attr: 'images', value: e },
        })
      }
    },
    [isEditModal]
  )

  const onItemValueChanged = useMemo(
    () => debounce(changeItemValue, 300),
    [changeItemValue]
  )


  const setValues = (newSelected: any) => {
    const selectedRepairItem = territoryRepairItems.find(
      (op) => op.label === newSelected
    )
    if (!isEditModal) {
      dispatchContext({ type: 'RESET_ITEM_VALUE', payload: true })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'totalPrice', value: 0 },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'totalLaborCost', value: 0 },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'totalMaterialCost', value: 0 },
      })
      selectedRepairItem && debounceFetchItemsTotal(newItem)

    } else {
      setNewItem({
        ...newItem,
        category: ITEM_CATEGORY.UNCATEGORIZED,
        severity: 'Medium',
        title: newSelected,
      })
      dispatchContext({
        type: workOdersTypes.SET_EDIT_ITEM_VALUE,
        payload: { attr: 'category', value: ITEM_CATEGORY.UNCATEGORIZED },
      })
      dispatchContext({
        type: workOdersTypes.SET_EDIT_ITEM_VALUE,
        payload: { attr: 'severity', value: 'Medium' },
      })
      dispatchContext({
        type: workOdersTypes.SET_EDIT_ITEM_VALUE,
        payload: { attr: 'title', value: newSelected },
      })
    }
    dispatchContext({
      type: 'SET_ITEM_VALUE',
      payload: { attr: 'totalLaborHours', value: 0 },
    })
    dispatchContext({
      type: 'SET_ITEM_VALUE',
      payload: { attr: 'laborRate', value: 0 },
    })
    dispatchContext({
      type: 'SET_ITEM_VALUE',
      payload: { attr: 'quantity', value: 1 },
    })
    dispatchContext({
      type: 'SET_ITEM_VALUE',
      payload: { attr: 'category', value: ITEM_CATEGORY.UNCATEGORIZED },
    })
    dispatchContext({
      type: 'SET_ITEM_VALUE',
      payload: { attr: 'severity', value: 'Medium' },
    })
    dispatchContext({
      type: 'SET_ITEM_VALUE',
      payload: { attr: 'title', value: newSelected },
    })
    dispatchContext({
      type: 'SET_ITEM_VALUE',
      payload: { attr: 'displayTitle', value: newSelected },
    })
  }

  const handleInputChange = (newSelected: any) => {
    const isNotSelected =
      territoryRepairItems.findIndex((op) => op.label === newSelected) === -1
    const isUncategorized =
      newSelected.indexOf(ITEM_CATEGORY.UNCATEGORIZED) === -1
    const isChanged = newSelected !== item.displayTitle

    const selectedRepairItem = territoryRepairItems.find(
      (op) => op.label === newSelected
    )

    if (selectedRepairItem && !isEditModal) {
      debounceFetchItemsTotal(
        { ...(selectedRepairItem.key as any), quantity: item.quantity || 1 }
      )
    }

    // only cases were the item its new
    if (
      isChanged &&
      isNotSelected &&
      isUncategorized &&
      newSelected.length > 0 &&
      !isJobClosed
    ) {
      setValues(newSelected)
    }
  }

  const handleSelectNewItem = (newSelected: any) => {
    const { key: newItem } = newSelected
    if (newItem !== undefined) {
      if (!isEditModal) {
        !isEmpty(newSelected.label) && debounceFetchItemsTotal(
          { ...newSelected.key, quantity: 1 },
        )
        setTempItem({
          ...newItem,
          quantity: 1,
        })
        dispatchContext({
          type: 'SET_ITEM_VALUE',
          payload: { attr: 'hideFromClient', value: newItem?.hideFromClient },
        })
        dispatchContext({
          type: 'SET_ITEM_VALUE',
          payload: { attr: 'id', value: newItem?.id },
        })
        dispatchContext({
          type: 'SET_ITEM_VALUE',
          payload: { attr: 'margin', value: newItem?.margin },
        })
        dispatchContext({
          type: 'SET_ITEM_VALUE',
          payload: { attr: 'unitPrice', value: newItem.unitPrice },
        })
        /*  dispatchContext({ type: 'RESET_ITEM_VALUE', payload: true })
         setTempItem({
           ...newItem,
           quantity: 1,
         })
         dispatchContext({
           type: 'SET_ITEM_VALUE',
           payload: { attr: 'hideFromClient', value: newItem?.hideFromClient },
         }) */
      } else {
        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: { attr: 'category', value: newItem.category },
        })
        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: { attr: 'severity', value: newItem.severity },
        })
        dispatchContext({
          type: workOdersTypes.SET_EDIT_ITEM_VALUE,
          payload: { attr: 'title', value: newItem.title },
        })
      }
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: {
          attr: 'totalLaborCost',
          value: newItem.unitLaborHours * newItem.laborRate,
        },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'totalMaterialCost', value: newItem.unitMaterialCost },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'totalLaborHours', value: newItem.unitLaborHours },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'laborRate', value: newItem.laborRate },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'quantity', value: 1 },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'category', value: newItem.category },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'severity', value: newItem.severity },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'title', value: newItem.title },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'disclaimer', value: newItem.disclaimer?.trim() },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'displayTitle', value: newSelected.label },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'unitMaterialCost', value: newItem.unitMaterialCost },
      })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'unitLaborHours', value: newItem.unitLaborHours || 1 },
      })
    }
  }

  useEffect(() => {
    setNewItem(item)
    /*  if (isEditModal) {
       setTempItem(item)
     } */
  }, [item])

  const getMarginValue = () => {
    const number = (((Number(newItem.totalPrice) -
      (Number(newItem.totalLaborCost) + Number(newItem.totalMaterialCost))) /
      Number(newItem.totalPrice)) *
      100
    )?.toFixed()
    if (number === "NaN" || number === "Infinity" || number === "-Infinity") {
      return 0
    }
    return number
  }

  const margin = `${getMarginValue()}%`

  const handleItemTotal = (propName: string) => {
    if (isEditModal) return
    // a flag for checking if the Material cost, hours/qty, quantity, margin has been changed, or if they select a different repair item entirely.
    let valueChanged =
      (tempItem as any)[propName]?.toString() !==
      (newItem as any)[propName]?.toString()

    if (valueChanged) {
      debounceFetchItemsTotal(newItem)
    }
  }

  const fetchItemsTotal = (
    requestItem: Partial<NewItem>,
  ) => {
    setItemTotalLoading(true)

    let { totalPrice, ...request } = requestItem

    if ('unitMaterialCost' in request) {
      request.unitMaterialCost = Number(request.unitMaterialCost || 0)
    }
    if ('unitLaborHours' in request)
      request.unitLaborHours = Number(request.unitLaborHours)
    if ('margin' in request) request.margin = Number(request.margin)
    if ('totalLaborCost' in request)
      request.totalLaborCost = Number(request.totalLaborCost)
    // if ('totalPrice' in request) request.totalPrice = Number(request.totalPrice)
    if ('minPriceRange' in request && request.minPriceRange)
      request.minPriceRange = Number(request.minPriceRange)
    if ('evaluateReason' in request && request.evaluateReason)
      request.evaluateReason = String(request.evaluateReason)
    if ('maxPriceRange' in request && request.maxPriceRange)
      request.maxPriceRange = Number(request.maxPriceRange)
    if ('quantity' in request) request.quantity = Number(request.quantity || 1)
    if ('unitMaterialCost' in request) request.unitMaterialCost = Number(request.unitMaterialCost)
    if ('margin' in request) request.margin = Number(request.margin)


    if (item.itemId) request.itemId = item.itemId
    dispatch(
      itemsActions.updateItemTotals(
        request,
        (succ: boolean, it: Item | null) => {
          setItemTotalLoading(false)

          const resultItem = it || newItem
          setNewItem(resultItem as any)
          setTempItem(resultItem as any)

          if (!isEditModal) {
            dispatchContext({
              type: 'SET_ITEM_VALUE',
              payload: { attr: 'totalPrice', value: resultItem.totalPrice },
            })
            dispatchContext({
              type: 'SET_ITEM_VALUE',
              payload: {
                attr: 'totalLaborCost',
                value: resultItem.totalLaborCost,
              },
            })
            dispatchContext({
              type: 'SET_ITEM_VALUE',
              payload: {
                attr: 'totalMaterialCost',
                value: resultItem.totalMaterialCost,
              },
            })
            dispatchContext({
              type: 'SET_ITEM_VALUE',
              payload: { attr: 'totalCost', value: resultItem.totalCost },
            })
            dispatchContext({
              type: 'SET_ITEM_VALUE',
              payload: { attr: 'profit', value: resultItem.profit },
            })
          } else {
            dispatchContext({
              type: workOdersTypes.SET_EDIT_ITEM_VALUE,
              payload: { attr: 'totalPrice', value: resultItem.totalPrice },
            })
            dispatchContext({
              type: workOdersTypes.SET_EDIT_ITEM_VALUE,
              payload: {
                attr: 'totalLaborCost',
                value: resultItem.totalLaborCost,
              },
            })
            dispatchContext({
              type: workOdersTypes.SET_EDIT_ITEM_VALUE,
              payload: {
                attr: 'totalMaterialCost',
                value: resultItem.totalMaterialCost,
              },
            })
            dispatchContext({
              type: workOdersTypes.SET_EDIT_ITEM_VALUE,
              payload: { attr: 'totalCost', value: resultItem.totalCost },
            })
            dispatchContext({
              type: workOdersTypes.SET_EDIT_ITEM_VALUE,
              payload: { attr: 'profit', value: resultItem.profit },
            })
            dispatchContext({
              type: workOdersTypes.SET_EDIT_ITEM_VALUE,
              payload: { attr: 'margin', value: resultItem.margin },
            })
          }
          dispatchContext({
            type: workOdersTypes.SET_TOTALS_LOADING,
            payload: false,
          })
        }
      )
    )
  }

  const debounceFetchItemsTotal = useCallback(
    debounce(fetchItemsTotal, 500),
    []
  )

  useEffect(() => {
    const isNotSelected =
      territoryRepairItems.findIndex((op) => op.label === newItem.title) === -1

    !isNotSelected &&
      !isEditModal &&
      newItem.id &&
      debounceFetchItemsTotal({ ...newItem })
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} alignItems="flex-end" spacing={2}>
        <Grid item flex={1}>
          <SelectInput
            label="Display title: "
            value={item.displayTitle || ''}
            onInputChange={handleInputChange}
            onChange={handleSelectNewItem}
            options={territoryRepairItems.map((option) => option)}
            renderOption={(props: any, item: any) => { return (<li {...props} key={item.id}>{`${item.key.publicTitle} - ${item.key.category} - ${item.key.unitLaborHours} hours`}</li>) }}
            size="small"
            labelVariant="h6"
            disabled={vendorIsPaid}
            freeSolo
          />
        </Grid>
        {/*  <Grid item>
          <Button
            variant="outlined"
            sx={{ padding: 0, width: 41, height: 41, marginRight: 1 }}
          >
            <Icon name="NorthEast" width="1rem" height="1rem" />
          </Button>
          <Button variant="outlined" sx={{ padding: 0, width: 41, height: 41 }}>
            <Icon name="Link" width="1rem" height="1rem" />
          </Button>
        </Grid> */}
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={8}>
          <SelectInput
            label="Category: "
            disabled
            value={
              item.category === ITEM_CATEGORY.UNCATEGORIZED
                ? ITEM_CATEGORY.UNCATEGORIZED
                : item.category && item.laborRate
                  ? `${item.category} - $${item.laborRate}/hr.`
                  : ''
            }
            options={territoryRepairItems.map((option) => option)}
            size="small"
            labelVariant="h6"
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            label="Severity: "
            disabled
            value={item.severity || ''}
            options={territoryRepairItems.map((option) => option)}
            size="small"
            labelVariant="h6"
          />
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item xs={12} sx={{ marginBottom: 1 }}>
          <Typography sx={{ marginBottom: 1 }} variant="h6">
            {' '}
            Images:{' '}
          </Typography>
        </Grid>
        <MemoImagesSelector
          submitAction={submitAction}
          images={[...(item.images || []), ...(editItem.images || [])]}
          imageFiles={item.imageFiles || []}
          isSlider={isEditModal}
          hasImageLoader={true}
        // allowDelete={!isJobClosed}
        // deleteAction={(index: number) => {
        //   console.log(index)
        // }
        // }
        />
      </Grid>

      <Grid item xs={12}>
        <TextFieldLabel
          autoComplete="off"
          label={
            <Typography display="inline" variant="h6">
              Measurements:
              <Typography
                variant="h6"
                display="inline"
                marginLeft="2px"
                color="var(--gray500)"
              />
            </Typography>
          }
          placeholder="Room size: 15’ x 19’5” x 8’ ceiling height"
          value={newItem.measurement || ''}
          size="small"
          onChange={(e) => {
            if (e.target.value.length > 998) return;
            setNewItem({ ...newItem, measurement: e.target.value })
            onItemValueChanged(e.target.value, 'measurement')
          }}
          multiline
          rows={2}
          labelVariant="h6"
          disabled={vendorIsPaid}
        />
      </Grid>

      {newItem.category === ITEM_CATEGORY.EVALUATE && (
        <Grid container item spacing={2} justifyContent="space-between">
          <Grid item xs={7}>
            <SelectInput
              label="Evaluate Reason: "
              value={newItem.evaluateReason}
              options={EVALUATE_REASONS.map(({ value }) => value)}
              onChange={(newValue) => {
                setNewItem({ ...newItem, evaluateReason: (newValue as unknown as string) })
                onItemValueChanged(newValue, 'evaluateReason')
              }}
              size="small"
              labelVariant="h6"
            />
          </Grid>
          <Grid item xs={2.5}>
            <CurrencyTextField
              autoComplete="off"
              label="Range: Min"
              value={newItem?.minPriceRange}
              size="small"
              onChange={(val) => {
                setNewItem({ ...newItem, minPriceRange: val || null })
                onItemValueChanged(
                  val || null, 'minPriceRange'
                )
              }}
              labelVariant="h6"
              mask={currencyMask}
            />
          </Grid>
          <Grid item xs={2.5}>
            <CurrencyTextField
              autoComplete="off"
              label="Range: Max"
              value={newItem?.maxPriceRange}
              size="small"
              onChange={(val) => {
                setNewItem({ ...newItem, maxPriceRange: val || null })
                onItemValueChanged(
                  val || null, 'maxPriceRange'
                )
              }}
              labelVariant="h6"
              mask={currencyMask}
            />
          </Grid>
        </Grid>
      )}

      <Grid container item spacing={2} alignItems="flex-end">
        <Grid item xs={2.4}>
          <TextFieldLabel
            autoComplete="off"
            label="Ref: "
            value={newItem.inspectionReportReference || ''}
            size="small"
            onChange={(e) => {
              setNewItem({
                ...newItem,
                inspectionReportReference: e.target.value,
              })
              onItemValueChanged(e.target.value, 'inspectionReportReference')
            }}
            labelVariant="h6"
            disabled={vendorIsPaid}
          />
        </Grid>
        <Grid item xs={2.4}>
          <TextFieldLabel
            autoComplete="off"
            label="Quantity: "
            value={newItem.quantity?.toString()}
            size="small"
            onChange={(e) => {
              setNewItem({
                ...newItem,
                quantity: Number(e.target.value),
              })
              onItemValueChanged(Number(e.target.value), 'quantity')
              dispatchContext({
                type: workOdersTypes.SET_TOTALS_LOADING,
                payload: true,
              })
              !isEditModal && debounceFetchItemsTotal(
                {
                  ...newItem,
                  quantity: Number(e.target.value),
                }
              )
            }}
            labelVariant="h6"
            type="number"
            disabled={vendorIsPaid}
          />
        </Grid>
        <Grid item xs={2.4}>
          <Typography variant="h6">Customer Price: </Typography>
          {itemTotalLoading ? (
            <CircularProgress
              style={{ marginTop: '.5rem' }}
              size={'1.5rem'}
            />
          ) : (
            <CurrencyTextField
              autoComplete="off"
              label=""
              value={newItem?.totalPrice}
              /* value={
                '$' + round(Number(newItem?.totalPrice), 2).toString()
              } */
              onChange={(e) => {
                setNewItem({ ...newItem, totalPrice: e })
                onItemValueChanged(unMaskedInput(e), 'totalPrice')
              }}
              size="small"
              labelVariant="h6"
              mask={currencyMaskNegative}
              disabled={(isEditModal && !canEditPrice) || vendorIsPaid}
            />
          )}
          {/* <CurrencyTextField
            autoComplete="off"
            label="Customer Price: "
            value={newItem?.totalPrice}
            onChange={(val) => {
              setNewItem({ ...newItem, totalPrice: val })
              onItemValueChanged(val, 'totalPrice')
            }}
            size="small"
            labelVariant="h6"
            disabled={(isEditModal && !canEditPrice) || vendorIsPaid}
            // disabled={(isEditModal && !canEditPrice) || vendorIsPaid}
            mask={currencyMaskNegative}
          /> */}
        </Grid>
        {showOverride && isEditModal ? (
          <Grid item xs={2.4}>
            <Typography variant="h6">Edit Customer Price</Typography>
            <Switch
              checked={canEditPrice}
              onChange={() => {
                setCanEditPrice(!canEditPrice)
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Grid>
        ) : (
          ((!isEditModal && !showOverride) || !showOverride) && (
            <Grid item xs={2.4}>
              <Button
                disabled={vendorIsPaid}
                fullWidth
                onClick={() => setShowOverride(true)}
                variant="outlined"
                startIcon={<Icon name="Edit" />}
              >
                Override
              </Button>
            </Grid>
          )
        )}

        {showOverride && (
          <Grid item xs={2.4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={newItem.requiredLicense}
                  onChange={() => {
                    setNewItem({ ...newItem, requiredLicense: !newItem.requiredLicense })
                    onItemValueChanged(
                      !newItem.requiredLicense, 'requiredLicense'
                    )
                  }}
                  name='remember'
                  color='infoText'
                />
              }
              className={styles.EstimateInfo__checkbox}
              label={<Typography variant='h6'>License:</Typography>}
            />
          </Grid>
        )}

      </Grid>
      {showOverride && (
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={2.4}>
            {isEditModal ?
              <CurrencyTextField
                autoComplete="off"
                label="Labor Cost: "
                value={newItem.totalLaborCost?.toString()}
                size="small"
                onChange={(val) => {
                  setNewItem({
                    ...newItem,
                    totalLaborCost: Number(val),
                  })
                  onItemValueChanged(Number(val), 'totalLaborCost')
                }}
                labelVariant="h6"
                mask={currencyMask}
              />
              :
              <TextFieldLabel
                autoComplete="off"
                label="Hours/Qty: "
                value={newItem?.unitLaborHours?.toString()}
                size="small"
                onChange={(e) => {
                  setNewItem({
                    ...newItem,
                    unitLaborHours: Number(e.target.value),
                  })
                  onItemValueChanged(
                    Number(e.target.value), 'unitLaborHours'
                  )
                }}
                onBlur={() => handleItemTotal('unitLaborHours')}
                labelVariant="h6"
                // mask={currencyMask}
                type="number"
              />
            }

          </Grid>
          <Grid item xs={2.4}>
            <CurrencyTextField
              autoComplete="off"
              label="Material Cost: "
              value={isEditModal ? newItem.totalMaterialCost : newItem.unitMaterialCost}
              size="small"
              onChange={(val) => {
                const newItemToEdit = isEditModal ? 'totalMaterialCost' : 'unitMaterialCost'
                setNewItem({ ...newItem, [newItemToEdit]: val })
                onItemValueChanged(
                  val, [newItemToEdit]
                )
              }}
              onBlur={() => handleItemTotal('unitMaterialCost')}
              labelVariant="h6"
              mask={currencyMask}
            />
          </Grid>
          <Grid item xs={2.4}>
            <CurrencyTextField
              autoComplete="off"
              label="Vendor Price: "
              value={
                round(
                  (Number(newItem?.totalLaborCost) || 0) +
                  (Number(newItem?.totalMaterialCost) || 0),
                  2
                )
              }
              size="small"
              labelVariant="h6"
              mask={currencyMask}
              disabled
            />
          </Grid>
          <Grid item xs={2.4}>
            <TextFieldLabel
              autoComplete="off"
              label="Margin: "
              value={isEditModal ? margin : newItem?.margin?.toString() || '0'}
              size="small"
              onKeyDown={e => {
                if ((e.code === "Backspace" && newItem?.margin?.toString()?.length === 1) || newItem?.margin?.toString() === "NaN") {
                  setNewItem({
                    ...newItem,
                    margin: '0',
                  })
                }
              }}
              onChange={(e) => {
                const margin = Math.max(0, Math.min(99, Number(e.target.value)));
                setNewItem({ ...newItem, margin: margin })
                onItemValueChanged(margin, 'margin')
              }}
              onBlur={() => handleItemTotal('margin')}
              labelVariant="h6"
              disabled={isEditModal}
            />
          </Grid>
          {showOverride && (
            <Grid item xs={2.4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newItem.requiredPermit}
                    onChange={() => {
                      setNewItem({ ...newItem, requiredPermit: !newItem.requiredPermit })
                      onItemValueChanged(
                        !newItem.requiredPermit, 'requiredPermit'
                      )
                    }}
                    name='remember'
                    color='infoText'
                  />
                }
                className={styles.EstimateInfo__checkbox}
                label={<Typography variant='h6'>Permit:</Typography>}
              />
            </Grid>
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <SelectInput
          label="Display Disclaimer"
          placeholder="Disclaimer Text on Estimate"
          value={disclaimerArray || []}
          options={DISCLAIMER_OPTIONS.map((option) => option.value)}
          size="small"
          onChange={dispatchDisclaimer}
          onBlur={_handleBlur}
          clearOnBlur
          multiple
          freeSolo
          labelVariant="h6"
          maxCharacters={40}
          disabled={vendorIsPaid}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldLabel
          autoComplete="off"
          label={
            <Typography display="inline" variant="h6">
              Public Notes/Inspection Comments{' '}
              <Typography
                variant="h6"
                display="inline"
                marginLeft="2px"
                color="var(--gray500)"
              >
                (Visible to Customer):{' '}
              </Typography>
            </Typography>
          }
          placeholder="Repair Notes"
          value={newItem.inspectionReportNote || ''}
          size="small"
          onChange={(e) => {
            setNewItem({ ...newItem, inspectionReportNote: e.target.value })
            onItemValueChanged(e.target.value, 'inspectionReportNote')
          }}
          multiline
          rows={2}
          labelVariant="h6"
          disabled={vendorIsPaid}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldLabel
          autoComplete="off"
          label={
            <Typography display="inline" variant="h6">
              Repair Addendum Notes{' '}
              <Typography
                variant="h6"
                display="inline"
                marginLeft="2px"
                color="var(--gray500)"
              >
                (Visible to Customer):{' '}
              </Typography>
            </Typography>
          }
          placeholder="Repair Addendum Notes"
          value={newItem.requestedRepairNote || ''}
          size="small"
          onChange={(e) => {
            setNewItem({ ...newItem, requestedRepairNote: e.target.value })
            onItemValueChanged(e.target.value, 'requestedRepairNote')
          }}
          multiline
          rows={2}
          labelVariant="h6"
          disabled={vendorIsPaid}
        />
      </Grid>
      <Grid item xs={12}>
        <DropDown
          title={
            <Typography variant="h6" color="var(--blue700)">
              {' '}
              Additional Notes
            </Typography>
          }
          iconColor="var(--blue700)"
          className={styles.EstimateInfo__dropdown}
        >
          <TextFieldLabel
            autoComplete="off"
            label={
              <Typography display="inline" variant="h6">
                Public Repair Notes{' '}
                <Typography
                  variant="h6"
                  display="inline"
                  marginLeft="2px"
                  color="var(--gray500)"
                >
                  (Visible to Customer):{' '}
                </Typography>
              </Typography>
            }
            value={newItem.publicRepairNote || ''}
            size="small"
            onChange={(e) => {
              setNewItem({ ...newItem, publicRepairNote: e.target.value })
              onItemValueChanged(e.target.value, 'publicRepairNote')
            }}
            labelVariant="h6"
          />
        </DropDown>
      </Grid>
    </Grid>
  )
}
export default EstimateInfo
