import { FC } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import {
  Calendar, Customers, CustomerView, Home, Admin, CompanyView, Companies,
  Jobs, JobsView, Login, Payments, Dispatch, SiteVisits,
  Pros, ProsView, Reports, Search, Tasks, Register, Unauthorized, Account,
} from 'src/components/pages'
import Reset from 'src/components/pages/Auth/Reset'
import ErrorPage from 'src/components/pages/Error'
import { DispatchProvider } from 'src/components/pages/Private/Dispatch/context'
import PreviewInvoice from 'src/components/pages/Private/PreviewInvoice'
import { PreviewInvoiceProvider } from 'src/components/pages/Private/PreviewInvoice/context'
import Profile from 'src/components/pages/Private/Profile'
import { VendorProvider } from 'src/components/pages/Private/Pros/context'
import AuthRoutes from './AuthRoutes'
import PrivateRoutes from './PrivateRoutes'
import DepositInvoice from 'src/components/pages/Private/DepositInvoice'
import { DepositInvoiceProvider } from 'src/components/pages/Private/DepositInvoice/context'
import ErrorBoundary from 'src/components/templates/ErrorBoundary'
import { CompaniesProvider } from 'src/components/pages/Private/Companies/context'
import Estimates from 'src/components/pages/Private/Estimates'
import EstimatesView from 'src/components/pages/Private/EstimatesView'
import Subscriptions from 'src/components/pages/Private/Subscriptions'
import SubscriptionView from 'src/components/pages/Private/SubscriptionView'
import { SubscriptionProvider } from 'src/components/pages/Private/SubscriptionView/context'
import { EMPLOYEE_ROLE_TYPE } from 'src/helpers'
import PortalEstimate from 'src/components/pages/Private/EstimatesView/PortalEstimate'
import Territories from 'src/components/pages/Private/Territories'
import TerritoryView from 'src/components/pages/Private/TerritoryView'
import { WidgetsProvider } from 'src/components/pages/Private/Widgets/context'
import Widgets from 'src/components/pages/Private/Widgets'
import RepairList from 'src/components/pages/Private/RepairList'
import RepairItemView from 'src/components/pages/Private/RepairItemView'
import NewPriceItem from 'src/components/pages/Private/RepairItemView/NewItem'
import Homemart from 'src/components/pages/Private/Homemart'
import Banners from 'src/components/pages/Private/Banners'

const Router: FC = () => (
  <BrowserRouter>
    <ErrorBoundary>
      <Routes>
        {/** Auth Routes */}
        <Route path='/' element={<AuthRoutes />}>
          <Route path='' element={<Login />} />
          <Route path='login' element={<Login />} />
          <Route path='register' element={<Register />} />
          <Route path='reset' element={<Reset />} />
          <Route path='unauthorized' element={<Unauthorized />} />
        </Route>
        {/** Private Routes */}
        <Route path='/' element={
          <PrivateRoutes
            allowedRoles={[EMPLOYEE_ROLE_TYPE.SUPER_ADMIN, EMPLOYEE_ROLE_TYPE.ADMIN]} />}
        >
          <Route path='/admin' element={<Admin />} />
          <Route path='/payments' element={<Payments />} />
          <Route path='/companies' element={<CompaniesProvider><Companies /></CompaniesProvider>} />
          <Route path='/companies/:id' element={<CompaniesProvider><CompanyView /></CompaniesProvider>} />
          <Route path='/widgets' element={<WidgetsProvider><Widgets /></WidgetsProvider>} />
          <Route path='/banners' element={<Banners />} />
        </Route>
        <Route path='/' element={
          <PrivateRoutes
            allowedRoles={[EMPLOYEE_ROLE_TYPE.SUPER_ADMIN, EMPLOYEE_ROLE_TYPE.ADMIN, EMPLOYEE_ROLE_TYPE.MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_CUSTOMER_CARE, EMPLOYEE_ROLE_TYPE.SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_BUSINESS_DEV_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_ESTIMATOR_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERINTENDENT, EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERVISOR]} />}
        >
          <Route path='/customers' element={<Customers />} />
          <Route path='/customers/:id' element={<CustomerView />} />
        </Route>
        <Route path='/' element={
          <PrivateRoutes
            allowedRoles={[EMPLOYEE_ROLE_TYPE.SUPER_ADMIN, EMPLOYEE_ROLE_TYPE.ADMIN, EMPLOYEE_ROLE_TYPE.MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_CUSTOMER_CARE, EMPLOYEE_ROLE_TYPE.SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_VENDOR_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_ESTIMATOR_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERINTENDENT, EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERVISOR]} />}
        >
          <Route path='/pros' element={<Pros />} />
          <Route path='/pros/:id' element={<VendorProvider><ProsView /></VendorProvider>} />
        </Route>
        <Route path='/' element={
          <PrivateRoutes
            allowedRoles={[EMPLOYEE_ROLE_TYPE.SUPER_ADMIN, EMPLOYEE_ROLE_TYPE.ADMIN, EMPLOYEE_ROLE_TYPE.MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_CUSTOMER_CARE, EMPLOYEE_ROLE_TYPE.SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.ESTIMATOR, EMPLOYEE_ROLE_TYPE.TERRITORY_ESTIMATOR_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_ESTIMATOR, EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERINTENDENT, EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERVISOR, EMPLOYEE_ROLE_TYPE.TERRITORY_VENDOR_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_BUSINESS_DEV_MANAGER]} />}
        >
          <Route path='/calendar' element={<Calendar />} />
        </Route>
        <Route path='/' element={
          <PrivateRoutes
            allowedRoles={[EMPLOYEE_ROLE_TYPE.SUPER_ADMIN, EMPLOYEE_ROLE_TYPE.ADMIN, EMPLOYEE_ROLE_TYPE.MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_CUSTOMER_CARE, EMPLOYEE_ROLE_TYPE.SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.ESTIMATOR, EMPLOYEE_ROLE_TYPE.TERRITORY_ESTIMATOR_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_ESTIMATOR, EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERINTENDENT, EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERVISOR, EMPLOYEE_ROLE_TYPE.TERRITORY_VENDOR_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_BUSINESS_DEV_MANAGER]} />}
        >
          <Route path='/jobs' element={<Jobs />} />
          <Route path='/jobs/:id' element={<JobsView />} />
        </Route>
        <Route path='/' element={
          <PrivateRoutes
            allowedRoles={[EMPLOYEE_ROLE_TYPE.TERRITORY_CUSTOMER_CARE, EMPLOYEE_ROLE_TYPE.SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.ADMIN, EMPLOYEE_ROLE_TYPE.SUPER_ADMIN]} />}
        >
          <Route
            path='/jobs/:jobId/dispatch/:orderId'
            element={<DispatchProvider><Dispatch /></DispatchProvider>}
          />
        </Route>
        <Route path='/' element={<PrivateRoutes />}>
          <Route path='/profile' element={<Profile />} />
          <Route path='/home' element={<Home />} />

          <Route path='/estimates' element={<Estimates />} />
          <Route path='/estimates/:id' element={<EstimatesView />} />
          <Route path='/estimates/:id/view-estimate' element={<PortalEstimate />} />
          <Route path='/estimates/:id/release-estimate' element={<PortalEstimate isRelease={true} />} />

          <Route path='/reports' element={<Reports />} />
          <Route path='/search' element={<Search />} />
          <Route path='/tasks' element={<Tasks />} />
          <Route path='/territories' element={<Territories />} />
          <Route path='/territories/:id' element={<TerritoryView />} />
          <Route
            path='/invoice/jobId/:jobId/preview'
            element={<PreviewInvoiceProvider><PreviewInvoice /></PreviewInvoiceProvider>}
          />
          <Route
            path='/invoice/jobId/:jobId/deposit'
            element={<DepositInvoiceProvider><DepositInvoice /></DepositInvoiceProvider>}
          />
          <Route path='/account' element={<Account />} />
          <Route path='/sitevisits' element={<SiteVisits />} />
          <Route path='/subscriptions' element={<Subscriptions />} />
          <Route path='/subscriptions/:id' element={<SubscriptionProvider><SubscriptionView /></SubscriptionProvider>} />
          <Route path='/repairlist' element={<RepairList />} />
          <Route path='/repairlist/new-item' element={<NewPriceItem />} />
          <Route path='/repairlist/:id' element={<RepairItemView />} />
          <Route path='/homemart' element={<Homemart />} />
        </Route>
        {/** 404 */}
        <Route
          path='*'
          element={<ErrorPage />}
        />
        <Route
          path='/not-found'
          element={<ErrorPage />}
        />
      </Routes>
    </ErrorBoundary>

  </BrowserRouter>
)

export default Router
