/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* global google */
import { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import env from '@beam-australia/react-env'

import {
  Typography,
  Button,
  //STextField,
  AutocompleteSearch,
  Box, Grid, TextField,
} from '../../../../UI'
import { icons, mapPointer, googleMaps } from '../../../../../assets'

import { SelectAddressProps } from './types'
import { useClassName } from '../../../../../hooks'
import { flattenedAddress, isEmpty } from 'src/helpers'
import { territoriesActions } from '../../../../../ducks/actions'
//import { Address } from '../../../../../ducks/types'
import { Address } from './types'
import useStyles from './styles'

const GOOGLE_MAPS_API_KEY = env('GOOGLE_MAPS_API_KEY') ?? ''
const defaultCenter = { lat: 40, lng: -100 }
const START_SEARCHING_FROM = 3

const containerStyle = {
  width: '100%',
  height: '100%',
  minHeight: '150px',
  borderRadius: '8px',
}

const emptyAddress = {
  city: '',
  state: '',
  line_1: '',
  line_2: '',
  zipCode: '',
  latitude: defaultCenter.lat,
  longitude: defaultCenter.lng,
  country: '',
  county: ''
}

const SelectAddress: FC<SelectAddressProps> = ({
  xl = 6,
  showSave = false,
  onChange,
  onSubmit,
  onAdd,
  showWarnings = false,
  showVertical = false,
  showUnit = true,
  edit = false,
  initAdd = '',
  initUnit = '',
  initCoord = defaultCenter,
  customClass = '',
  savedAddress,
  title,
  compact = false,
  placeholder,
  hasCallback = false,
  errorMessage,
  inputClassName = "",
  enterManually = false,
}) => {
  const classes = useStyles()
  const className = useClassName()
  const dispatch = useDispatch()
  const Input = null as any
  const [address, setAddress] = useState<Address>(
    savedAddress?.line_1
      ? savedAddress
      : {
        city: '',
        state: '',
        line_1: '',
        line_2: initUnit,
        zipCode: '',
        latitude: defaultCenter.lat,
        longitude: defaultCenter.lng,
      }
  )
  const mapCountry = (country: string): string => {
    return country === 'United States' ? 'USA' : 'Canada';
  }
  const [addressText, setAddressText] = useState<string>(
    savedAddress?.line_1
      ? `${savedAddress?.line_1}, ${savedAddress?.city}, ${savedAddress?.state}${savedAddress?.zipCode ? " " + savedAddress?.zipCode : ""}, ${mapCountry(savedAddress?.country)}`
      : initAdd
  )
  const [options, setOptions] = useState<any[]>([])
  const [optionCount, setOptionCount] = useState<number>(0)
  const [coordinates, setCoordinates] = useState(
    savedAddress
      ? { lat: savedAddress.latitude, lng: savedAddress.longitude }
      : initCoord
  )
  const [openMap, setOpenMap] = useState(true)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  })

  const placesService = new window.google.maps.places.PlacesService(
    document.createElement('div')
  )
  const autoCompleteService = new window.google.maps.places.AutocompleteService()
  const geocoder = new window.google.maps.Geocoder()

  const hasCoordinates =
    coordinates.lat !== defaultCenter.lat &&
    coordinates.lng !== defaultCenter.lng


  useEffect(() => {
    if (!savedAddress) {
      setAddress({
        city: '',
        state: '',
        line_1: '',
        line_2: initUnit,
        zipCode: '',
        latitude: initCoord.lat,
        longitude: initCoord.lng,
        country: '',
        county: ''
      })

      setCoordinates(initCoord)
    }
  }, [initAdd])

  useEffect(() => {
    if (onChange) {
      onChange(address)
    }
    //!hasCallback && address.zipCode &&
    //dispatch(territoriesActions.fetchTerritory(address.zipCode))
  }, [address])

  useEffect(() => {
    const request = {
      input: addressText,
      componentRestrictions: { country: ['us', 'ca'] },
    }

    addressText && autoCompleteService.getPlacePredictions(request).then((results) => {
      const { predictions } = results
      let tempOptions: any[] = []

      setOptionCount(predictions.length)
      predictions.forEach((prediction) => {
        placesService.getDetails(
          {
            placeId: prediction.place_id,
          },
          (place: any) => {
            if (place !== null && flattenedAddress(place).zipCode) {
              tempOptions = [
                ...tempOptions,
                {
                  id: prediction.place_id,
                  label: prediction.description,
                  value: prediction.description,
                },
              ]
              setOptions(tempOptions)
            }
          }
        )
      })
    })
  }, [addressText])

  const handleSelectedAddress = (e: any) => {
    placesService.getDetails(
      {
        placeId: e?.id,
      },
      (place: any) => {
        const {
          city,
          state,
          zipCode,
          line_1,
          street_number,
          latitude,
          longitude,
          country,
          county
        } = flattenedAddress(place)

        const streetNumberFromLabel = e.label.split(" ")[0]
        const isNumber = !isNaN(streetNumberFromLabel)
        setAddressText(e.label)
        setAddress({
          city,
          state: state,
          zipCode,
          line_1: `${street_number || isNumber ? streetNumberFromLabel : '' || ''} ${line_1 || ''}`,
          line_2: address.line_2,
          latitude,
          longitude,
          country,
          county
        })
        setCoordinates({ lat: latitude, lng: longitude })
      }
    )
  }

  const handleMapClick = async (cords: { lat: number; lng: number }) => {
    geocoder.geocode(
      {
        location: {
          lat: cords.lat,
          lng: cords.lng,
        },
      },
      (results: any, status: any) => {
        const {
          city,
          state,
          zipCode,
          line_1,
          street_number,
          latitude,
          longitude,
          formatted_address,
          country,
          county
        } = flattenedAddress(results[0])
        setAddressText(formatted_address)
        setAddress({
          city,
          state: state,
          zipCode,
          line_1: `${street_number || ''} ${line_1 || ''}`,
          line_2: address.line_2,
          latitude,
          longitude,
          country,
          county
        })
        setCoordinates(cords)
      }
    )
  }

  const resetData = () => {
    setAddress(emptyAddress)
    setOptions([])
    setAddressText('')
  }

  const handleSubmit = (): void => {
    if (onSubmit) onSubmit(address)
    resetData()
  }

  const handleInput = (inputAddress: string): void => {
    resetData()
    setAddressText(inputAddress)
  }

  const handleOnBlur = () => {
    if (isEmpty(address.state)) {
      resetData()
    }
  }

  const onMapLoad = (map: google.maps.Map) => {
    if (!savedAddress || savedAddress.line_1 === '') {
      setCoordinates(defaultCenter)
      if (navigator?.geolocation) {
        navigator?.geolocation.getCurrentPosition(
          ({ coords: { latitude: lat, longitude: lng } }) => {
            setCoordinates({ lat, lng })
          }
        )
      }
    } else {
      setCoordinates({
        lat: savedAddress.latitude,
        lng: savedAddress.longitude,
      })
    }
  }

  return (
    <Grid container spacing={1}>

      <Grid item xs={showUnit ? 8 : 12}>
        {title || (
          <Typography variant="subtitle2" className={classes.label}>
            Property Address:
          </Typography>
        )}
        {enterManually ? (
          <TextField
            value={addressText}
            onChange={(event) => handleInput(event.target.value)}
            placeholder={placeholder}
            className={inputClassName}
          />
        ) : (
          <AutocompleteSearch
            label=""
            error={showWarnings}
            options={
              addressText.length > START_SEARCHING_FROM ? options : []
            }
            optionCount={optionCount}
            value={edit ? { value: initAdd } : { value: addressText }}
            valueInput={{ value: addressText }}
            renderOption={(props, option: any) => {

              return (
                <Box {...props} zIndex={99999} className={classes.optionContainer} sx={{ cursor: 'pointer' }}  >
                  <img
                    src={mapPointer}
                    className={classes.pointerIcon}
                    alt="map pointer"
                  />
                  <Typography className={classes.mainDirection}>
                    {option.value}
                  </Typography>
                </Box>
              )
            }}
            onChange={handleSelectedAddress}
            valueChange={handleInput}
            popupIcon={
              <icons.Search
                className={className(classes.addressSearcherIcon)}
                aria-label=""
              />
            }
            noOptionText
            onBlur={handleOnBlur}
            placeholder={placeholder}
            inputClassName={inputClassName}
          />
        )}
      </Grid>
      {showUnit && <Grid item xs={4}>
        <Typography variant="subtitle2" className={classes.label}>
          Unit Number:
        </Typography>
        <TextField
          value={address.line_2}
          className={classes.comboUnit}
          onChange={(event) =>
            setAddress({ ...address, line_2: event.target.value })
          }
          placeholder="Unit Number"
        />
      </Grid>}
      {showWarnings && errorMessage && errorMessage}
      <Grid item xs={12}>
        {isLoaded && openMap && (
          <GoogleMap
            center={coordinates}
            zoom={hasCoordinates ? 18 : 3}
            mapContainerStyle={containerStyle}
            mapContainerClassName={customClass}
            options={{ clickableIcons: false }}
            // onLoad={!savedAddress.line_1 ? onMapLoad : undefined}
            onLoad={onMapLoad}
            onClick={(e) => {
              if (e.latLng) {
                const cords = {
                  lat: e.latLng.lat(),
                  lng: e.latLng.lng(),
                }
                handleMapClick(cords)
              }
            }}
          >
            {hasCoordinates && <Marker position={coordinates} />}
          </GoogleMap>
        )}
      </Grid>

    </Grid>
  )
}

export default SelectAddress
