import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import { EMPLOYEE_ROLE_TYPE, getOptionsFromArray, getOptionsFromArrayMultiple } from 'src/helpers'
import { EmployeeType } from '../employee/types'
import { State } from '../reducers'

export const getEmployees = (state: State): EmployeeType[] => {
  return state.employees?.items || []
}

export const getTotalEmployees = (state: State) => {
  return state.employees?.total || 0
}

export const getEmployeesForDropdown = (state: State): any[] => {
  const flattenedData = state.employees?.items?.reduce((acc: any, curr) => {
    const email = curr.email?.find(email => email.emailType === 'PRIMARY')
    return [...acc, (acc = {
      id: curr.id,
      display: `${curr.firstName} ${curr.lastName}`,
      email: email?.email || ''
    })]
  }, [])
  return flattenedData
}

export const getEmployeesForSelect = (allLabel?: string) => (state: State): SelectInputOption[] => {
  const employees = state.employees?.items || []
  const flattenedData = employees.reduce((acc: any, curr: EmployeeType) => {
    return [
      ...acc,
      (acc = {
        id: curr.id,
        label: `${curr.firstName} ${curr.lastName}`,
        key: curr.id,
      })
    ]
  }, [{ id: 'all', label: allLabel || 'All Employees', key: 'all' }])
  return flattenedData
}

export const getEmployeesByRole = (role: EMPLOYEE_ROLE_TYPE) => (state: State): SelectInputOption[] => {
  const employees = state.employees?.items || []
  const filteredEmployees = employees?.filter(e =>
    e.roles.some(r => {
      return r.roleType === role
    })
  )
  const flattenedData = getOptionsFromArray(filteredEmployees, { key: 'id', label: ['firstName', 'lastName'] })
  return flattenedData
}

export const getEmployeesPossibleManagers = () => (state: State): SelectInputOption[] => {
  const employees = state.employees?.items || []
  const filteredEmployees = employees?.filter(e =>  !e.territories || e.territories?.length === 0).filter(e =>
    e.roles.some(r => {
      return r.roleType === EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER
    })
  )
  const flattenedData = getOptionsFromArray(filteredEmployees, { key: 'id', label: ['firstName', 'lastName'] })
  return flattenedData
}
