import { companyActions } from 'src/ducks/actions'
/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { errorTextTryingTo } from '../../helpers'
import { Punchlist } from '../../api'
import { companiesActions } from '../actions'
import {
  companiesTypes,
  CreateCompanyAction,
  UpdateCompanyAction,
  DeleteCompanyAction,
  FetchCompaniesAction,
  RequestPayload,
} from './types'
import { CompanyItem } from '../types'
import { isEmpty } from 'lodash'
import { UpdateRequest } from '~api/types'

export function* fetchCompanies({
  payload,
  callback,
}: FetchCompaniesAction): SagaIterator {
  let success = false
  try {
    const page = payload?.page ?? 0
    const size = payload?.size ?? 1000
    const institutional = payload?.institutional
    const searchParams = payload?.searchParams
    const start = page * size
    const limit = size

    const companies = yield call(Punchlist.companies.getCompanies, {
      start,
      limit,
      searchParams,
      institutional,
    })

    if (companies) {
      yield put(companiesActions.setCompanies(companies))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the companies'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchAllCompanies({
  payload,
  callback,
}: FetchCompaniesAction): SagaIterator {
  let success = false
  try {
    const institutional = payload?.institutional
    const searchParams = payload?.searchParams

    const companies = yield call(Punchlist.companies.getCompanies, {
      searchParams,
      institutional,
      start: 0,
      limit: 1000,
    })

    if (companies) {
      yield put(companiesActions.setAllCompanies(companies))
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the companies'))
    if (callback) yield call(callback, false)
  }
}

export function* createCompany({
  payload,
  callback,
}: CreateCompanyAction): SagaIterator {
  try {
    const request: RequestPayload = payload

    const company = yield call(
      Punchlist.companies.createCompany,
      request.company
    )

    if (!isEmpty(company)) {
      const page = payload?.page ?? 0
      const size = payload?.size ?? 1000
      const institutional = payload?.institutional

      const start = page * size
      const limit = size

      const companies = yield call(Punchlist.companies.getCompanies, {
        start,
        limit,
        institutional,
      })

      if (companies) {
        yield put(companyActions.setCompany(company))
        yield put(companiesActions.setCompanies(companies))
        if (callback) yield call(callback, true, company)
      }
    }
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('create the company'))
    if (callback) yield call(callback, false)
  }
}

export function* patchCompany({
  payload,
  callback,
}: UpdateCompanyAction): SagaIterator {
  try {
    if (payload.updateRequest) {
      const request: UpdateRequest = payload.updateRequest
      const company = yield call(
        Punchlist.companies.updateCompany,
        payload.company.id as string,
        request
      )

      if (!isEmpty(company)) {
        const page = payload?.page ?? 0
        const size = payload?.size ?? 1000
        const institutional = payload?.institutional

        const start = page * size
        const limit = size

        const companies = yield call(Punchlist.companies.getCompanies, {
          start,
          limit,
          institutional,
        })
        if (companies) {
          console.log("companies", companies)
          yield put(companyActions.setCompany(company))
          yield put(companiesActions.setCompanies({ ...companies, allItems: companies.items, }))
          if (callback) yield call(callback, true)
        }
      }
    } else {
      if (callback) yield call(callback, false)
    }
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the company'))
    if (callback) yield call(callback, false)
  }
}

export function* updateCompany({
  payload,
  callback,
}: UpdateCompanyAction): SagaIterator {
  try {
    const request: UpdateRequest = []

    for (const attr in payload.company) {
      if (attr !== 'id') {
        request.push({
          op: 'add',
          path: '/' + attr,
          value: payload.company[attr as keyof CompanyItem],
        })
      }
    }

    const company = yield call(
      Punchlist.companies.updateCompany,
      payload.company.id as string,
      request
    )

    if (!isEmpty(company)) {
      const page = payload?.page ?? 0
      const size = payload?.size ?? 1000
      const institutional = payload?.institutional

      const start = page * size
      const limit = size

      const companies = yield call(Punchlist.companies.getCompanies, {
        start,
        limit,
        institutional,
      })
      if (companies) {
        yield put(companiesActions.setCompanies(companies))
        if (callback) yield call(callback, true)
      }
    }
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the company'))
    if (callback) yield call(callback, false)
  }
}

export function* deleteCompany({
  payload,
  callback,
}: DeleteCompanyAction): SagaIterator {
  try {
    yield call(Punchlist.companies.deleteCompany, payload.company.id as string)

    if (callback) yield call(callback, true)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('delete the company'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(companiesTypes.FETCH_COMPANIES, fetchCompanies)
  yield takeLatest(companiesTypes.FETCH_ALL_COMPANIES, fetchAllCompanies)
  yield takeLatest(companiesTypes.CREATE_COMPANY, createCompany)
  yield takeLatest(companiesTypes.UPDATE_COMPANY, updateCompany)
  yield takeLatest(companiesTypes.DELETE_COMPANY, deleteCompany)
  yield takeLatest(companiesTypes.PATCH_COMPANY, patchCompany)
}
