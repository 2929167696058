import { Insurance } from "../types";

export const insurancesTypes = {
    FETCH_INSURANCES: 'FETCH_INSURANCES',
    FETCH_CSV_INSURANCES: 'FETCH_CSV_INSURANCES',
    SET_INSURANCES: 'SET_LICENSES',
    SET_CSV_INSURANCES: 'SET_CSV_INSURANCES',
}

export interface FetchInsurancesAction {
    type: typeof insurancesTypes.FETCH_INSURANCES
    payload: {
        page?: number
        size?: number
        searchParams?: any
    }
    callback?: (succ: boolean) => void
}

export interface FetchCSVInsurancesAction {
    type: typeof insurancesTypes.FETCH_INSURANCES
    payload: {
        count?: number
        searchParams?: any
    }
    callback?: (succ: boolean) => void
}

export interface SetInsurancesAction {
    type: typeof insurancesTypes.SET_INSURANCES
    payload: InsurancesFetch
}

export interface TableInsurance {
    userId: string
    companyName: string
    vendorName: string
    phone: string
    insurance: Insurance
}

export interface InsurancesFetch {
    start: number
    limit: number
    total: number
    items: TableInsurance[]
    csvItems: TableInsurance[]
}

export type InsurancesActions =
    | FetchInsurancesAction
    | SetInsurancesAction
    | FetchCSVInsurancesAction