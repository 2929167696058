import { FC } from 'react'
import { TitleProps } from './types'

import { Typography, Box } from '../../..'
import { isEmpty } from 'src/helpers'
import { Button, IconButton, Tooltip } from 'src/components/UI/MaterialUI'
import Icon from '../Icon'
import { toast } from 'react-toastify'

const LabeledText: FC<TitleProps> = ({
  labelValue,
  textValue,
  labelVariant,
  textVariant,
  labelColor,
  flexDirection = 'row',
  textColor,
  sx,
  showCopyButton = false
}) => {

  const closedTime = 1000

  const handleCopy = () => {
    if (textValue) {
      navigator.clipboard.writeText(textValue)
      toast.success.call('success', 'Copied to clipboard', { autoClose: closedTime })
    }
  }

  return (
    <Box display='flex' gap={flexDirection === 'row' ? '8px' : 0} flexDirection={flexDirection} sx={[
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}>
      {!isEmpty(labelValue) && (
        <Typography
          fontFamily='NouvelleNormal'
          fontSize={labelVariant ? undefined : '12px'}
          color={labelColor ? labelColor : ''}
          fontWeight='bold'
          lineHeight='18px'
          variant={labelVariant}
        >
          {labelValue}
        </Typography>
      )}
      {textValue !== undefined && (
        <Typography
          fontFamily='NouvelleNormal'
          fontSize={textVariant ? undefined : '12px'}
          fontWeight='regular'
          lineHeight='18px'
          variant={textVariant}
          color={textColor ? textColor : ''}
        >
          {!isEmpty(textValue) ? textValue : '-'}
          {showCopyButton && flexDirection === 'column' &&
            <Tooltip title='Copy to clipboard'>
              <Button onClick={handleCopy} >
                <Icon name='ContentCopy' height='12px' />
              </Button>
            </Tooltip>
          }
        </Typography>
      )}
      {showCopyButton && flexDirection === 'row' &&
        <Tooltip title='Copy to clipboard'>
          <Button size='small' onClick={handleCopy}
            sx={{
              position: 'relative',
              top: '1px',
              left: '-5px',
              padding: '2px'
            }} >
            <Icon name='ContentCopy' height='12px' />
          </Button>
        </Tooltip>
      }
    </Box>
  )
}

export default LabeledText
