import { useState, useEffect, useContext } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import useStyles from './styles'
import itemsHeaders from './ItemsHeaders'
import Table from 'src/components/UI/CustomUI/organisms/Table'
import { useDispatch, useSelector } from 'react-redux'
import { getEstimate, getItems } from 'src/ducks/selectors'
import { itemsActions, matchActions } from 'src/ducks/actions'
import { PrivateLoader } from 'src/components/templates'
import { WorkOrdersContext } from '../../context'
import { workOdersTypes } from '../../context/types'
import { Item } from 'src/ducks/types'
import { Box } from 'src/components/UI'
import AntSwitch from 'src/components/UI/CustomUI/atoms/AntSwitch'
import { isEmpty } from 'lodash'

const EditItems = () => {
  const styles = useStyles()

  const dispatch = useDispatch()
  const {
    dispatch: dispatchContext,
    state: { editItem, selectedItemId, item, totalsLoading, itemToFetchTotals },
  } = useContext(WorkOrdersContext)

  const estimate = useSelector(getEstimate())
  const items = useSelector(getItems())

  const [loading, setLoading] = useState(false)
  const [selectedRow, setSelectedRow] = useState(-1)
  const [editItemHistory, setEditItemHistory] = useState<any>({})
  const [hideMatch, setHideMatch] = useState<boolean>(false)


  useEffect(() => {
    setLoading(true)

    dispatchContext({ type: workOdersTypes.RESET_ITEM_VALUE, payload: true })

    dispatch(
      itemsActions.fetchItems({ params: { jobId: estimate?.id } }, (succ) => {
        setLoading(false)
      })
    )
  }, [])


  useEffect(() => {
    const index = (
      items.filter(
        (it) =>
          it.automatchConfidenceScore === null ||
          it.automatchConfidenceScore < 90 ||
          !hideMatch
      ) || []
    ).findIndex((it) => it.itemId === item.itemId)

    setSelectedRow(index)
  }, [hideMatch, items, item, editItem])


  const handleRowClick = (row: Item) => {
    dispatch(
      itemsActions.updateItemTotals({}, (succ) => { })
    )
    const index = (
      items.filter(
        (item) =>
          item.automatchConfidenceScore === null ||
          item.automatchConfidenceScore < 90 ||
          !hideMatch
      ) || []
    ).findIndex((item) => item.itemId === row.itemId)

    setSelectedRow(index)
    setEditItemHistory({
      ...editItemHistory,
      [`${selectedItemId}`]: { ...item, ...editItem },
    })

    let memoizedItem
    if (`${row.itemId}` in editItemHistory)
      memoizedItem = editItemHistory[`${row.itemId}`]
    else memoizedItem = row

    // update the unsaved changes

    if (editItem && selectedItemId) {
      if (totalsLoading) {
        dispatchContext({
          type: workOdersTypes.RESET_ITEM_TO_FETCH_TOTALS,
        })
        dispatchContext({
          type: workOdersTypes.SET_TOTAL_PATCH_LOADING,
          payload: true
        })
        dispatch(itemsActions.updateItemTotalsSync(
          {
            itemId: selectedItemId || '',
            partialItem: editItem,
            isEstimate: true,
          },
          (succ) => {
            dispatchContext({
              type: workOdersTypes.SET_TOTAL_PATCH_LOADING,
              payload: false
            })
            dispatchContext({ type: 'RESET_EDIT_ITEM_VALUE' })
            dispatchContext({
              type: workOdersTypes.RESET_ITEM_TO_FETCH_TOTALS,
            })
            dispatch(
              itemsActions.fetchItems({ params: { jobId: estimate?.id || '' } })
            )
          }
        ))
      } else {
        !isEmpty(editItem) && dispatch(
          itemsActions.updateItem(
            {
              itemId: selectedItemId || '',
              partialItem: editItem,
              isEstimate: true,
            },
            (succ) => {
              dispatch(
                itemsActions.fetchItems({ params: { jobId: estimate?.id || '' } })
              )
            }
          )
        )
      }
    }

    dispatchContext({
      type: workOdersTypes.SET_ITEM,
      payload: { ...row, displayTitle: row.title },
    })
    dispatchContext({
      type: workOdersTypes.RESET_EDIT_ITEM_VALUE,
    })
    dispatchContext({
      type: workOdersTypes.SET_SELECTED_ITEM_ID,
      payload: row.itemId,
    })

    dispatch(
      matchActions.fetchMatch(
        { estimateId: estimate?.id || '', itemId: row.id || '' },
        (succ) => { }
      )
    )
  }

  const handleAddItem = () => {
    dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
    dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'EDIT_ITEM' })
    dispatchContext({ type: workOdersTypes.RESET_EDIT_ITEM_VALUE })
    dispatchContext({ type: workOdersTypes.RESET_ITEM_VALUE })

  }

  return (
    <PrivateLoader loading={loading}>
      <Grid container className={styles.container} flexDirection="column">
        <Grid item container className={styles.buttonGroup}>
          <Button
            variant="outlined"
            className={`${styles.addEstimate} ${styles.midFont}`}
            onClick={handleAddItem}
            disabled={estimate?.status === 'APPROVED'}
          >
            Add New Item
          </Button>
          <Box className={styles.hideMatch}>
            <Typography className={styles.sxFont}>
              Hide Matched (+90%)
            </Typography>
            <AntSwitch
              checked={hideMatch}
              onClick={() => setHideMatch(!hideMatch)}
            />
          </Box>
        </Grid>
        <Grid item container className={styles.itemsTable}>
          <Table
            headers={itemsHeaders}
            sx={{ width: '100%' }}
            fullWidth
            hasPagination={false}
            rowAction={handleRowClick}
            selectedRow={selectedRow}
          >
            {items.filter(
              (item) =>
                item.automatchConfidenceScore === null ||
                item.automatchConfidenceScore < 90 ||
                !hideMatch
            ) || []}
          </Table>
        </Grid>
      </Grid>
    </PrivateLoader>
  )
}

export default EditItems
