import { InsurancesFetch } from './insurances/types'
import { combineReducers } from 'redux'

import clientReducer from './client/reducer'
import clientsReducer from './clients/reducer'
import configReducer from './config/reducer'
import employeeReducer from './employee/reducer'
import employeesReducer from './employees/reducer'
import orderReducer from './order/reducer'
import ordersReducer from './orders/reducer'
import vendorReducer from './vendor/reducer'
import vendorsReducer from './vendors/reducer'
import searchesReducer from './searches/reducer'
import siteVisitReducer from './siteVisit/reducer'
import siteVisitsReducer from './siteVisits/reducer'
import territoriesReducer from './territories/reducer'
import territoryReducer from './territory/reducer'
import jobReducer from './job/reducer'
import jobsReducer from './jobs/reducer'
import licensesReducer from './licenses/reducer'
import navbarReducer from './navBar/reducer'
import workflowsReducer from './workflows/reducer'
import companiesReducer from './companies/reducer'
import companyReducer from './company/reducer'
import itemsReducer from './items/reducer'
import notificationsReducer from './notifications/reducer'
import commentsReducer from './comments/reducer'
import notesReducer from './notes/reducer'
import invoiceReducer from './invoice/reducer'
import paymentsReducer from './payments/reducer'
import tradeTypesReducer from './tradeTypes/reducer'
import communicationsReducer from './communications/reducer'
import materialInvoicesReducer from './materialInvoice/reducer'
import historyReducer from './history/reducer'
import priceModelsReducer from './priceModels/reducer'
import estimatesReducer from './estimates/reducer'
import estimateReducer from './estimate/reducer'
import estimatesByCategoryReducer from './estimatesByCategory/reducer'
import invitationsReducer from './invitations/reducer'
import filtersReducer from './filters/reducer'
import subscriptionsReducer from './subscriptions/reducer'
import subscriptionReducer from './subscription/reducer'
import estimateItemsReducer from './estimateItems/reducer'
import insurancesReducer from './insurances/reducer'
import newEstimateReducer from './newEstimate/reducer'
import widgetsReducer from './widgets/reducer'
import widgetReducer from './widget/reducer'
import matchesReducer from './matches/reducer'
import matchReducer from './match/reducer'
import repairListReducer from './repairList/reducer'
import repairItemReducer from './repairItem/reducer'
import materialReducer from './material/reducer'
import materialsReducer from './materials/reducer'
import groupedOrdersReducer from './groupedOrders/reducer'
import homemartReducer from './homemart/reducer'
import discountsReducer from './discounts/reducer'
import campaignsReducer from './campaigns/reducer'


import { ClientType } from './client/types'
import { ClientsType } from './clients/types'
import { ConfigType } from './config/types'
import { EmployeeType } from './employee/types'
import { EmployeesType } from './employees/types'
import { Order } from './order/types'
import { OrdersType } from './orders/types'
import { VendorType } from './vendor/types'
import { VendorsType } from './vendors/types'
import { Searches } from './searches/types'
import { SiteVisitType } from './siteVisit/types'
import { SiteVisitsType } from './siteVisits/types'
import { JobType } from './job/types'
import { JobsType } from './jobs/types'
import { LicensesType } from './licenses/types'
import { TerritoriesType } from './territories/types'
import { TerritoryType } from './territory/types'
import { CompaniesType } from './companies/types'
import { CompanyType } from './company/types'
import { ItemsType } from './items/types'
import { WorkflowsType } from './workflows/types'
import { NotificationType } from './notifications/types'
import { CommentsType } from './comments/types'
import { Notes } from './notes/types'
import { Invoice } from './invoice/types'
import { PaymentsType } from './payments/types'
import { Trades } from './tradeTypes/types'
import { CommunicationsType } from './communications/types'
import { MaterialInvoices } from './materialInvoice/types'
import { HistoryType } from './history/types'
import { PriceModelType } from './priceModels/types'
import { EstimatesType } from './estimates/types'
import { EstimateType } from './estimate/types'
import { EstimatesByCategoryType } from './estimatesByCategory/types'
import { Invitation } from './invitations/types'
import { FiltersType } from './filters/types'
import { SubscriptionsType } from './subscriptions/types'
import { SubscriptionType } from './subscription/types'
import { EstimateItemsType } from './estimateItems/types'
import { NewEstimateType } from './newEstimate/types'
import { WidgetsType } from './widgets/types'
import { Widget } from './types'
import { MatchesType } from './matches/types'
import { MatchType } from './match/types'
import { RepairListType } from './repairList/types'
import { RepairItemType } from './repairItem/types'
import { MaterialType } from './material/types'
import { MaterialsType } from './materials/types'
import { GroupedOrdersType } from './groupedOrders/types'
import { HomemartType } from './homemart/types'
import { DiscountsType } from './discounts/types'
export interface State {
  client: ClientType
  clients: ClientsType
  config: ConfigType
  employee: EmployeeType
  employees: EmployeesType
  oldemployees: EmployeesType
  order: Order
  orders: OrdersType
  vendor: VendorType
  vendors: VendorsType
  searches: Searches
  siteVisit: SiteVisitType
  siteVisits: SiteVisitsType
  territories: TerritoriesType
  territory: TerritoryType
  job: JobType
  jobs: JobsType
  licenses: LicensesType
  companies: CompaniesType
  company: CompanyType
  items: ItemsType
  workflows: WorkflowsType
  notifications: NotificationType
  comments: CommentsType
  notes: Notes
  invoice: Invoice
  payments: PaymentsType
  tradeTypes: Trades
  communications: CommunicationsType
  materialInvoices: MaterialInvoices
  history: HistoryType
  priceModel: PriceModelType
  estimates: EstimatesType
  estimatesByCategory: EstimatesByCategoryType
  estimate: EstimateType
  filters: FiltersType
  invitations: Invitation
  subscriptions: SubscriptionsType
  subscription: SubscriptionType
  insurances: InsurancesFetch
  estimateItems: EstimateItemsType
  newEstimate: NewEstimateType
  widgets: WidgetsType
  widget: Widget
  matches: MatchesType
  match: MatchType
  repairList: RepairListType
  repairItem: RepairItemType
  material: MaterialType
  materials: MaterialsType
  groupedOrders: GroupedOrdersType
  homemart: HomemartType
  discounts: DiscountsType
}

const rootReducer = combineReducers({
  client: clientReducer,
  clients: clientsReducer,
  config: configReducer,
  employee: employeeReducer,
  employees: employeesReducer,
  oldemployees: employeesReducer,
  order: orderReducer,
  orders: ordersReducer,
  vendor: vendorReducer,
  vendors: vendorsReducer,
  searches: searchesReducer,
  siteVisit: siteVisitReducer,
  siteVisits: siteVisitsReducer,
  territories: territoriesReducer,
  territory: territoryReducer,
  job: jobReducer,
  jobs: jobsReducer,
  licenses: licensesReducer,
  companies: companiesReducer,
  company: companyReducer,
  items: itemsReducer,
  workflows: workflowsReducer,
  notifications: notificationsReducer,
  comments: commentsReducer,
  notes: notesReducer,
  invoice: invoiceReducer,
  payments: paymentsReducer,
  tradeTypes: tradeTypesReducer,
  communications: communicationsReducer,
  navbar: navbarReducer,
  materialInvoices: materialInvoicesReducer,
  history: historyReducer,
  priceModel: priceModelsReducer,
  estimates: estimatesReducer,
  estimatesByCategory: estimatesByCategoryReducer,
  estimate: estimateReducer,
  invitations: invitationsReducer,
  filters: filtersReducer,
  subscriptions: subscriptionsReducer,
  subscription: subscriptionReducer,
  insurances: insurancesReducer,
  estimateItems: estimateItemsReducer,
  newEstimate: newEstimateReducer,
  widgets: widgetsReducer,
  widget: widgetReducer,
  matches: matchesReducer,
  match: matchReducer,
  repairList: repairListReducer,
  repairItem: repairItemReducer,
  material: materialReducer,
  materials: materialsReducer,
  groupedOrders: groupedOrdersReducer,
  homemart: homemartReducer,
  discounts: discountsReducer,
  campaigns: campaignsReducer
})

export default rootReducer
