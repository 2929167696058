

import { UpdateRequest } from '~api/types'
import { MaterialInvoice } from '../types'
import {
  MaterialInvoiceActions, materialInvoiceTypes, MaterialInvoices, UpdateMaterialInvoiceParams
} from './types'

const actions = {

  fetchMaterialInvoices: (payload: string, callback?: (succ: boolean) => void): MaterialInvoiceActions => ({
    type: materialInvoiceTypes.FETCH_MATERIAL_INVOICE,
    payload,
    callback
  }),

  setMaterialInvoices: (payload: MaterialInvoices): MaterialInvoiceActions => ({
    type: materialInvoiceTypes.SET_MATERIAL_INVOICES,
    payload
  }),

  setMaterialInvoice: (payload: MaterialInvoice, callback?: (succ: boolean) => void): MaterialInvoiceActions => ({
    type: materialInvoiceTypes.SET_MATERIAL_INVOICE,
    payload,
    callback
  }),

  uploadMaterialInvoices: (payload: UpdateMaterialInvoiceParams, callback?: (succ: boolean) => void): MaterialInvoiceActions => ({
    type: materialInvoiceTypes.UPLOAD_MATERIAL_INVOICES,
    payload,
    callback
  }),

  addMaterialInvoices: (payload: MaterialInvoice, callback?: (succ: boolean) => void): MaterialInvoiceActions => ({
    type: materialInvoiceTypes.ADD_MATERIAL_INVOICES,
    payload,
    callback
  }),

  updateMaterialInvoiceByPath: (payload: UpdateRequest, callback?: (succ: boolean, materialInvoices?: MaterialInvoices) => void): MaterialInvoiceActions => ({
    type: materialInvoiceTypes.UPDATE_MATERIAL_INVOICES_BY_PATH,
    payload,
    callback
  }),

  resetState: (): MaterialInvoiceActions => ({
    type: materialInvoiceTypes.RESET_MATERIAL_INVOICES,
    payload: null,
  }),

}

export default actions