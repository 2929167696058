/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import BackButton from 'src/components/UI/CustomUI/atoms/BackButton'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import { siteVisitsActions, estimateActions, estimatesActions, itemsActions, territoryActions } from 'src/ducks/actions'
import { Box } from '../../../UI'
import JobTopInfo from './EstimateTopInfo'
import { Attachments, History, Information, Overview, Items, Notes } from './Tabs'
import { WorkOrdersProvider } from './Tabs/Items/context'
import CommentsFab from './CommentsFab'
import ControlButtons from './ControlButtons'
import HistoryAndComments from './Tabs/HistoryAndComments'
import Communications from './Tabs/Communications'
import { getEstimate } from 'src/ducks/selectors'
import InspectionItems from './Tabs/InspectionItems'

const EstimateView: FC = () => {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [estimateLoading, setEstimateLoading] = useState(true)
  const [relatedEstimatesLoading, setRelatedEstimatesLoading] = useState(true)
  const [selectedTab, setSelectedTab] = useState<number>()
  const { id } = useParams()
  const navigate = useNavigate()
  const order = searchParams?.get('order') ?? ''
  const estimate = useSelector(getEstimate())

  const pageLoading = estimateLoading

  const fetchEstimate = useCallback(() => {
    setEstimateLoading(true)

    if (id) {
      dispatch(
        estimateActions.fetchEstimate(id, (succ: boolean) => {
          setEstimateLoading(false)

          if (!succ) {
            setEstimateLoading(false)
            navigate('/not-found')
          }
          /*      dispatch(itemsActions.fetchItems({
                 params: { jobId: id, status: ITEM_STATUS.COMPLETE }
               }, (_succ: boolean, _count?: number) => {
               })) */
        })
      )
    }
  }, [id])

  const fetchRelatedEstimates = () => {
    setRelatedEstimatesLoading(true)
    if (id) {
      dispatch(
        estimatesActions.fetchRelatedEstimates(id, (succ: boolean) => {
          setRelatedEstimatesLoading(false)
          // if (!succ) {
          //   navigate('/not-found')
          // }
        })
      )
    }
  }

  const fetchSiteVisits = useCallback(() => {
    if (id) {
      dispatch(
        siteVisitsActions.getSiteVisits({ jobId: id })
      )
    }
  }, [id])

  const fetchItems = () => {
    dispatch(itemsActions.fetchItemsByCategory({
      params: { jobId: id, orderId: order }
    }, (_succ) => {
      //setTableLoading(false)
    }))
  }

  const fetchInspectionItems = () => {
    dispatch(itemsActions.fetchInspectionItems({
      params: { jobId: id, orderId: order }
    }, (_succ) => {
      //setTableLoading(false)
    }))
  }

  const fetchRepairItems = () => {
    dispatch(
      territoryActions.fetchTerritoryRepairItems({ territoryId: estimate?.properties.territory?.id, params: { limit: 9999 } })
    )
  }

  useEffect(() => {
    fetchEstimate()
    fetchRelatedEstimates()
    fetchSiteVisits()
    fetchItems()
    fetchInspectionItems()
  }, [id])

  const refresh = () => {
    fetchEstimate()
    fetchRelatedEstimates()
    fetchSiteVisits()
    fetchRepairItems()
  }

  const TABS = [
    { label: 'Overview', content: <Overview /> },
    { label: 'Items', content: <WorkOrdersProvider><Items /></WorkOrdersProvider> },
    { label: 'Inspection Items', content: <InspectionItems /> },
    { label: 'Attachments', content: <Attachments /> },
    { label: 'History', content: <History /> },
    { label: 'Information', content: <Information setPageLoading={setEstimateLoading} /> },
    { label: 'History & Comments', content: <HistoryAndComments /> },
    { label: 'Communications', content: <Communications /> },
    { label: 'Notes', content: <Notes /> },
  ]

  return (
    <>
      {searchParams?.get('from') ? (<BackButton float top='12px' to={'/' + searchParams?.get('from') ?? undefined} />) : (<BackButton float top='12px' />)}
      <Box display='grid' gridTemplateRows='min-content auto' gap={2}>
        <Box padding={selectedTab === 3 ? '24px 24px 0' : 0}><JobTopInfo loading={pageLoading} /></Box>
        {!pageLoading && <Box>
          <TabNavigation
            tabs={TABS}
            margin='0'
            style={{ fontSize: 'var(--font-XS)' }}
            defaultActive={parseInt(searchParams?.get('tab') ?? '0')}
            getSelectedTab={(selected) => {
              setSelectedTab(selected)
              const orderId = searchParams.get('order')
              const fromParam = searchParams.get('from')
              if (fromParam !== null) {
                setSearchParams({ ...searchParams, tab: selected.toString(), from: fromParam }, { replace: true })
              } else {
                const params: any = {
                  ...searchParams,
                  tab: selected.toString(),
                }
                if (orderId && selected === 1) {
                  params.order = orderId
                }
                setSearchParams(params, { replace: true })
              }
            }}
            containerSx={{ padding: selectedTab === 3 ? '0 24px' : 0, margin: '1rem 0 0', display: 'flex', justifyContent: 'space-between' }}
            additionalContent={<ControlButtons refresh={refresh} />}

            tabsStyles={{ width: 'fit-content' }}
          />
          {selectedTab !== 0 && <CommentsFab />}
        </Box>}
      </Box>
    </>
  )
}

export default EstimateView
