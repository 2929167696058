import React from 'react'
import MaterialTableHead from '@mui/material/TableHead'
import { TableHeadProps } from './types'
import { TableRow, TableSortLabel, Typography } from '@mui/material'
import TableCell from '../TableCell'
import { HeaderType } from '../types'

const TableHead = <T,>({
  headers,
  sortable = false,
  haveActions,
  actionTitle = 'View',
  small,
  orderBy,
  order = 'asc',
  onRequestSort
}: TableHeadProps<T>) => {
  const children = []

  const createSortHandler = (property: keyof Record<string, string>) =>
    (event: React.MouseEvent<unknown>) => {
      if (onRequestSort) onRequestSort(event, property as keyof T)
    }

  headers.forEach((headCell: HeaderType) => {
    if (!headCell.hide) {
      children.push(
        <TableCell
          isHeader
          maxWidth={headCell.maxWidth ? `${headCell.maxWidth}px` : 'auto'}
          key={headCell.id}
          sortDirection={orderBy === headCell.id ? order : false}
          small={small}
          color={headCell.color}
          sx={{ padding: '8px !important', textAlign: headCell.align ? headCell.align : 'left' }}
        >
          {sortable
            ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography variant={small ? 'body1Bold' : 'h6Bold'} color="var(--navy-700)">
                  {headCell.label ?? ''}
                </Typography>
              </TableSortLabel>
            )
            : <Typography variant={small ? 'body1Bold' : 'h6Bold'} color="var(--navy-700)">{headCell.label ?? ''}</Typography>}
        </TableCell>
      )
    }
  })

  if (haveActions) {
    children.push(
      <TableCell key='actions' isHeader>
        <Typography variant='subtitle1'> {actionTitle} </Typography>
      </TableCell>)
  }
  return (
    <MaterialTableHead>
      <TableRow style={{ background: 'var(--gray100)' }}>
        {children}
      </TableRow>
    </MaterialTableHead>
  )
}

export default TableHead
