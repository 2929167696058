import env from '@beam-australia/react-env'
import { FC, useState, useEffect } from 'react'
import {
    Box,
    Button, Grid, Typography
} from '@mui/material'
import { icons } from '../../../../../../../../assets'
import useStyles from './styles'
import { ModalAddInvoice } from './types'
import { SelectInput, TextFieldLabel } from 'src/components/UI'
import { CURRENCY_MASK_NEGATIVE, FILE_TYPE, ParseFilesUrl, isEmpty, unMaskedInput } from 'src/helpers'
import UploadDocuments from "src/components/UI/CustomUI/molecules/UploadDocument"
import { getOrdersSelector, getEmployeeId } from 'src/ducks/selectors'
import { useSelector, useDispatch } from "react-redux"
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import { createNumberMask } from 'text-mask-addons'
import { materialInvoicesActions, jobActions, filesActions } from 'src/ducks/actions'
import moment from 'moment'
import { PrivateLoader } from 'src/components/templates'
import CurrencyTextField from 'src/components/UI/CustomUI/molecules/CurrencyTextField'

const Add: FC<ModalAddInvoice> = ({
    onClose,
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const currencyMask = createNumberMask(CURRENCY_MASK_NEGATIVE)
    const userId = useSelector(getEmployeeId)
    const ordersOtions = useSelector(getOrdersSelector)
    const [workOrder, setWorkOrder] = useState(ordersOtions[0])
    const [invoiceNum, setInvoiceNum] = useState('')
    const [amountForm, setAmountForm] = useState('')
    const [uploadingFile, setUploadingFile] = useState(false)
    const [file, setFile] = useState<any>(null)
    const [disableButton, setDisableButton] = useState(true)
    const FILES_URL = env('FILES_URL') ?? ''
    const [loading, setLoading] = useState(false)
    const [fileUrl, setFileUrl] = useState<string>('')

    const validate: boolean = !isEmpty(invoiceNum) && !isEmpty(amountForm) && file !== null

    const handleClose = () => {
        onClose()
    }

    const handleAddInvoice = () => {
        if (validate) {
            setLoading(true)
            dispatch(materialInvoicesActions.addMaterialInvoices({
                invoiceNum,
                amount: Number(unMaskedInput(amountForm)),
                orderName: String(workOrder.label),
                insertedOn: moment().unix(),
                fileUrl: fileUrl
            }, (succ: boolean) => {
                if (succ) {
                    dispatch(jobActions.updateJobByPath([{ op: 'add', path: `/properties/files/-`, value: file }],
                        (succ: boolean) => {
                            onClose()
                            setLoading(false)
                        }))
                }
            }))
        }
    }

    const handleUploadFile = (file: null | File, callback: () => void) => {
        if (file) {
            setUploadingFile(true)
            dispatch(filesActions.uploadFile(file, (uploadSucc, fileName) => {
                if (uploadSucc && fileName) {
                    const fileToSave = {
                        name: file.name,
                        fileUrl: ParseFilesUrl({
                            baseUrl: FILES_URL,
                            avatar: fileName ?? ''
                        }),
                        description: file.name ?? '',
                        fileType: FILE_TYPE.MATERIAL_INVOICE,
                        uploadedOn: moment().unix(),
                        uploadedBy: userId
                    }
                    callback()
                    setFileUrl(fileToSave.fileUrl)
                    // setFiles([...files, fileToSave])
                    setFile(fileToSave)
                }
                setUploadingFile(false)
            }))
        } else {
            setFile(null)
            setFileUrl('')
        }
    }

    const handleAWorkOrderChange = (newSelected: SelectInputOption) => {
        if (newSelected === null) {
            setWorkOrder(ordersOtions[0])
        } else {
            setWorkOrder(newSelected)
        }
    }

    /*     const fetchMaterialInvoices = useCallback(() => {
            if (job?.id) {
                dispatch(materialInvoicesActions.fetchMaterialInvoices(job?.id,
                    (_succ: boolean) => {
                        setLoading(false)
                    }))
            }
        }, [job?.id]) */

    useEffect(() => {
        setDisableButton(!validate)
    }, [validate])

    return (
        <Grid className={classes.contentRemove}>
            <PrivateLoader loading={loading} sx={{ minHeight: "30vh" }}>
                <Box width="100%">
                    <Grid item container spacing={2} justifyContent='flex-end' >
                        <Grid item xs={4}>
                            <TextFieldLabel
                                autoComplete='off'
                                label='Invoice Number: '
                                value={invoiceNum}
                                size='small'
                                onChange={(e) => {
                                    setInvoiceNum(e.target.value)
                                }}
                                labelVariant='h6'
                                name='invoice-number-material-invoices'
                                id='invoice-number-material-invoices'
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CurrencyTextField
                                autoComplete='off'
                                label='Amount: '
                                value={amountForm}
                                size='small'
                                onChange={(e) => {
                                    setAmountForm(e)
                                }}
                                labelVariant='h6'
                                mask={currencyMask}
                                name='amount-number-material-invoices'
                                id='amount-number-material-invoices'
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectInput
                                label='Work Order:'
                                onChange={handleAWorkOrderChange}
                                value={workOrder}
                                options={ordersOtions}
                                size='small'
                                disableClearable
                                disabled={false}
                            />
                        </Grid>
                    </Grid>


                    <Grid item paddingTop={2}>
                        <Typography variant='h6Bold' color='var(--gray700)'>File to upload:</Typography>
                    </Grid>

                    <Grid item container spacing={2} justifyContent='flex-end' paddingTop={1} >
                        <Grid item xs={12} alignItems='flex-end'>
                            <Grid item>
                                <UploadDocuments
                                    error={false}
                                    file={file ?? null}
                                    dropzoneText='Click here to upload your invoice'
                                    onChange={!uploadingFile ? handleUploadFile : () => { }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} spacing={4} justifyContent='flex-end' marginRight={2} paddingTop={4}>
                        <Grid item>
                            <Button
                                variant='contained'
                                sx={{
                                    width: '160px',
                                    height: '40px',
                                    backgroundColor: '#FAFBFF',
                                    color: '#3A66FF',
                                    border: 0,
                                    '&:hover': {
                                        color: 'white'
                                    }
                                }}

                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={handleAddInvoice}
                                variant='contained'
                                color='primary'
                                sx={{
                                    width: '235px',
                                    height: '40px',
                                }}
                                endIcon={<icons.CloudUpload style={{ width: '12px', height: '12px', padding: '0px !important' }} />}
                                disabled={disableButton || uploadingFile}
                            >
                                Upload Invoice
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </PrivateLoader>
        </Grid>
    )
}

export default Add
