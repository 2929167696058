export const fonts = {
    h1: {
        fontSize: 'var(--font-XXL)'
    },
    h2: {
        fontSize: 'var(--font-XL)'
    },
    h3: {
        fontSize: 'var(--font-L)',
        fontFamily: 'var(--font-normal)'
    },
    h4: {
        fontSize: 'var(--font-M)',
        fontFamily: 'var(--font-normal)',
        lineHeight: 1
    },
    h5: {
        fontSize: 'var(--font-S)',
        fontFamily: 'var(--font-normal)',
        lineHeight: 1.6
    },
    h6: {
        fontSize: 'var(--font-XS)',
        fontFamily: 'var(--font-normal) !important'
    },
    h1Bold: {
        fontSize: 'var(--font-XXL)',
        fontWeight: 700,
    },
    h4Bold: {
        fontSize: 'var(--font-M)',
        fontFamily: 'var(--font-bold)',
        lineHeight: 1
    },
    h5Bold: {
        fontSize: 'var(--font-S)',
        fontFamily: 'var(--font-bold)',
        lineHeight: 1.6
    },
    h6Bold: {
        fontSize: 'var(--font-XS)',
        fontFamily: 'var(--font-bold)'
    },
    subtitle1: { fontFamily: 'Nouvelle', fontSize: '12px' },
    subtitle2: { size: '16px' },
    body1: { fontFamily: 'NouvelleNormal', fontSize: "12px" },
    body1Bold: { fontFamily: 'Nouvelle', fontSize: "12px" },
    body2: { size: '16px' },
    button: { fontSize: '12px', textTransform: 'inherit' },
    caption: { size: '10px' }
}

export interface CustomTypography {
    h1: true
    h2: true
    h3: true
    h4: true
    h5: true
    h6: true
    h1Bold: true
    h4Bold: true
    h5Bold: true
    h6Bold: true
    subtitle1: true
    subtitle2: true
    body1: true
    body1Bold: true
    body2: true
    button: true
    caption: true
}
