/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { icons } from '../../../../../assets'
import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Avatar, MenuItem, SelectInput, CircularProgress, Button } from '../../..'
import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import { navbarActions } from 'src/ducks/actions'
import { authActions } from '../../../../../ducks/actions'
import { colors } from 'src/components/UI/MaterialUI/themeExtension'
import { NavBarIcon, IconNames } from './NavBarIcon'
import { employeeIsAdmin, getEmployee, getEmployeeRoles, getNavbarJobsSelector } from 'src/ducks/selectors'
import { SearchParams } from 'src/ducks/searches/types'
import useStyles from './styles'
import { EmployeeRole } from 'src/ducks/employee/types'
import { debounce } from 'lodash'
import { EMPLOYEE_ROLE_TYPE, ParseFilesUrl } from 'src/helpers'
import env from '@beam-australia/react-env'
import path from 'path'
import Modal from '../../molecules/Modal'
import ReportsModal from './ReportsModal'


const NavigationMenu: FC = () => {
  const FILES_URL = env('FILES_URL') ?? ''
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const classes = useStyles()
  const currentPath = location.pathname
  const isAdmin = useSelector(employeeIsAdmin)
  const roles = useSelector(getEmployeeRoles)
  const employee = useSelector(getEmployee)
  const { picture } = employee
  const [jobNumber, setJobNumber] = useState('')
  const jobs = useSelector(getNavbarJobsSelector)
  const [loading, setLoading] = useState(false)
  const [openReportsModal, setOpenReportsModal] = useState(false)

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }
  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleLogout = () => {
    dispatch(
      authActions.logoutRequest({
        callback: success => {
          if (success) navigate('/')
        }
      })
    )
  }

  const handleRedirect = (path: string) => {
    navigate('/'.concat(path))
  }

  const redirectAdmin = () => {
    const path = 'admin'
    navigate('/'.concat(path))
    handleCloseUserMenu()
  }

  const redirectProfile = () => {
    const path = 'profile'
    navigate('/'.concat(path))
    handleCloseUserMenu()
  }

  const pages = [
    { title: 'Home', path: 'home', scope: ["ALL", EMPLOYEE_ROLE_TYPE.CLOUDFACTORY] },
    {
      title: 'Calendar', path: 'calendar',
      scope: [EMPLOYEE_ROLE_TYPE.SUPER_ADMIN, EMPLOYEE_ROLE_TYPE.ADMIN, EMPLOYEE_ROLE_TYPE.MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_CUSTOMER_CARE, EMPLOYEE_ROLE_TYPE.SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.ESTIMATOR, EMPLOYEE_ROLE_TYPE.TERRITORY_ESTIMATOR_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_ESTIMATOR, EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERINTENDENT, EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERVISOR, EMPLOYEE_ROLE_TYPE.TERRITORY_VENDOR_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_BUSINESS_DEV_MANAGER]
    },
    { title: 'Jobs', path: 'jobs', scope: [EMPLOYEE_ROLE_TYPE.SUPER_ADMIN, EMPLOYEE_ROLE_TYPE.ADMIN, EMPLOYEE_ROLE_TYPE.MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_CUSTOMER_CARE, EMPLOYEE_ROLE_TYPE.SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.ESTIMATOR, EMPLOYEE_ROLE_TYPE.TERRITORY_ESTIMATOR_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_ESTIMATOR, EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERINTENDENT, EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERVISOR, EMPLOYEE_ROLE_TYPE.TERRITORY_VENDOR_MANAGER] },
    { title: 'Estimates', path: 'estimates', scope: ["ALL", EMPLOYEE_ROLE_TYPE.CLOUDFACTORY] },
    { title: 'Customers', path: 'customers', scope: [EMPLOYEE_ROLE_TYPE.SUPER_ADMIN, EMPLOYEE_ROLE_TYPE.ADMIN, EMPLOYEE_ROLE_TYPE.MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_CUSTOMER_CARE, EMPLOYEE_ROLE_TYPE.SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_BUSINESS_DEV_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_ESTIMATOR_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERINTENDENT, EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERVISOR] },
    { title: 'Site Visits', path: 'sitevisits', scope: ["ALL"] },
    {
      title: 'Pros', path: 'pros', scope: [EMPLOYEE_ROLE_TYPE.SUPER_ADMIN, EMPLOYEE_ROLE_TYPE.ADMIN, EMPLOYEE_ROLE_TYPE.MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_CUSTOMER_CARE, EMPLOYEE_ROLE_TYPE.SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_VENDOR_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_ESTIMATOR_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERINTENDENT, EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUPERVISOR]
    },
    { title: 'Advanced', path: 'search', scope: ["ALL"] },
    { title: 'Subscriptions', path: 'subscriptions', scope: ["ALL"] },
    {
      title: 'Admin', path: '', scope: ["SUPER_ADMIN", "ADMIN", EMPLOYEE_ROLE_TYPE.TERRITORY_CUSTOMER_CARE, EMPLOYEE_ROLE_TYPE.SALES, EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.MANAGER, EMPLOYEE_ROLE_TYPE.SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_ESTIMATOR_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_VENDOR_MANAGER, EMPLOYEE_ROLE_TYPE.FIELD_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_BUSINESS_DEV_MANAGER], subItems: [
        { title: 'Payments', path: 'payments', scope: ["SUPER_ADMIN", "ADMIN"] },
        { title: 'Companies', path: 'companies', scope: [EMPLOYEE_ROLE_TYPE.SUPER_ADMIN, EMPLOYEE_ROLE_TYPE.ADMIN] },
        { title: 'Widgets', path: 'widgets', scope: [EMPLOYEE_ROLE_TYPE.SUPER_ADMIN, EMPLOYEE_ROLE_TYPE.ADMIN] },
        { title: 'Territories', path: 'territories', scope: [EMPLOYEE_ROLE_TYPE.SUPER_ADMIN, EMPLOYEE_ROLE_TYPE.ADMIN, EMPLOYEE_ROLE_TYPE.TERRITORY_CUSTOMER_CARE, EMPLOYEE_ROLE_TYPE.SALES, EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.MANAGER, EMPLOYEE_ROLE_TYPE.SUCCESS_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_ESTIMATOR_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_VENDOR_MANAGER, EMPLOYEE_ROLE_TYPE.FIELD_MANAGER, EMPLOYEE_ROLE_TYPE.TERRITORY_BUSINESS_DEV_MANAGER] },
        { title: 'Repair Items', path: 'repairlist', scope: [EMPLOYEE_ROLE_TYPE.SUPER_ADMIN, EMPLOYEE_ROLE_TYPE.ADMIN] },
        { title: 'Homemart', path: 'homemart', scope: [EMPLOYEE_ROLE_TYPE.SUPER_ADMIN, EMPLOYEE_ROLE_TYPE.ADMIN, EMPLOYEE_ROLE_TYPE.TERRITORY_BUSINESS_DEV_MANAGER] },
        { title: 'Banners', path: 'banners', scope: [EMPLOYEE_ROLE_TYPE.SUPER_ADMIN, EMPLOYEE_ROLE_TYPE.ADMIN] },
      ]
    },
  ]

  const settings = (isAdmin && [
    { title: 'Profile', path: '', action: redirectProfile },
    { title: 'Admin Panel', path: '', action: redirectAdmin },
    { title: 'Logout', path: '', action: handleLogout }
  ]) || [
      { title: 'Profile', path: '', action: redirectProfile },
      { title: 'Logout', path: '', action: handleLogout }
    ]

  const getParams = (): Partial<SearchParams> => {
    const searchParams = {} as Partial<SearchParams>
    if (jobNumber !== '') { searchParams.search = jobNumber }
    return searchParams
  }

  const fetchNavbarJobs = useCallback(
    debounce((params: Partial<SearchParams>, page?: number, size?: number) => {
      setLoading(true)
      const searchParams = { ...params, includeArchived: true }
      dispatch(
        navbarActions.fetchJobs({ page, size, searchParams }, (_succ: boolean) => {
          setLoading(false)
        })
      )
    }, 300),
    []
  )

  const handleChangeSearch = (newSelected: SelectInputOption): void => {
    setJobNumber('')
    if (newSelected !== undefined && newSelected.key !== 'all') {
      navigate(`jobs/${newSelected.key}`)
    }
  }

  const handleInputChange = (newValue: any): void => {
    setJobNumber(newValue)
  }

  useEffect(() => {
    jobNumber && fetchNavbarJobs(getParams(), 0, 10)
  }, [jobNumber])

  return (
    <AppBar position='static' color='navbar' sx={{ boxShadow: 'none' }}>
      <Toolbar sx={{ height: '80px' }}>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }}>
          <IconButton
            size='large'
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={handleOpenNavMenu}
            color='inherit'
          >
            <icons.Menu />
          </IconButton>
          <Menu
            id='menu-appbar'
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{ display: { xs: 'block', lg: 'none' } }}
          >
            {pages.map((page, index) =>
              roles?.some((role: EmployeeRole) => page?.scope?.includes(role?.roleType)) || (!(roles?.some((role: EmployeeRole) => role?.roleType === EMPLOYEE_ROLE_TYPE.CLOUDFACTORY)) && page?.scope?.includes("ALL")) ? (
                !page?.subItems?.length ?
                  <MenuItem
                    key={`${page.path}-${index}`}
                    onClick={() => page?.path && handleRedirect(page?.path)}
                  >
                    <Typography textAlign='center'>{page.title}</Typography>
                  </MenuItem>
                  :
                  page.subItems.map((subItem, index) => (
                    roles?.some((role: EmployeeRole) => subItem?.scope?.includes(role?.roleType)) || subItem?.scope?.includes("ALL") ? (
                      <MenuItem
                        key={`${subItem.path}-${index}`}
                        onClick={() => subItem?.path && handleRedirect(subItem?.path)}
                      >
                        <Typography textAlign='center'>{subItem.title}</Typography>
                      </MenuItem>) : null
                  ))
              ) : null)}
          </Menu>
        </Box>
        <Box gap={4} sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' } }}>
          {pages.map((page, index) =>
            roles?.some((role: EmployeeRole) => page?.scope?.includes(role?.roleType)) || (!(roles?.some((role: EmployeeRole) => role?.roleType === EMPLOYEE_ROLE_TYPE.CLOUDFACTORY)) && page?.scope?.includes("ALL")) ? (
              !page?.subItems?.length ? (
                <Link
                  key={`${page.path}-${index}`}
                  to={'/'.concat(page?.path || '')}
                  onClick={() => page.path && handleRedirect(page.path)}
                  style={{
                    color:
                      currentPath.includes(page.path || '')
                        ? colors.blue[700]
                        : colors.webBackground[200],
                    display: 'block',
                    width: '75px !important',
                    minWidth: '75px !important',
                    textDecoration: 'none'
                  }}
                >
                  <Box display='flex' flexDirection='column' alignItems='center'>
                    <NavBarIcon icon={page.title as IconNames} />
                    <Typography variant='h6'>
                      {page.title}
                    </Typography>
                  </Box>
                </Link>
              )
                : <DropDownMenuItem page={page} key={`${page.path}-${index}`} />
            ) : null
          )}
        </Box>
        <Box gap={3} display='flex' sx={{ flexGrow: 0, alignItems: 'center' }}>
          <SelectInput
            customIcon={
              loading
                ? <CircularProgress color='info' size='1rem' />
                : <icons.Search style={{ width: '14px', height: '14px', padding: '0px !important', color: 'white !important' }} />
            }
            onChange={handleChangeSearch}
            onInputChange={handleInputChange}
            options={jobs}
            value={jobNumber}
            sx={{ height: '38px', width: '360px', alignSelf: 'center' }}
            className={classes.input}
            deafultSelected={{ key: 'all', label: '' }}
            renderOption={(props: any, option: any) => {
              const labels = option.label.split('/')
              return (
                <li {...props}>
                  <Box sx={{ display: 'grid', gridAutoFlow: 'row', gridAutoRows: 'max-content max-content max-content' }}>
                    <Box display='flex' flexDirection='row' gap='1'>
                      <Typography variant='body1Bold'>{labels[0]}</Typography>
                      <Typography variant='body1'>{labels[1].length > 45 ? labels[1].substring(0, 45) + '...' : labels[1]}</Typography>
                    </Box>
                    <Box> <Typography variant='body1'>{labels[2]}</Typography> </Box>
                    <Box display='flex' flexDirection='row' gap='1'>
                      <Typography variant='body1' color='var(--green900)'>{labels[3]}</Typography>
                      <Typography variant='body1'>{labels[4]}</Typography>
                    </Box>
                  </Box>
                </li>
              )
            }}
          />
          <Button
            onClick={() => setOpenReportsModal(true)}
            style={{
              color: colors.webBackground[200],
              display: 'block',
              width: '75px !important',
              minWidth: '75px !important',
              textDecoration: 'none',
              padding: '0',
              marginTop: '.5rem'
            }}
          >
            <Box display='flex' flexDirection='column' alignItems='center'>
              <NavBarIcon icon="Reports" />
              <Typography variant='h6'>
                Reports
              </Typography>
            </Box>
          </Button>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 2, background: 'transparent' }}>
            <Avatar
              sx={{ borderRadius: '8px', height: 46, width: 46 }}
              src={
                picture !== ''
                  ? ParseFilesUrl({ baseUrl: FILES_URL, avatar: picture })
                  : ''
              }
            />
          </IconButton>
          <Menu
            sx={{ mt: '45px' }}
            id='menu-appbar'
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting, index) => (
              <MenuItem
                key={`${setting.title}-${index}`}
                onClick={setting.action || handleRedirect(setting.path)}
              >
                <Typography textAlign='center'>{setting.title}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
      <Modal
        open={openReportsModal}
        setOpen={setOpenReportsModal}
        onClose={() => setOpenReportsModal(false)}
        title={<Typography variant='h5Bold' sx={{ padding: '12px 12px 4px', display: 'block' }}>Download Report</Typography>}
        className={classes.reportModal}
      >
        <ReportsModal
          onClose={() => setOpenReportsModal(false)}
        />
      </Modal>
    </AppBar>
  )
}

const DropDownMenuItem = ({ page }: any) => {
  const navigate = useNavigate()
  const location = useLocation()
  const roles = useSelector(getEmployeeRoles)
  const classes = useStyles()

  const currentPath = location.pathname
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRedirect = (path: string) => {
    navigate('/'.concat(path))
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        key={page.path}
        className={classes.dropDownButton}
        onClick={handleClick}
        style={{
          color:
            page.path && currentPath.includes(page.path || '')
              ? colors.blue[700]
              : colors.webBackground[200],
          display: 'block',
          width: '75px !important',
          minWidth: '75px !important',
          textDecoration: 'none'
        }}
      >
        <Box display='flex' flexDirection='column' alignItems='center'>
          <NavBarIcon icon={page.title as IconNames} />
          <Typography variant='h6'>
            {page.title}
          </Typography>
        </Box>
      </Button>
      <Menu
        id="demo-customized-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.dropDown}
      >
        {page.subItems.length > 0 && (page.subItems.map((subItem: any, index: number) =>
          roles?.some((role: EmployeeRole) => subItem?.scope?.includes(role?.roleType)) || subItem?.scope?.includes("ALL") ? (
            <Link
              key={`${subItem.path}-${index}`}
              to={'/'.concat(subItem?.path || '')}
              className={classes.subItem}
              onClick={() => subItem.path && handleRedirect(subItem.path)}
              style={{
                color:
                  currentPath.includes(subItem.path || '')
                    ? colors.blue[700]
                    : colors.webBackground[200],
                display: 'block',
                width: '75px !important',
                minWidth: '75px !important',
                textDecoration: 'none'
              }}
            >
              <Box display='flex' flexDirection='column' alignItems='center'>
                <NavBarIcon icon={subItem.title as IconNames} />
                <Typography variant='h6'>
                  {subItem.title}
                </Typography>
              </Box>
            </Link>
          ) : null
        ))}
      </Menu>
    </div >
  )
}

export default NavigationMenu
