/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState } from 'react'
import Lightbox from 'react-image-lightbox'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollWrapper } from 'src/components/templates'
import { Button, Grid, FormControlLabel, Checkbox, Typography, Box, CircularProgress, ActionsButton } from 'src/components/UI'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import { getItemImages, getJobFullAddress, getOrderItemsImages, getItemsImages, getEstimate, getEstimateFullAdress, getItemsSortedByCategory, getEstimateId } from 'src/ducks/selectors'
import { ImageFile, Item } from 'src/ducks/types'
import { dateFormatString, downloadZip, formatTimestampDate, videoMimeTypes } from 'src/helpers'
import { useIsMdScreen, useIsSmScreen, useIsXsScreen } from 'src/hooks'
import { WorkOrdersContext } from '../../../context'
import { ImagesProps } from './types'
import styles from './styles.module.scss'
import { applicationMimeTypes } from 'src/helpers/files'
import { itemsActions } from 'src/ducks/actions'
import { toast } from 'react-toastify'
import { Action } from 'src/components/UI/CustomUI/molecules/ActionsButton/types'

const Images: FC<ImagesProps> = ({
	handleDeleteFile = () => null,
	submitAction = () => null,
	allOrders = false,
	allImages = false,
	downloadable,
	hasTabs,
	size = "small",
	initialTab = 0,
	height = '14vw',
	deletable,
	maximizeImage = true,
	imageFiles,
	showCopyToItem = false
}) => {
	const dispatch = useDispatch()

	const { state: { selectedOrderId, selectedItemId }, dispatch: dispatchContext } = useContext(WorkOrdersContext)
	const jobFullAddress = useSelector(getEstimateFullAdress())
	const [xsScreen] = useIsXsScreen()
	const [smScreen] = useIsSmScreen()
	const [mdScreen] = useIsMdScreen()
	const estimateId = useSelector(getEstimateId())

	const duckImages: ImageFile[] = useSelector(allOrders
		? getItemsImages()
		: allImages
			? getOrderItemsImages(selectedOrderId ?? '')
			: getItemImages(selectedItemId ?? ''))

	const images = imageFiles || duckImages

	const fileExtension = (tmppath: string) => (tmppath ? tmppath.slice((tmppath.lastIndexOf('.') - 2 >>> 0) + 2) : '')
	const itemsByCategory = useSelector(getItemsSortedByCategory())
	const items: Item[] = itemsByCategory.reduce((acc: any, curr: any) => {
		if (curr.id === selectedItemId) return acc
		return [...acc, ...curr.items]
	}, []).filter((item: any) => item.id !== selectedItemId)

	const beforeCount = images.filter(image => !image.uploadedAfterJob).length
	const afterCount = images.filter(image => image.uploadedAfterJob).length

	const [photoIndex, setPhotoIndex] = useState(0)
	const [openLightbox, setOpenLightbox] = useState(false)

	const [selectedImageIds, setSelectedImageIds] = useState<string[]>([])
	const [selectedTab, setSelectedTab] = useState(initialTab)
	const [downloadAllLoading, setDownloadAllLoading] = useState(false)
	const [downloadSelectedLoading, setDownloadSelectedLoading] = useState(false)

	const copyItemsToOrderAction = (action: 'copy' | 'move'): Action[] => items.map(item => {
		return {
			label: item.title,
			icon: <Icon color='lightGray' name='ArrowForward' />,
			onClick: () => {
				copyImageToItem(item?.id)
				action === 'move' && handleDeleteSelected()
			},
		}
	})

	const handleDeleteSelected = async () => {

		const indexesToDelete = images.filter((image, index) => (
			selectedImageIds.includes(image.id)
		));

		const indexToDelete = indexesToDelete.reverse().map((image) => {
			return {
				op: 'remove' as "remove",
				path: `/imageFiles/${images.indexOf(image)}`
			}
		});

		dispatch(itemsActions.updateItemByPath({ itemId: selectedItemId, request: indexToDelete }, (succ: boolean) => {

			if (succ) {
				toast.success('The files were deleted successfully')
				dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
				handleSuccess()
			}

		}))
	}

	const imagesSelectedActions = [
		{
			label: `Copy Images (${selectedImageIds.length}) to`,
			subActions: copyItemsToOrderAction('copy'),
		},
		{
			label: `Move Images (${selectedImageIds.length}) to`,
			subActions: copyItemsToOrderAction('move'),
		},
	]

	const toggleSelectImage = (imageId: string) => {
		if (selectedImageIds.includes(imageId)) {
			setSelectedImageIds(selectedImageIds.filter(id => id !== imageId))
		} else {
			setSelectedImageIds([...selectedImageIds, imageId])
		}
	}

	const handleDownloadAll = async () => {
		setDownloadAllLoading(true)
		await downloadZip(`${jobFullAddress.replaceAll(".", "")} (${selectedTab ? 'after' : 'before'})`, images.filter(image =>
			(selectedTab && image.uploadedAfterJob) ||
			(!selectedTab && !image.uploadedAfterJob)), () => {
				setDownloadAllLoading(false)
			})
	}


	const handleDownloadSelected = async () => {
		setDownloadSelectedLoading(true)
		await downloadZip(`${jobFullAddress.replaceAll(".", "")} (selection)`,
			images.filter(image => selectedImageIds.includes(image.id)), () => {
				setDownloadSelectedLoading(false)
			}
		)
	}

	const handleSuccess = () => {
		dispatch(
			itemsActions.fetchItemsByCategory({ params: { jobId: estimateId } }, () => { })
		)
		dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
	}


	const copyImageToItem = (itemId: string | null) => {
		if (!itemId) return toast.error(`There's was a problem moving the images.`)
		const imagesToMove = images?.reduce((acc: any, curr: any) => {
			return selectedImageIds?.includes(curr.id) ? [...acc, curr] : acc;
		}, [])

		const itemToPatch = items.find(item => item.id === itemId)
		const request = [{
			op: 'add',
			path: `/imageFiles`,
			value: [...(itemToPatch?.imageFiles ?? []), ...imagesToMove]
		}]
		dispatch(itemsActions.updateItemByPath({ itemId: itemId, request: request }, (succ: boolean) => {
			if (succ) {
				toast.success('The files were added successfully')
				dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
				// CALL fetchEstimate & fetchItems
				handleSuccess()
			}
		}))
	}

	const handleDeleteImage = (index: number) => {
		if (index && index > images.length) return
		const filteredArray = images.filter((_, i) => i !== index)
		if (submitAction) submitAction(filteredArray)
	}

	const tabContent = () => {
		const imagesToShow = hasTabs ? images.filter(image => (
			(selectedTab && image.uploadedAfterJob) ||
			(!selectedTab && !image.uploadedAfterJob)
		)) : images

		if (!imagesToShow || !imagesToShow.length) {
			return <Typography color="var(--gray500)" variant="h6">No uploaded Images for now.</Typography>
		}

		return (
			<>
				{openLightbox && (
					<Lightbox
						mainSrc={imagesToShow[photoIndex].fileUrl}
						nextSrc={imagesToShow[(photoIndex + 1) % imagesToShow.length].fileUrl}
						prevSrc={imagesToShow[(photoIndex + imagesToShow.length - 1) % imagesToShow.length].fileUrl}
						onCloseRequest={() => setOpenLightbox(false)}
						onMovePrevRequest={() => setPhotoIndex((photoIndex + imagesToShow.length - 1) % imagesToShow.length)}
						onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % imagesToShow.length)}
					/>
				)}
				<ScrollWrapper>
					<Grid container spacing={3}>
						{imagesToShow.map((image, index) => {
							return (
								<Grid key={index} item xs={12} sm={6} md={4} lg={size === "small" ? 3 : 6}>
									<div
										style={{
											backgroundColor: '#F9FAFA',
											borderRadius: '12px',
											position: 'relative'
										}}
									>
										{videoMimeTypes.includes(fileExtension(image.fileUrl)) ? (
											<video controls className={styles.Images__image}>
												<source src={image.fileUrl} />
											</video>
										) : (
											<img
												alt='' src={image.fileUrl}
												onClick={() => {
													setPhotoIndex(index)
													maximizeImage && setOpenLightbox(true)
												}}
												style={{ height: xsScreen ? '40vw' : smScreen ? '24vw' : mdScreen ? '16vw' : height }}
											/>
										)}
										<FormControlLabel
											sx={{ padding: '0 8px 8px 8px' }}
											control={
												<Checkbox
													checked={selectedImageIds.includes(image.id)}
													onChange={() => { toggleSelectImage(image.id) }}
													name={image.name}
													color='infoText'
												/>
											}
											label={
												<Typography variant='body2'>
													{`Uploaded 
													${dateFormatString(formatTimestampDate(image.uploadedOn))}
													at
													${dateFormatString(formatTimestampDate(image.uploadedOn), 'hh:mm')}
													by
													${image.uploadedByUserName ?? ' -'}`}
												</Typography>
											}
										/>
										{deletable && (
											<Icon
												name="CloseRounded"
												onClick={() => handleDeleteImage(index)}
												className={styles.Images__deleteButton}
												width={14}
												background="var(--error-color)"
												color="white"
											/>
										)}
									</div>
								</Grid>
							)
						})}
					</Grid>
				</ScrollWrapper>
			</>
		)
	}

	const TABS = [
		{ label: `Before (${beforeCount})`, content: tabContent() },
		{ label: `After (${afterCount})`, content: tabContent() }
	]

	return (
		<Box
			sx={{
				display: 'grid',
				gridAutoFlow: 'row',
				gridAutoRows: 'auto min-content'
			}}
		>
			{hasTabs ? <TabNavigation
				tabs={TABS}
				variant='contained'
				tabWidth='300px'
				tabAlign='flex-start'
				getSelectedTab={(selected) => { setSelectedTab(selected) }}
			/> : tabContent()}
			<Box sx={{
				paddingTop: '12px',
				display: 'flex',
				gap: '12px',
				flexDirection: 'row-reverse',
				flexWrap: 'wrap'
			}}>
				{downloadable && ((!selectedTab && beforeCount > 0) || (selectedTab && afterCount > 0)) && (
					<Button variant='containedBig' sx={{ width: '200px' }} onClick={handleDownloadAll} startIcon={<Icon name='CloudDownload' />}>
						{downloadAllLoading ? <CircularProgress color='info' size={'1.4rem'} /> : 'Download All'}
					</Button>
				)}
				{selectedImageIds?.length > 0 && (
					<>
						<Button variant='containedBig' sx={{ width: '200px' }} onClick={handleDownloadSelected} startIcon={<Icon name='CloudDownload' />}>
							{downloadSelectedLoading ? <CircularProgress color='info' size={'1.4rem'} /> : `Download Selected (${selectedImageIds.length})`}
						</Button>
						<Button variant='containedLightBig' sx={{ width: '200px' }} onClick={() => setSelectedImageIds([])}>
							Clear All
						</Button>
						{showCopyToItem &&
							<ActionsButton
								actions={imagesSelectedActions}
								variant='outlined'
								text={`Actions (${selectedImageIds.length} Selected)`}
								icon={<Icon name='MoreVert' />}
								sx={{ fontSize: 'var(--font-S)' }}
							/>
						}
					</>
				)}
			</Box>
		</Box>
	)
}

export default Images