import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { icons, narLogo } from 'src/assets'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import { getEstimate, getRelatedEstimates } from 'src/ducks/selectors'
import {
  Box,
  BoxContainer,
  Button,
  CircularProgress,
  Grid,
  LabeledText,
  TitleFirst,
  Typography,
} from '../../../../UI'
import { EstimateContact, EstimateInfo } from './Tabs'
import Items from './Tabs/Items'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { estimatesActions } from 'src/ducks/actions'
import FileBox from 'src/components/UI/CustomUI/atoms/FileBox'
import ClientNotes from './Tabs/ClientNotes'
import RelatedEstimates from './Tabs/RelatedEstimates'
import StatusLabel from 'src/components/UI/CustomUI/atoms/StatusLabel/Status'
import { EstimateItem } from 'src/ducks/types'
import HouseImages from './Tabs/HouseImages'
import Attachments from './Tabs/Attachments'

const EstimateTopInfo: FC<{ loading: boolean }> = ({ loading }) => {
  const dispatch = useDispatch()

  const estimate = useSelector(getEstimate())
  const relatedEstimates = useSelector(getRelatedEstimates())

  const isNar = estimate?.properties?.nar

  const [status, setStatus] = useState(estimate?.status)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [toDeleteIdx, setToDeleteIdx] = useState<number>()

  const files = estimate?.properties?.files?.filter((file) => !file.fileType?.includes('GOOGLE_SEARCH_IMAGE')).map((file, idx) => {
    return {
      name: file.name,
      onDeleteClick: () => {
        setToDeleteIdx(idx)
        setConfirmationModal(true)
      },
      onViewClick: () => {
        window.open(file.fileUrl, '_blank')
      },
    }
  })

  useEffect(() => {
    setStatus(estimate?.status)
  }, [estimate])

  const Financials = [
    {
      label: `Related Estimates (${relatedEstimates?.length || 0})`,
      content: <RelatedEstimates estimates={relatedEstimates || []} />,
    },
    { label: 'Items', content: <Items /> },
    { label: 'General Information', content: <EstimateInfo /> },
  ]

  const AttachmentsAndImages = [
    {
      label: `Attachments`,
      content: <Attachments files={files || []} />,
    },
    { label: 'Estimate Contact', content: <EstimateContact /> },
    { label: 'House Images', content: <HouseImages /> },
  ]

  const handleDelete = () => {
    if (estimate?.properties?.address)
      dispatch(
        estimatesActions.updateEstimate(
          {
            properties: {
              ...estimate?.properties,
              files: estimate?.properties?.files?.filter(
                (e, idx) => idx !== toDeleteIdx
              ),
            },
          },
          (succ: boolean) => {
            setConfirmationModal(false)
          }
        )
      )
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={2}
      justifyContent="space-between"
    >
      <Modal
        setOpen={setConfirmationModal}
        open={confirmationModal}
        title={
          <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3, ml: 2 }}>
            Confirm Delete
          </Typography>
        }
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="body1">
            Are you sure you want to delete this attachment?
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button
            variant="outlined"
            onClick={() => setConfirmationModal(false)}
          >
            Cancel
          </Button>
          <Button
            endIcon={
              loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <icons.Delete />
              )
            }
            variant="containedError"
            color="error"
            onClick={handleDelete}
            sx={{ ml: 2, color: 'red' }}
          >
            Delete
          </Button>
        </Box>
      </Modal>
      <BoxContainer
        sx={{ width: '100%', height: '250px', justifyContent: 'flex-start' }}
        title={
          <Box display="flex" alignItems="center">
            <TitleFirst
              alignItems="center"
              black="Client Notes"
              blackVariant="h5"
              lineHeight="24px"
            />
            <Box
              borderLeft="1px solid"
              width={0}
              height="15px"
              marginLeft={1}
              marginRight={1}
            />
            <LabeledText
              textVariant="h5"
              labelValue="#"
              textValue={estimate?.properties.publicId}
              showCopyButton={true}
            />
            <Box
              borderLeft="1px solid"
              width={0}
              height="15px"
              marginLeft={1}
              marginRight={1}
            />
            <StatusLabel status={estimate?.status || ''} />
            <StatusLabel status={estimate?.properties?.archived ? 'ARCHIVED' : ''} />
          </Box>
        }
        loading={loading}
      >
        <ClientNotes estimate={estimate as EstimateItem} />
      </BoxContainer>
      <BoxContainer
        sx={{ width: '100%', height: '250px', justifyContent: 'flex-start' }}
        title={
          <TitleFirst
            alignItems="center"
            black="Attachments"
            blackVariant="h5"
            lineHeight="24px"
          />
        }
        loading={loading}
      >
        <Grid container spacing={1}>
          <TabNavigation
            tabs={AttachmentsAndImages}
            variant="contained"
            margin="0 0 0.5rem"
          />
        </Grid>
      </BoxContainer>
      <BoxContainer
        sx={{ width: '100%', height: '250px', justifyContent: 'flex-start' }}
        title={
          <TitleFirst
            alignItems="center"
            black="General Information"
            blackVariant="h5"
            rightIcon={isNar ? <img src={narLogo} alt="" /> : ''}
            lineHeight="24px"
          />
        }
        loading={loading}
      >
        <TabNavigation
          tabs={Financials}
          variant="contained"
          margin="0 0 0.5rem"
        />
      </BoxContainer>
    </Box>
  )
}

export default EstimateTopInfo
