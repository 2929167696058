import { FC, useEffect, useRef } from 'react'
import MaskedInput from 'react-text-mask'
import { Typography, TextField, TextareaAutosize } from '../../..'
import { TextMaskCustomProps } from '../../atoms/MaskedInput/types'
import { TextFieldProps } from './types'
import useStyles from './styles'

const TextMaskCustom: FC<TextMaskCustomProps> = props => {
  const { mask, inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={inputRef ? (ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      } : null}
      mask={mask}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  )
}

const TextFieldLabel: FC<TextFieldProps> = ({
  label = '',
  labelVariant = "body1",
  labelColor = 'inherit',
  type = '',
  name,
  value,
  error = false,
  helperText,
  placeholder,
  className,
  onChange = () => { },
  isTextArea = false,
  autoComplete = 'off',
  mask = null,
  size = "medium",
  variant = 'outlined',
  id,
  textFieldStyle = {},
  shouldScrollOnError,
  ...rest
}) => {
  const classes = useStyles()
  const selectText = (
    event: any
  ): void => {
    event.target.select();
  };

  let randomName = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  const CustomTextInput = isTextArea ? TextareaAutosize : TextField

  const compRef = useRef<HTMLDivElement>(null)

  const scrollIntoView = () => {
    if (compRef.current) {
      compRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    error && shouldScrollOnError && scrollIntoView()
  }, [error])

  return (
    <div className={className} ref={compRef}>
      {typeof label === "string" ? <Typography variant={labelVariant} color={labelColor}>
        {label}
      </Typography> : label}
      {isTextArea ?
        <TextareaAutosize
          name={name || randomName()}
          value={value}
          onChange={onChange}
          onDoubleClick={selectText}
          autoComplete={autoComplete}
          placeholder={placeholder}
          id={id}
          className={classes.textArea}
          style={{ ...textFieldStyle }}
        />
        :
        <TextField
          {...rest}
          sx={{ ...textFieldStyle }}
          name={name || randomName()}
          type={type}
          variant={variant}
          size={size}
          autoComplete={autoComplete}
          value={value}
          error={error}
          helperText={helperText}
          onChange={onChange}
          onDoubleClick={selectText}
          placeholder={placeholder}
          fullWidth
          id={id}
          InputProps={
            mask !== null
              ? {
                classes: { ...classes },
                inputProps: { mask },
                inputComponent: TextMaskCustom as any,
              }
              : {
                classes: { ...classes },
                style: { ...textFieldStyle },
              }
          }
        />}
    </div>
  )
}

export default TextFieldLabel
