import { formatParams } from 'src/helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/items'

const apiController: ApiController = {
  getItems: async ({
    params
  }) => {
    const parameters = formatParams(params);
    return await axios
      .get(`${base}?${parameters}`)
      .then(response => response.data)
  },
  getInspectionItems: async ({
    params
  }) => {
    const isString = typeof params === 'string'
    return await axios
      .get(`${base}/estimate/${isString ? params : params?.jobId}/inspection`)
      .then(response => response.data)
  },

  updateItem: async (id: string, request) => await axios
    .patch(`${base}/${id}`, request)
    .then(response => response.data),

  deleteItem: async (id: string) =>
    await axios
      .delete(`${base}/${id}`)
      .then(response => response.data),

  deleteEstimateItems: async (estimateId) =>
    await axios
      .delete(`${base}/estimate/${estimateId}`)
      .then(response => response.data),

  updateBatch: async (request) =>
    await axios
      .patch(base, request)
      .then(response => response.data),

  deleteBatch: async (request) =>
    await axios
      .delete(base, {
        data: request,
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.data),

  createItem: async (request) =>
    await axios
      .post(`${base}`, request)
      .then(response => response.data),

  createItemsFromCsv: async (id, request) =>
    await axios
      .post(`${base}/estimates/${id}/csv`, request)
      .then(response => response.data),

  rejectItem: async (id: string, request) =>
    await axios
      .post(`${base}/${id}/reject`, request)
      .then(response => response.data),

  getItemsByCategory: async ({
    params
  }) => {
    const paramsWithValues = Object.entries(params || {}).reduce((acc: any, [key, value]) => {
      if (value) {
        acc[key] = value
      }
      return acc
    }, {})
    const parameters = formatParams(paramsWithValues);
    return await axios
      .get(`${base}/by-category?${parameters}`)
      .then(response => response.data)
  },

  recallItems: async (request) =>
    await axios
      .post(`${base}/recall`, request)
      .then(response => response.data),

  moveItems: async (id: string, request) =>
    await axios
      .post(`${base}/order/${id}`, request)//headers: { 'Content-Type': 'application/json' })
      .then(response => response.data),

  moveItemsToJob: async (id: string, request) =>
    await axios
      .post(`${base}/job/${id}`, request)//headers: { 'Content-Type': 'application/json' })
      .then(response => response.data),

  moveItemsToEstimate: async (id: string, request) =>
    await axios
      .post(`${base}/estimate/${id}`, request)//headers: { 'Content-Type': 'application/json' })
      .then(response => response.data),

  recoverItem: async (request) =>
    await axios
      .post(`${base}/recovery`, request)
      .then(response => response.data),

  updateItemTotals: async (request) =>
    await axios
      .post(`${base}/${request.itemId}/totals`, request)
      .then(response => response.data),

  updateItemSync: async (id, request) =>
    await axios
      .patch(`${base}/${id}/totals`, request)
      .then(response => response.data),

  mergeItems: async (id, request) =>
    await axios
      .post(`${base}/${id}/merge`, request)
      .then(response => response.data)
}

export default apiController
