/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */

import { SagaIterator } from 'redux-saga'
import { all, AllEffect } from 'redux-saga/effects'

import authSaga from './auth/saga'
import clientSaga from './client/saga'
import clientsSaga from './clients/saga'
import employeeSaga from './employee/saga'
import employeesSaga from './employees/saga'
import orderSaga from './order/saga'
import ordersSaga from './orders/saga'
import vendorSaga from './vendor/saga'
import vendorsSaga from './vendors/saga'
import searchesSaga from './searches/saga'
import siteVisitSaga from './siteVisit/saga'
import siteVisitsSaga from './siteVisits/saga'
import territoriesSaga from './territories/saga'
import territorySaga from './territory/saga'
import jobSaga from './job/saga'
import jobsSaga from './jobs/saga'
import licensesSaga from './licenses/saga'
import workflowsSaga from './workflows/saga'
import companiesSaga from './companies/saga'
import companySaga from './company/saga'
import itemsSaga from './items/saga'
import filesSaga from './files/saga'
import notificationsSaga from './notifications/saga'
import commentsSaga from './comments/saga'
import notesSaga from './notes/saga'
import invoiceSaga from './invoice/saga'
import paymentsSaga from './payments/saga'
import tradeTypesSaga from './tradeTypes/saga'
import communicationsSaga from './communications/saga'
import navbarSaga from './navBar/saga'
import materialInvoicesSaga from './materialInvoice/saga'
import historySaga from './history/saga'
import priceModelSaga from './priceModels/saga'
import estimatesSaga from './estimates/saga'
import estimatesByCategorySaga from './estimatesByCategory/saga'
import estimateSaga from './estimate/saga'
import invitationsSaga from './invitations/saga'
import subscriptionsSaga from './subscriptions/saga'
import subscriptionSaga from './subscription/saga'
import insurancesSaga from './insurances/saga'
import newEstimateSaga from './newEstimate/saga'
import estimateItemsSaga from './estimateItems/saga'
import widgetsSaga from './widgets/saga'
import widgetSaga from './widget/saga'
import matchesSaga from './matches/saga'
import matchSaga from './match/saga'
import repairListSaga from './repairList/saga'
import repairItemSaga from './repairItem/saga'
import materialSaga from './material/saga'
import materialsSaga from './materials/saga'
import groupedOrdersSaga from './groupedOrders/saga'
import reportsSaga from './reports/saga'
import homemartSaga from './homemart/saga'
import discountsSaga from './discounts/saga'
import campaignsSaga from './campaigns/saga'


const rootSaga = function* rootSaga(): Generator<
  AllEffect<SagaIterator<any>>,
  void,
  unknown
> {
  yield all([
    authSaga(),
    clientSaga(),
    clientsSaga(),
    employeeSaga(),
    orderSaga(),
    ordersSaga(),
    vendorSaga(),
    vendorsSaga(),
    searchesSaga(),
    siteVisitSaga(),
    siteVisitsSaga(),
    territoriesSaga(),
    jobSaga(),
    jobsSaga(),
    licensesSaga(),
    companiesSaga(),
    companySaga(),
    itemsSaga(),
    territorySaga(),
    workflowsSaga(),
    filesSaga(),
    notificationsSaga(),
    commentsSaga(),
    employeesSaga(),
    notesSaga(),
    invoiceSaga(),
    paymentsSaga(),
    tradeTypesSaga(),
    communicationsSaga(),
    navbarSaga(),
    materialInvoicesSaga(),
    historySaga(),
    priceModelSaga(),
    estimatesSaga(),
    estimatesByCategorySaga(),
    estimateSaga(),
    invitationsSaga(),
    subscriptionsSaga(),
    subscriptionSaga(),
    insurancesSaga(),
    newEstimateSaga(),
    estimateItemsSaga(),
    widgetsSaga(),
    widgetSaga(),
    matchesSaga(),
    matchSaga(),
    repairListSaga(),
    repairItemSaga(),
    materialSaga(),
    materialsSaga(),
    groupedOrdersSaga(),
    discountsSaga(),
    reportsSaga(),
    campaignsSaga(),
    homemartSaga(),
  ])
}

export default rootSaga
