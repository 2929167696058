import { Theme } from '../../..'
import { makeStyles } from '@mui/styles'

export default makeStyles((theme: Theme) =>
({
  input: {
    backgroundColor: 'white !important',
    borderRadius: '6px !important',
    padding: '0px !important',
    margin: '-1px -1px 0px 0px !important',
    boxShadow: '0px !important',
    '&:hover': {
      borderColor: 'var(--border-hover)'
    },
    '&:before': {
      border: 0
    }
  },
  icon: {
    backgroundColor: 'white !important'
  },
  dropDownButton: {
    padding: '0 !important'
  },
  dropDown: {
    '@global': {
      '.MuiPaper-root': {
        background: '#0f102c',
        padding: '0 2rem'
      }
    }
  },
  subItem: {
    padding: '.25rem 0'
  },
  reportModal: {
    "@global": {
      '.MuiPaper-root': {
        overflow: 'inherit'
      }
    }
  }
})
)
