import { Components } from '@mui/material'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import { createTheme } from '.'
import { colors, CustomPalette, AvailableCustomColors } from './themeExtension'
import { button } from './components/button'
import { radio } from './components/radio'
import { buttonGroup } from './components/buttonGroup'
import { iconButton } from './components/iconButton'
import { table } from './components/table'
import { tabs } from './components/tabs'
import { CustomTypography, fonts } from './fonts'
import { dialog } from './components/dialog'
import { popover } from './components/popover'
import { input, autocomplete } from './components/input'
import { accordion } from './components/accordion'
import { calendarPicker } from './components/calendarPicker'
import { tooltip } from './components/tooltip'
import { listItemButton } from './components/listItemButton'
import { formControlLabel } from './components/formControlLabel'
import { fab } from './components/fab'


export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 445,
      md: 588,
      lg: 1321, // 868,
      xl: 1536 // 1024
    }
  },
  typography: {
    fontFamily: [
      'Nouvelle',
      'Moranga',
      'Roboto Condensed',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    fontSize: 12,
    h1: fonts.h1,
    h2: fonts.h2,
    h3: fonts.h3,
    h4: fonts.h4,
    h5: fonts.h5,
    h6: fonts.h6,
    h1Bold: fonts.h1Bold,
    h4Bold: fonts.h4Bold,
    h5Bold: fonts.h5Bold,
    h6Bold: fonts.h6Bold,
    subtitle1: fonts.subtitle1,
    subtitle2: fonts.subtitle2,
    body1: fonts.body1,
    body1Bold: fonts.body1Bold,
    body2: fonts.body2,
    button: fonts.button,
    caption: fonts.caption
  } as ExtendedTypographyOptions,
  palette: {
    background: {
      default: colors.neutral.white
    },
    primary: {
      light: 'var(--blue700)',
      main: colors.navy[600],
      dark: colors.navy[700],
      contrastText: colors.neutral.white
    },
    secondary: {
      light: colors.red[500],
      main: colors.red[600],
      dark: colors.red[700],
      contrastText: colors.neutral.white
    },
    text: {
      primary: colors.navy[700],
      secondary: colors.text.secondary,
      disabled: colors.text.disabled
    },
    info: {
      main: colors.infoColor.main,
      dark: colors.infoColor.dark,
      light: colors.infoColor.light,
      contrastText: colors.neutral.white
    },
    success: {
      main: colors.green[800],
      dark: colors.success.dark,
      light: colors.success.light,
      contrastText: colors.neutral.white
    },
    error: {
      main: colors.error.main,
      dark: colors.error.dark,
      light: colors.error.light,
      contrastText: colors.neutral.white
    },
    warning: {
      main: colors.warning.main,
      dark: colors.warning.dark,
      light: colors.warning.light,
      contrastText: colors.neutral.white
    },
    infoText: {
      main: colors.blue[700],
      dark: colors.blue[700],
      light: colors.blue[700],
      contrastText: colors.neutral.white
    },
    inputColors: colors.inputColors,
    navbar: {
      main: colors.navbar.background,
      dark: colors.navbar.background,
      light: colors.blue[700],
      contrastText: colors.neutral.white
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    neutral: colors.neutral,
    webBackground: colors.webBackground,
    lightBlue: colors.lightBlue,
    blue: colors.blue,
    navy: colors.navy,
    gray: colors.gray,
    purple: colors.purple,
    orange: colors.orange,
    red: colors.red,
    lime: colors.lime,
    green: colors.green,
    teal: colors.teal,
    borderColor: colors.borderColor,
    checkbox: colors.checkbox
  },
  components: Object.assign(
    table,
    button,
    buttonGroup,
    iconButton,
    tabs,
    dialog,
    popover,
    input,
    autocomplete,
    accordion,
    formControlLabel,
    calendarPicker,
    tooltip,
    listItemButton,
    radio,
    fab
  ) as unknown as Components
})

// * DECLARATION OF CUTSOM THEME EXTENSION
declare module '@mui/material/styles' {
  interface Palette extends CustomPalette {
    infoText: Palette['primary']
    navbar: Palette['primary']
    inputColors: Palette['primary']
  }
  interface PaletteOptions extends CustomPalette {
    infoText: Palette['primary']
    navbar: Palette['primary']
    inputColors: Palette['primary']
    checkbox: Palette['primary']
  }
}
declare module '@mui/material/Checkbox' {
  export interface CheckboxPropsColorOverrides extends AvailableCustomColors { }
}

declare module '@mui/material/TextField' {
  export interface TextFieldPropsColorOverrides extends AvailableCustomColors { }
}
declare module '@mui/material/AppBar' {
  export interface AppBarPropsColorOverrides extends AvailableCustomColors { }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides extends CustomTypography { }
}
declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides extends AvailableCustomColors { }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  h1Bold: React.CSSProperties;
  h4Bold: React.CSSProperties;
  h6Bold: React.CSSProperties;
  body1Bold: React.CSSProperties;
}
