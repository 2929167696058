import { FC, useState } from 'react'
import { Box, Button, Popover, Divider, CircularProgress } from 'src/components/UI'
import Icon from '../../atoms/Icon'
import { Action, ActionsButtonProps } from './types'
import styles from './styles.module.scss'

const ActionButton: FC<{
  index: number,
  onClick?: (() => void),
  icon: React.ReactNode,
  label: React.ReactNode,
  disabled?: boolean,
  onCloseActions: (event: React.MouseEvent<HTMLButtonElement>) => void,
}> = ({ index, onClick, icon, label, onCloseActions, disabled }) => {

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) {
      onClick()
      onCloseActions(event)
    }
  }

  return (
    <Button
      key={`${index}-${label}`}
      onClick={handleClick}
      variant='text'
      startIcon={icon}
      disabled={disabled}
      sx={{
        justifyContent: 'flex-start',
        padding: '0.3rem 1rem',
        width: '100%',
      }}
    >
      {label}
    </Button>
  )
}

const SubActions: FC<{
  index: number,
  icon: React.ReactNode,
  label: React.ReactNode,
  actions?: Action[],
  onCloseActions: (event: React.MouseEvent<HTMLButtonElement>) => void
}> = ({ index, icon, label, actions, onCloseActions }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)

  if (!actions) return null

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null)
    onCloseActions(event)
  }

  return (
    <Box key={index}>
      <Button
        onClick={handleClick}
        variant='text'
        startIcon={icon}
        sx={{
          justifyContent: 'flex-start',
          padding: '0.3rem 1rem',
          width: '100%',
          marginRight: '0.5rem',
        }}
      >
        {label}
        <div style={{ position: 'absolute', right: '0' }}>
          <Icon color='gray' name='ArrowRight' />
        </div>
      </Button>
      {
        actions && <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          onClose={handleClose}
          open={open}
          sx={{
          }}
          PaperProps={{
            style: {
              border: '2px solid #EBECEF',
              borderRadius: '8px',
              marginLeft: '0.15rem',
              marginTop: '-0.15rem',
            }
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <RenderActions actions={actions} onCloseActions={onCloseActions} />
          </Box>
        </Popover>
      }
    </Box>
  )
}

const RenderActions: FC<{
  actions?: Action[], onCloseActions: (event: React.MouseEvent<HTMLButtonElement>) => void
}> = ({ actions, onCloseActions }) => {
  if (!actions) return null
  return (
    <>
      {actions?.map(({ label, icon, onClick, subActions, isDivider = false, disabled }, index) => (
        <Box key={index}>
          {isDivider ? (
            <Divider />
          ) : subActions ? (
            <SubActions
              index={index}
              label={label}
              icon={icon}
              actions={subActions}
              onCloseActions={onCloseActions}
            />
          ) : (
            <ActionButton
              index={index}
              label={label}
              icon={icon}
              onClick={onClick}
              disabled={disabled}
              onCloseActions={onCloseActions}
            />
          )}
        </Box>
      ))}
    </>
  )
}

const ActionsButton: FC<ActionsButtonProps> = ({
  actions, variant = 'icon', icon, text, iconPosition = 'start', sx, loading = false, disabled = false, fullHeight = true, onClick
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    if (!loading) setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const toRender = () => {
    if (loading) return <CircularProgress color='info' size={'1.4rem'} />
    if (onClick) {
      return (
        <Box flex={1} className={styles.Button__onClick__container__action} onClick={(event: React.SyntheticEvent<EventTarget>) => {
          event.stopPropagation()
          onClick()
        }}>
          {variant === 'icon' ? icon : text}
        </Box>
      )
    } else {
      return variant === 'icon' ? icon : text
    }
  }

  return (
    <Box sx={{ height: `${fullHeight ? '100%' : 'auto'}` }}>
      <Button
        onClick={handleClick}
        className={`${styles.Button} ${loading && styles.Button__loading} ${onClick && styles.Button__onClick__container}`}
        variant={variant}
        sx={[
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        disabled={disabled}
        startIcon={text && icon && iconPosition === 'start' ? icon : null}
        endIcon={!loading && text && icon && iconPosition === 'end' ? icon : null}
      >
        {toRender()}
      </Button>
      {
        actions && <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          onClose={handleClose}
          open={actions.length ? open : false}
          sx={{
          }}
          PaperProps={{
            style: {
              border: '2px solid #EBECEF',
              borderRadius: '8px',
              marginTop: '0.5rem'
            }
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Box
            sx={{
              display: 'inline-flex',
              flexDirection: 'column'
            }}
          >
            <RenderActions actions={actions} onCloseActions={handleClose} />
          </Box>
        </Popover>
      }
    </Box>
  )
}

export default ActionsButton
