import env from '@beam-australia/react-env'
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import { useContext, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ActionsButton } from 'src/components/UI'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import { commentsActions, filesActions, itemsActions } from 'src/ducks/actions'
import { getEstimateId, getItemById, getOrderById } from 'src/ducks/selectors'
import {
  FILE_TYPE,
  formatTimestamp,
  isEmpty,
  ITEM_STATUS,
  ORDER_STATUS,
  ParseFilesUrl,
} from 'src/helpers'
import { WorkOrdersContext } from '../../../context'

import styles from './styles.module.scss'
import EstimateInfo from './Tabs/EstimateInfo'
import ImageGallery from './Tabs/ImageGallery'
import Notes from './Tabs/Notes'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'

const FILES_URL = env('FILES_URL') ?? ''

const AddItemModal = () => {
  const dispatch = useDispatch()
  const {
    dispatch: dispatchContext,
    state: { item, editItem, selectedOrderId, selectedItemId, comments, totalsLoading },
  } = useContext(WorkOrdersContext)
  const isEditModal = !isEmpty(item.itemId)
  const { id } = useParams()
  const [buttonLoading, setButtonLoading] = useState(false)
  const order = useSelector(getOrderById(selectedOrderId as string))
  const itemToEdit = useSelector(getItemById(selectedItemId as string))
  const estimateId = useSelector(getEstimateId())

  const {
    completedTimestamp,
    vendorPaymentTimestamp,
    statusType,
    csmAcceptanceTimestamp,
  } = order

  const showPaid =
    statusType &&
    (ORDER_STATUS.PAID === statusType || vendorPaymentTimestamp != null)
  const showAccepted =
    statusType &&
    (ORDER_STATUS.COMPLETE === statusType ||
      showPaid ||
      csmAcceptanceTimestamp != null)
  const showComplete =
    statusType &&
    (ORDER_STATUS.REVIEW === statusType ||
      showAccepted ||
      completedTimestamp != null)

  const vendorIsPaid =
    vendorPaymentTimestamp !== (null || undefined)
      ? true
      : showAccepted && showComplete
        ? true
        : false
  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  useEffect(() => {
    if (itemToEdit?.itemId) {
      dispatchContext({ type: 'SET_ITEM', payload: itemToEdit })
      dispatchContext({
        type: 'SET_ITEM_VALUE',
        payload: { attr: 'displayTitle', value: itemToEdit.title },
      })
    }
  }, [itemToEdit])

  const TABS = [
    {
      label: 'Estimate Info',
      content: <EstimateInfo />,
    },
    {
      label: 'Notes',
      content: <Notes />,
    },
    {
      label: 'Image Gallery',
      content: <ImageGallery />,
    },
  ]

  const handleClose = () => {
    dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
    dispatchContext({ type: 'RESET_ITEM_VALUE' })
    dispatchContext({ type: 'RESET_EDIT_ITEM_VALUE' })
  }

  const uploadImages = (auxAction?: 'CREATE_NEW' | 'CLONE') => {
    setButtonLoading(true)
    if (item?.images || editItem?.images) {
      const images = isEditModal ? editItem.images : item.images
      dispatch(
        filesActions.uploadFiles(
          images as File[],
          (uploadSucc, fileNameList) => {
            if (
              uploadSucc &&
              fileNameList &&
              fileNameList.length === (images as File[]).length
            ) {
              const imageFiles = (images as File[]).map((_image, index) => {
                const fileName = fileNameList[index]
                return {
                  name: fileName,
                  fileUrl: ParseFilesUrl({
                    baseUrl: FILES_URL,
                    avatar: fileName ?? '',
                  }),
                  description: '',
                  fileType: FILE_TYPE.INSPECTION_IMAGE,
                }
              })
              if (!isEditModal) {
                handleCreate({ imageFiles, auxAction })
              } else {
                handleEditItem({ imageFiles, auxAction })
              }
            } else {
              setButtonLoading(false)
            }
          }
        )
      )
    } else {
      if (!isEditModal) {
        handleCreate({ auxAction })
      } else {
        handleEditItem({ auxAction })
      }
    }
  }

  const handleCreate = ({
    imageFiles,
    auxAction,
  }: {
    imageFiles?: any
    auxAction?: 'CREATE_NEW' | 'CLONE'
  }) => {
    const request = {
      ...item,
      unitLaborHours: item.unitLaborHours || 0,
      orderId: selectedOrderId,
      estimateId: estimateId,
      imageFiles,
      isEstimate: true,
      quantity: item.quantity || 1,
    }
    dispatch(
      itemsActions.createItem(request, (success, newItem) => {
        setButtonLoading(false)
        dispatchContext({ type: 'SET_PAGE_LOADING', payload: false })
        dispatchContext({
          type: 'SET_ITEM_VALUE',
          payload: { attr: 'images', value: [] },
        })
        dispatchContext({
          type: 'SET_COMMENTS',
          payload: []
        })
        if (success) {
          if (comments.length) {
            const apiRequests = comments.map((comment) => {
              const request = {
                message: comment?.message,
                recipientsList: comment?.recipientsList,
                itemId: newItem?.itemId,
                estimateId: id,
              }

              return dispatch(
                commentsActions.createComment(request, () => { })
              )
            })

            Promise.all(apiRequests)
          }

          switch (auxAction) {
            case 'CREATE_NEW':
              dispatchContext({ type: 'RESET_ITEM_VALUE' })
              break
            case 'CLONE':
              dispatchContext({ type: 'SET_ITEM', payload: { ...newItem } })
              dispatchContext({
                type: 'SET_ITEM_VALUE',
                payload: { attr: 'displayTitle', value: newItem?.title },
              })
              dispatchContext({
                type: 'SET_ITEM_VALUE',
                payload: { attr: 'itemId', value: null },
              })
              break
            default:
              handleClose()
          }
        }
      })
    )
  }

  const handleRemoveItem = () => {
    setOpenDeleteModal(true)
  }

  const handleDeleteItemPermanently = () => {
    if (item.itemId) {
      dispatch(
        itemsActions.deleteItem(
          {
            itemId: item.itemId,
            selectedOrderId: selectedOrderId || undefined,
            jobId: estimateId,
          },
          () => {
            handleClose()
          }
        )
      )
    }
  }

  const handleEditItem = ({
    imageFiles,
    auxAction,
  }: {
    imageFiles?: any
    auxAction?: 'CREATE_NEW' | 'CLONE'
  }) => {
    if (!item.itemId) return

    const request = {
      ...editItem,
    }

    if ('unitMaterialCost' in request)
      request.unitMaterialCost = Number(request.unitMaterialCost)
    if ('unitLaborHours' in request)
      request.unitLaborHours = Number(request.unitLaborHours)
    if ('margin' in request) request.margin = Number(request.margin)
    if ('totalLaborCost' in request)
      request.totalLaborCost = Number(request.totalLaborCost)
    if ('totalPrice' in request) request.totalPrice = Number(request.totalPrice)
    if ('minPriceRange' in request && request.minPriceRange)
      request.minPriceRange = Number(request.minPriceRange)
    if ('evaluateReason' in request && request.evaluateReason)
      request.evaluateReason = String(request.evaluateReason)
    if ('maxPriceRange' in request && request.maxPriceRange)
      request.maxPriceRange = Number(request.maxPriceRange)
    if ('quantity' in request && !!request.quantity) request.quantity = request.quantity || editItem.quantity || item.quantity

    if (imageFiles) {
      const images: any = [...(item.imageFiles || []), ...(imageFiles || [])]
      request.imageFiles = images
    }
    dispatch(
      itemsActions.updateItem(
        {
          itemId: item.itemId,
          isEstimate: true,
          partialItem: request,
          selectedOrderId: selectedOrderId || undefined,
        },
        (success, newItem) => {
          setButtonLoading(false)
          dispatchContext({ type: 'SET_PAGE_LOADING', payload: false })
          if (success) {
            dispatch(
              itemsActions.fetchItemsByCategory(
                { params: { jobId: id, orderId: item.orderId as string } },
                (succ) => { }
              )
            )

            switch (auxAction) {
              case 'CREATE_NEW':
                dispatchContext({ type: 'RESET_ITEM_VALUE' })
                dispatchContext({ type: 'RESET_EDIT_ITEM_VALUE' })
                break
              case 'CLONE':
                dispatch(
                  itemsActions.createItem({ ...newItem, isEstimate: true }, (success, itemNew) => {
                    if (success) {
                      dispatchContext({ type: 'SET_ITEM', payload: { ...itemNew } })
                      dispatchContext({ type: 'SET_EDIT_ITEM', payload: { ...itemNew } })
                      dispatchContext({
                        type: 'SET_ITEM_VALUE',
                        payload: { attr: 'displayTitle', value: itemNew?.title },
                      })
                    }
                  }))
                break
              default:
                handleClose()
            }
          }
        }
      )
    )
  }

  return (
    <>
      <Grid
        item
        container
        spacing={2}
        justifyContent="flex-end"
      // onPaste={handlePaste}
      >
        <Grid item xs={12} style={{ minHeight: '85vh', paddingBottom: '6rem' }}>
          <TabNavigation
            tabs={TABS}
            variant="text"
            className={styles.TabNavigation}
          />
        </Grid>
        <Grid
          container
          item
          justifyContent="space-between"
          xs={12}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            background: 'white',
            paddingBottom: '1rem',
            padding: '0 !important',
            zIndex: '1',
          }}
        >
          <Grid item xs={3}>
            {isEditModal && (
              <>
                <Typography>
                  Added by:{' '}
                  <Typography variant="body1Bold">
                    {item.createdByName}
                  </Typography>
                </Typography>
                <Typography>
                  {formatTimestamp(
                    item.createdOn as number,
                    'MM/DD/YYYY HH:mm'
                  )}
                </Typography>
              </>
            )}
          </Grid>
          <Grid
            item
            container
            xs={7}
            spacing={2}
            justifyContent="flex-end"
            marginRight={3}
          >
            {itemToEdit.status === ITEM_STATUS.NEW ? (
              <Grid item xs={5}>
                <Button
                  variant={
                    isEditModal ? 'containedErrorLight' : 'containedLight'
                  }
                  onClick={isEditModal ? handleRemoveItem : handleClose}
                  fullWidth
                  disabled={vendorIsPaid}
                >
                  {isEditModal ? 'Remove Item' : 'Cancel'}
                </Button>
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={6}>
              <ActionsButton
                icon={<Icon name="ArrowDropDown" />}
                text="Save"
                onClick={uploadImages}
                iconPosition="end"
                loading={buttonLoading}
                disabled={totalsLoading}
                actions={[
                  {
                    label: 'Save & Create New',
                    onClick: () => {
                      uploadImages('CREATE_NEW')
                    },
                    disabled: vendorIsPaid,
                  },
                  {
                    label: 'Save & Clone',
                    onClick: () => {
                      uploadImages('CLONE')
                    },
                  },
                ]}
                variant="contained"
                sx={{
                  minWidth: '100% !important',
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: 'var(--blue700)',
                  borderColor: 'var(--blue700)',
                  '&:hover': {
                    backgroundColor: 'var(--blue800)',
                    borderColor: 'var(--blue800)',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {openDeleteModal && (
        <Modal
          setOpen={setOpenDeleteModal}
          open={openDeleteModal}
          title={
            <Grid
              container
              spacing={1}
              flexWrap="nowrap"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>This cannot be undone!</Grid>
            </Grid>
          }
        >
          <Grid container spacing={2}>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              justifyContent="center"
              flexWrap="nowrap"
            >
              <Grid item>
                <Typography>Permanently remove the item?</Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpenDeleteModal(false)
                  }}
                  fullWidth
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="containedError"
                  fullWidth
                  onClick={handleDeleteItemPermanently}
                  endIcon={!buttonLoading && <Icon name="DeleteOutline" />}
                >
                  {buttonLoading ? (
                    <CircularProgress color="info" size={'1.4rem'} />
                  ) : (
                    'Permanently Delete'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      )}
    </>
  )
}

export default AddItemModal
