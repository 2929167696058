/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState } from 'react'
import Lightbox from 'react-image-lightbox'
import { useSelector } from 'react-redux'
import { ScrollWrapper } from 'src/components/templates'
import { Button, Grid, FormControlLabel, Checkbox, Typography, Box, CircularProgress } from 'src/components/UI'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import TabNavigation from 'src/components/UI/CustomUI/organisms/TabNavigation'
import { getItemImages, getJobFullAddress } from 'src/ducks/selectors'
import { ImageFile } from 'src/ducks/types'
import { dateFormatString, downloadZip, formatTimestampDate } from 'src/helpers'
import { useIsMdScreen, useIsSmScreen, useIsXsScreen } from 'src/hooks'
import { WorkOrdersContext } from '../../context'
import { ImagesProps } from './types'

const Images: FC<ImagesProps> = ({
    handleDeleteFile = () => null,
    downloadable,
    hasTabs,
    size = 'small',
    initialTab = 0,
    height = '14vw',
    deletable,
    maximizeImage = true
}) => {
    const { state: { selectedItemId /* , tabSelected */ } } = useContext(WorkOrdersContext)
    const jobFullAddress = useSelector(getJobFullAddress())
    const [xsScreen] = useIsXsScreen()
    const [smScreen] = useIsSmScreen()
    const [mdScreen] = useIsMdScreen()

    const images: ImageFile[] = useSelector(getItemImages(selectedItemId ?? ''))
    const beforeCount = images.filter(image => !image.uploadedAfterJob).length
    const afterCount = images.filter(image => image.uploadedAfterJob).length

    const [photoIndex, setPhotoIndex] = useState(0)
    const [openLightbox, setOpenLightbox] = useState(false)

    const [selectedImageIds, setSelectedImageIds] = useState<string[]>([])
    const [selectedTab, setSelectedTab] = useState(initialTab)
    const [downloadAllLoading, setDownloadAllLoading] = useState(false)
    const [downloadSelectedLoading, setDownloadSelectedLoading] = useState(false)

    const toggleSelectImage = (imageId: string) => {
        if (selectedImageIds.includes(imageId)) {
            setSelectedImageIds(selectedImageIds.filter(id => id !== imageId))
        } else {
            setSelectedImageIds([...selectedImageIds, imageId])
        }
    }

    const handleDownloadAll = async () => {
        setDownloadAllLoading(true)
        await downloadZip(`${jobFullAddress.replaceAll(".", "")} (${selectedTab ? 'after' : 'before'})`, images.filter(image =>
            (selectedTab && image.uploadedAfterJob) ||
            (!selectedTab && !image.uploadedAfterJob)), () => {
                setDownloadAllLoading(false)
            })
    }

    const handleDownloadSelected = async () => {
        setDownloadSelectedLoading(true)
        await downloadZip(`${jobFullAddress.replaceAll(".", "")} (selection)`,
            images.filter(image => selectedImageIds.includes(image.id)), () => {
                setDownloadSelectedLoading(false)
            }
        )
    }

    const tabContent = () => {
        const imagesToShow = images.filter(image => (
            (selectedTab && image.uploadedAfterJob) ||
            (!selectedTab && !image.uploadedAfterJob)
        ))

        if (!imagesToShow || !imagesToShow.length) {
            return <Typography color='var(--gray500)' variant='h6'>No uploaded Images for now.</Typography>
        }

        return (
          <>
              {openLightbox && (
                  <Lightbox
                      mainSrc={imagesToShow[photoIndex].fileUrl}
                      nextSrc={imagesToShow[(photoIndex + 1) % imagesToShow.length].fileUrl}
                      prevSrc={imagesToShow[(photoIndex + imagesToShow.length - 1) % imagesToShow.length].fileUrl}
                      onCloseRequest={() => setOpenLightbox(false)}
                      onMovePrevRequest={() => setPhotoIndex((photoIndex + imagesToShow.length - 1) % imagesToShow.length)}
                      onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % imagesToShow.length)}
                    />
                )}
              <ScrollWrapper>
                  <Grid container spacing={3}>
                      {imagesToShow.map((image, index) => {
                            return (
                              <Grid key={index} item xs={12} sm={6} md={4} lg={size === 'small' ? 3 : 6}>
                                  <div
                                      style={{
                                            backgroundColor: '#F9FAFA',
                                            borderRadius: '12px'
                                        }}
                                    >
                                      <img
                                          alt='' src={image.fileUrl}
                                          onClick={() => {
                                                setPhotoIndex(index)
                                                maximizeImage && setOpenLightbox(true)
                                            }}
                                          style={{
                                                objectFit: 'cover',
                                                padding: '8px 8px 0 8px',
                                                borderRadius: '16px',
                                                width: '100%',
                                                cursor: 'pointer',
                                                height: xsScreen ? '40vw' : smScreen ? '24vw' : mdScreen ? '16vw' : height
                                            }}
                                        />
                                      <FormControlLabel
                                          sx={{ padding: '0 8px 8px 8px' }}
                                          control={
                                              <Checkbox
                                                  checked={selectedImageIds.includes(image.id)}
                                                  onChange={() => { toggleSelectImage(image.id) }}
                                                  name={image.name}
                                                  color='infoText'
                                                />
                                            }
                                          label={
                                              <Typography variant='body2'>
                                                  {`Uploaded 
													${dateFormatString(formatTimestampDate(image.uploadedOn))}
													at
													${dateFormatString(formatTimestampDate(image.uploadedOn), 'hh:mm')}`}
                                                </Typography>
                                            }
                                        />
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                </ScrollWrapper>
            </>
        )
    }

    const TABS = [
        { label: `Before (${beforeCount})`, content: tabContent() },
        { label: `After (${afterCount})`, content: tabContent() }
    ]

    return (
      <Box
          sx={{
                display: 'grid',
                gridAutoFlow: 'row',
                gridAutoRows: 'auto min-content'
            }}
        >
          {hasTabs
                ? <TabNavigation
                    tabs={TABS}
                    variant='contained'
                    tabWidth='300px'
                    tabAlign='flex-start'
                    getSelectedTab={(selected) => { setSelectedTab(selected) }}
                    defaultActive={initialTab}
                  />
                : tabContent()}
          <Box sx={{
                paddingTop: '12px',
                display: 'flex',
                gap: '12px',
                flexDirection: 'row-reverse',
                flexWrap: 'wrap'
            }}
            >
              {downloadable && ((!selectedTab && beforeCount > 0) || (selectedTab && afterCount > 0)) && (
                  <Button variant='containedBig' sx={{ width: '200px' }} onClick={handleDownloadAll} startIcon={<Icon name='CloudDownload' />}>
                      {downloadAllLoading ? <CircularProgress color='info' size='1.4rem' /> : 'Download All'}
                    </Button>
                )}
              {selectedImageIds?.length > 0 && (
                  <>
                      <Button variant='containedBig' sx={{ width: '200px' }} onClick={handleDownloadSelected} startIcon={<Icon name='CloudDownload' />}>
                          {downloadSelectedLoading ? <CircularProgress color='info' size='1.4rem' /> : `Download Selected (${selectedImageIds.length})`}
                        </Button>
                      <Button variant='containedLightBig' sx={{ width: '200px' }} onClick={() => setSelectedImageIds([])}>
                          Clear All
                        </Button>
                    </>
                )}

            </Box>
        </Box>
    )
}

export default Images
