import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { icons } from "src/assets"
import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, SelectInput, TextFieldLabel, Typography } from "src/components/UI"
import DatePicker from "src/components/UI/CustomUI/atoms/DatePicker"
import Icon from "src/components/UI/CustomUI/atoms/Icon"
import { SelectInputOption } from "src/components/UI/CustomUI/atoms/SelectInput/types"
import Modal from "src/components/UI/CustomUI/molecules/Modal"
import { vendorActions } from "src/ducks/actions"
import { TableInsurance } from "src/ducks/insurances/types"
import { getTerritoriesSelector, getVendor } from "src/ducks/selectors"
import { Insurance } from "src/ducks/types"
import { AMOUNT_MASK, enumToArrayObject, formatDateTimestamp, formatTimestampDate, getOptionsFromArray, isEmpty, REVIEW_STATUS, unMaskedInput } from "src/helpers"
import { createNumberMask } from "text-mask-addons"



const InsuranceModal = ({ setOpen, open, tableInsurance, onSubmit }: {
    setOpen: Dispatch<SetStateAction<boolean>>,
    open: boolean,
    tableInsurance: TableInsurance,
    onSubmit: () => void
}) => {
    const dispatch = useDispatch()
    const { insurance, userId } = tableInsurance
    const [companyName, setCompanyName] = useState(insurance.insuranceCompanyName);
    const [coverageAmount, setCoverageAmount] = useState(insurance.amountOfCoverage);
    const [additionalInsured, setAdditionalInsured] = useState(insurance.additionalInsured);
    const [dates, setDates] = useState({
        validFromDate: insurance.validFromDate,
        validToDate: insurance.validToDate
    });
    const territoriesOtions = useSelector(getTerritoriesSelector)
    const options = territoriesOtions.slice(1)
    const terr = options.map((t: { label: any }) => { return t.label })
    const terrValues = options.map(t => { return insurance.territories?.find((et: any) => et === t.key) !== undefined || false })
    const [selTerritories, setSelTerritories] = useState(terr)
    const [selTerrValues, setSelTerrValues] = useState<boolean[]>(terrValues)
    const [loading, setLoading] = useState<boolean>(false)
    const vendor = useSelector(getVendor())

    const currencyMask = createNumberMask(AMOUNT_MASK(1))
    const url = insurance?.fileLocation === 'CLOUDINARY' ? 'https://res.cloudinary.com/punchlist/raw/upload/' : 'https://files.prod.punchlistapi.com/'

    const enumArray = enumToArrayObject(REVIEW_STATUS)
    const statusOptions = getOptionsFromArray(enumArray, { key: 'key', label: 'label' })
    const [status, setStatus] = useState(statusOptions[0])

    const handlStatusChange = (newSelected: SelectInputOption) => {
        if (newSelected === null) {
            setStatus(statusOptions[0])
        } else {
            setStatus(newSelected)
        }
    }
    const [updateDisabled, setUpdateDisabled] = useState<boolean>(false)

    useEffect(() => {
        setUpdateDisabled(!((companyName !== '' && coverageAmount && !!dates.validFromDate && !!dates.validToDate) || status.key === 'EXPIRED'))
    }, [companyName, coverageAmount, dates, status])



    useEffect(() => {
        if (open) {
            dispatch(vendorActions.fetchVendor(userId))
            setCompanyName(insurance.insuranceCompanyName)
            setCoverageAmount(insurance.amountOfCoverage)
            setDates({
                validFromDate: insurance.validFromDate,
                validToDate: insurance.validToDate
            })
            const status = statusOptions.find(status => status.key === insurance.reviewStatus)
            if (status) {
                setStatus(status)
            }
            setSelTerrValues(terrValues)
        }
    }, [open])

    const onSave = () => {
        setLoading(true)

        const id = vendor?.insuranceList?.findIndex((c) => c.id === insurance.id)

        const newValues = [] as string[]
        const options = territoriesOtions.slice(1)
        selTerrValues.forEach((item: boolean, index: number) => {
            if (item) {
                newValues.push(options[index].key)
            }
        })

        dispatch(
            vendorActions.updateVendorList(getInsuranceChanges(
                insurance,
                companyName,
                coverageAmount,
                additionalInsured,
                dates.validFromDate,
                dates.validToDate,
                status?.key,
                newValues), id ?? -1, 'insuranceList', () => {
                    setOpen(false)
                    setLoading(false)
                    onSubmit()
                })
        )
    }

    const getInsuranceChanges = (
        insurance: Insurance,
        companyName: string,
        coverageAmount: number,
        additionalInsured: boolean,
        validFromDate: number,
        validToDate: number,
        status: string,
        selTerritories: string[]
    ): Partial<Insurance> => {
        const changes: Partial<Insurance> = {};
        if (companyName !== insurance.insuranceCompanyName)
            changes.insuranceCompanyName = companyName;

        if (coverageAmount !== insurance.amountOfCoverage)
            changes.amountOfCoverage = Number(unMaskedInput('' + coverageAmount));

        if (additionalInsured !== insurance.additionalInsured)
            changes.additionalInsured = additionalInsured;

        if (validFromDate !== insurance.validFromDate || validToDate !== insurance.validToDate) {
            changes.validFromDate = validFromDate;
            changes.validToDate = validToDate;
        }
        if (status && status !== insurance.reviewStatus)
            changes.reviewStatus = status as REVIEW_STATUS
        if (selTerritories !== insurance.territories)
            changes.territories = selTerritories

        return changes;
    }


    return (
        <Modal
            title={
                <Grid container justifyContent='left' padding='1rem'>
                    <Typography variant='h5Bold'>Insurance Details</Typography>
                </Grid>
            }
            open={open}
            setOpen={setOpen} size='lg'>
            <Grid container padding='0 0 1.5rem 0' display='flex' justifyContent='flex-start'>
                <Grid container item xs={6} justifySelf='start' alignSelf='start' spacing={2}>
                    <Grid item xs={12} padding='0 12px 0 0'>
                        <Typography variant='h5'> File: </Typography>
                        <Grid item display='flex' direction='row' sx={{ background: 'var(--gray100)', borderRadius: '4px', marginRight: '6px' }}>
                            <Button
                                href={insurance?.fileName ? url + insurance?.fileName : ''}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <Icon name='Description' color='var(--navy-700)' sx={{ margin: '4px' }} />
                                <Typography variant='body1' color='var(--blue700)' style={{ textDecoration: 'underline', margin: '4px' }}>
                                    {insurance?.fileName ? insurance?.fileName : '_'}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} gap={1}>
                        <Typography variant='h6'> File Type: Insurance</Typography>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={6}>
                            <TextFieldLabel
                                label="Insurance Company:"
                                labelVariant="h6"
                                value={companyName}
                                onChange={e => setCompanyName(e.target.value)}
                                placeholder="Insurance Company Name"
                                name="insurance-company"
                                type='text'
                                id='insurance-company'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFieldLabel
                                label="Amount of Coverage:"
                                labelVariant="h6"
                                value={coverageAmount as unknown as string}
                                onChange={e => setCoverageAmount(e.target.value)}
                                placeholder="20,000"
                                mask={currencyMask}
                                name="amount-of-coverage"
                                type='text'
                                id="amount-of-coverage"
                            />
                        </Grid>
                        <Grid item xs={12} marginTop={1}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={additionalInsured}
                                        onChange={() => setAdditionalInsured(!additionalInsured)}
                                        name='remember'
                                        color='infoText'
                                        sx={{
                                            padding: '0 !important',
                                            marginRight: '.5rem'
                                        }}
                                    />
                                }
                                label={<Typography variant='body2'>Additional Insured</Typography>}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={1} >
                        <Grid item xs={6}>
                            <DatePicker
                                placeholder='MM/DD/YYYY'
                                onChange={(dateSelected: Date) => setDates({ ...dates, validFromDate: formatDateTimestamp(dateSelected) })}
                                value={dates?.validFromDate ? formatTimestampDate(dates?.validFromDate) : null}
                                style={{ height: '38px', padding: '0px', width: '100%' }}
                                size='small'
                                label='Valid from Date:'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DatePicker
                                placeholder='MM/DD/YYYY'
                                onChange={(dateSelected: Date) => setDates({ ...dates, validToDate: formatDateTimestamp(dateSelected) })}
                                value={dates?.validToDate ? formatTimestampDate(dates?.validToDate) : null}
                                style={{ height: '38px', padding: '0px', width: '100%' }}
                                size='small'
                                label='Valid to Date:'
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={1} >

                        <Grid item xs={6}>
                            <SelectInput
                                label='Review Status:'
                                onChange={handlStatusChange}
                                options={statusOptions}
                                value={status}
                                sx={{ flex: '1' }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={6}>
                    <Typography variant='h5'> Territories </Typography>
                    <Grid container item>
                        {selTerritories.map((terr: string, index: number) => {
                            return (
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selTerrValues[index]}
                                                onChange={() => setSelTerrValues(selTerrValues.map((val, i) => i === index ? !val : val))}
                                                name='remember'
                                                color='infoText'
                                            />
                                        }
                                        label={<Typography variant='body2'>{'' + terr}</Typography>}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container gap={1} display='flex' alignItems='flex-end' justifyContent='flex-end'>
                <Button
                    sx={{ width: '200px' }}
                    variant='outlined'
                    onClick={() => setOpen(false)}
                >Close
                </Button>
                <Button
                    sx={{ width: '200px' }}
                    variant='contained'
                    onClick={onSave}
                    disabled={updateDisabled}
                    endIcon={loading ? <CircularProgress size={24} color='inherit' /> : <icons.Save />}
                >
                    Save Changes
                </Button>
            </Grid>
        </Modal>
    )
}

export default InsuranceModal
